@import "../../../assets/styles/base/variables";

.account-type-button-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 30px;

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: $white-color;
    border: 0.75px solid #979797cc;
    width: 350px;
    height: 218px;
    border-radius: 8px;
    outline: none;
    cursor: pointer;

    input[type="radio"] {
      opacity: 0;
      position: absolute;
    }

    &__icon {
      width: 64px;
      height: 64px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $purple-color;
      border-radius: 50%;
    }

    &.active {
      border: 2px solid #7647ee;
    }
  }
}
