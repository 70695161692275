@import "../../assets/styles/base/mixins";

.mobileAppLayout__content {
  padding: 0 32px 50px 32px;

  @include respond-to("small") {
    padding: 0 32px 140px 32px;
  }

  @include respond-to("big-tablet") {
    padding: 0 5% 140px 5%;
  }
}
