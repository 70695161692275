.multi-select-dropdown {
  position: relative;

  .tool-tip {
    position: absolute;
    bottom: 43px;
    left: 0;
    right: 0;
    width: 100%;
    background-color: white;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
    visibility: hidden;
    display: flex;
    // align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    min-height: max-content;

    span {
      display: block;
    }
  }

  &:hover {
    .tool-tip {
      visibility: visible;
    }
  }

  .Select-value-label {
    background-color: white;
    color: #111;
  }
}
