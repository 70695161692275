@import "../../../assets/styles/base/mixins";
@import "../../../assets/styles/base/variables";

.storesTable {
  background-color: $white-color;
  min-height: 500px;

  // .detail__btn {
  //   font-size: 12px;
  //   padding: 10px 16px;
  //   border-radius: 8px;
  //   color: white;
  //   height: auto;
  //   background-color: $purple-color;
  // }
}
