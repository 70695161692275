@import "../../../../assets/styles/base/variables";

.loyalty-notification-page {
  max-width: 733px;
  &__steps {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 26px;

    &__step {
      display: flex;
      // align-items: center;
      gap: 12px;

      &__number {
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white-color;
        background-color: $purple-color;
        border-radius: 50%;
        flex-shrink: 0;
      }

      &__text {
        font-size: 20px;
      }
    }
  }

  &__button {
    margin-top: 50px;
  }
}
