@import "../../assets/styles/base/variables";
@import "../../assets/styles/base/mixins";

.login-layout {
  width: 100%;
  height: 100%;
  padding: 50px 5%;
  background-color: #f6f4ff;
  position: absolute;
  overflow: auto;

  &__nav {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .signup-link {
      @include respond-to("mobile") {
        display: none;
      }
    }
  }

  &__content {
    padding: 50px 0px;
    @include respond-to("mobile") {
      padding: 25px 0px;
    }

    &__image {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 13rem;

      // mobile screens
      @include respond-to("small") {
        display: none;
      }
    }
  }
}
