.user-table-actions {
  display: flex;
  flex-direction: column;

  &__item {
    padding: 16px;
    cursor: pointer;
    white-space: nowrap;

    &:hover {
      background-color: #f3f2f4;
    }
  }
}
