@import "../../../assets/styles/base/variables";

.percentField {
  width: min-content;
  display: block;
  padding-top: 3px;
  &__cover {
    display: flex;
    align-items: center;
    height: 50px;
    &:hover,
    &:focus-within {
      .percentSection,
      .equivalentSection {
        border-color: $purple-color;
        &.isDisabled,
        &:disabled {
          border-color: rgba(151, 151, 151, 0.8) !important;
        }
      }
    }

    .percentSection,
    .equivalentSection {
      height: 100%;
      border: 0.75px solid rgba(151, 151, 151, 0.8);
      outline: none;

      &:disabled,
      &.isDisabled {
        cursor: not-allowed;
        background: rgba(0, 0, 0, 0.05) !important;
        color: -internal-light-dark(
          rgb(84, 84, 84),
          rgb(170, 170, 170)
        ) !important;
      }

      &.errorBorder {
        border-color: $danger-color;
      }
    }

    .percentSection {
      min-width: 90px;
      overflow: hidden;
      display: flex;
      align-items: center;
      border-radius: 4px 0px 0px 4px;
      input {
        height: 100%;
        width: 100%;
        padding: 0 1em 2px 1em;
        outline: none;
        border: none;
        /* Chrome, Safari, Edge, Opera */
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        &[type="number"] {
          -moz-appearance: textfield;
        }
      }
      span {
        height: 100%;
        background: $purple-color;
        width: 50px;
        display: flex;
        align-items: center;
        color: $white-color;
        justify-content: center;
      }
    }

    .equivalentSection {
      width: 100%;
      min-width: 110px;
      padding: 0 1em 2px 1em;
      border-left: none;
      border-radius: 0 4px 4px 0;
    }
  }

  .errorMessage {
    font-size: 13px;
    visibility: hidden;
    display: flex;
    margin-top: 2px;
    min-height: 15px;
    &.show {
      visibility: visible;
    }
  }
}
