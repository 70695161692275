@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.error-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px 20px 20px;
}
