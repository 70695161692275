.split-payment {
  &-confirm {
    color: #15112d;
    font-size: 14px;
    line-height: 18px;
  }
  &-group {
    display: flex;
    width: 100%;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.14);
  }
  &-input {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    align-self: stretch;
    &__label {
      color: #696677;
    }
    &__field {
      width: 191px;
    }
  }
}
