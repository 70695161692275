@import "../../../../assets/styles/base/variables";
@import "../../../../assets/styles/base/mixins";

.productTableOption {
  // &.isMobile {
  //   .dropdrown {
  //     right: 0;
  //     margin-top: 0;
  //   }
  // }
  @include respond-to("big-tablet") {
    position: relative;
  }

  .dropdrown {
    position: absolute;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    background: #ffffff;
    box-shadow: 0px 20px 36px rgba(0, 0, 0, 0.05);
    z-index: 5;
    width: 200px;
    gap: 10px;
    padding: 10px;
    margin-top: 10px;
    border: $light-border;
    right: 0;

    @include respond-to("big-tablet") {
      right: -10px;
      top: 14px;
    }

    p {
      cursor: pointer;
    }
  }
}
