@import "../../assets/styles/base/variables";
@import "../../assets/styles/base/mixins";

.kitchenLayout {
  background: #ededed;
  max-width: 1600px;
  width: 100%;
  height: 100%;
  position: absolute;
  margin: auto;
  overflow-y: auto;
  overflow-x: hidden;

  &__header {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;

    &__top {
      padding: 20px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      background-color: $purple-color;
      gap: 5px;
      @include respond-to("tablet") {
        padding: 10px 5%;
      }

      .searchField {
        width: 37em;
        margin-left: auto;
      }

      .storeField {
        width: 10em;
        margin-left: auto;
      }
    }

    &__bottom {
      background-color: $dark-purple-color;
      padding: 12px 20px 12px 30px;
      display: flex;
      align-items: center;
      overflow-x: auto;
      gap: 7px;
      @include respond-to("tablet") {
        padding: 10px 5%;
      }

      .stage {
        padding: 5px 12px;
        color: $white-color !important;
        text-align: center;
        display: flex;
        align-items: center;
        height: 35px;
        flex-shrink: 0;
        cursor: pointer;
        &:last-child {
          border: none;
        }
        &:hover {
          text-decoration: none;
        }
        &.active {
          border-radius: 24px;
          border: none;
          background: $purple-color;
        }
      }

      .vertical {
        height: 25px;
        border-left: 1px solid #ddd;
      }
    }
  }

  &__content {
    padding: 20px;
    @include respond-to("tablet") {
      padding: 10px 5%;
    }
  }
}
