@import "../../../../assets/styles/base/variables";
@import "../../../../assets/styles/base/mixins";

.expenseDetails {
  &__header {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    flex-wrap: wrap;
  }

  &__card {
    background-color: $white-color;
    border-radius: 12px;
    padding: 24px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    @include respond-to("big-tablet") {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
    @include respond-to("mobile") {
      grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    }
  }
}
