@import "../../../assets/styles/base/mixins";

.mainContainer {
  padding-bottom: 80px;

  .closeContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 24px;

    @include respond-to("big-tablet") {
      margin-top: 20px;
    }

    img {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }

  .notificationsContainer {
    margin-top: 32px;
    @include respond-to("big-tablet") {
      margin-top: 24px;
    }
    .subTitle {
      color: #696677;
      font-size: 14px;
      margin-bottom: 12px;
    }

    .notificationItems {
      margin-top: 12px;
      .notificationItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 0;
        border-top: 1px dashed #9797974d;

        .notificationTextContainer {
          display: flex;
          align-items: flex-start;

          .notificationText {
            margin-left: 16px;
            margin-top: -5px;
            .mainText,
            .subText {
              color: black;
            }
            .readMainText,
            .readSubText {
              color: #696677;
            }
          }
        }
      }
    }
  }
}
