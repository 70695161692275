.prescriptionWidget {
  padding-top: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  max-width: 100%;

  &__header {
    margin: 12px 0;
    cursor: pointer;
  }

  &__content {
    border: 0.75px solid hsla(0, 0%, 59%, 0.3);
    padding: 20px 10px 20px 10px;
    display: flex;
    flex-direction: column;
    min-height: 300px;
    max-height: 546px;
    overflow-y: scroll;
    border-radius: 8px;
    margin: 20px 0;

    .prescriptionWidget__items {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-top: 20px;
    }

    .prescriptionWidget__item {
      border: 0.75px solid hsla(0, 0%, 59%, 0.35);
      border-radius: 8px;
      padding: 12px;

      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &__content {
        margin-top: 12px;
        border-top: 1px solid hsla(0, 0%, 59%, 0.35);
        // padding: 10px;

        .dosage {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          gap: 8px;
          margin-top: 4px;
        }

        .frequency {
          display: grid;
          place-content: center;
          grid-template-columns: 1fr 1fr;
          gap: 8px;

          & > div {
            max-width: 100%;
          }
        }
      }
    }
  }

  &__bottom {
    position: sticky;
    bottom: 0px;
    margin-top: auto;
    padding-top: 10px;
    display: flex;
    gap: 20px;
  }
}
