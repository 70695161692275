@import "../../../assets/styles/base/variables";

.account-detail-widget {
  width: 100%;
  // max-width: 733px;
  // min-height: 488px;
  background: #f3f1fc;
  padding: 32px;

  &__details {
    display: flex;
    flex-direction: column;
    gap: 24px;
    background-color: $white-color;
    padding: 20px;
    margin-top: 32px;
    margin-bottom: 24px;
    border-radius: 4px;

    &__item {
      &.flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .widget--btn {
        height: 32px;
        padding: 7px 24px;
        border-radius: 8px;
      }
    }
  }
}
