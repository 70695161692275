@import "../../../assets/styles/base/mixins";
@import "../../../assets/styles/base/variables";

.inputSection {
  display: flex;
  gap: 38px;
  align-items: center;
  justify-content: center;

  @include respond-to("tablet") {
    flex-direction: column;
    gap: 0;
  }
}
