@import "../../../../assets/styles/base/variables";
@import "../../../../assets/styles/base/mixins";

.allProducts {
  padding-bottom: 40px;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    .headerLeft {
      display: flex;
      gap: 7px;
      flex-wrap: wrap;
    }
    .addProductBtn {
      @include respond-to("tablet") {
        display: none;
      }
    }
    &__filterProductBtn {
      border: 1px solid $light-gray-color;
      @include respond-to("tablet") {
        display: none;
      }
    }
    &__filterProductEditBtn {
      @include respond-to("tablet") {
        display: none;
      }
    }
  }
  .tableBody {
    background: $white-color;
    border-radius: 16px;
    padding-bottom: 24px;
    min-height: 400px;
    &__header {
      padding: 18px;
      border-bottom: 2px solid #e7f1fa;
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      @include respond-to("large") {
        padding: 15px 15px 5px 15px;
      }
      @include respond-to("mobile") {
        padding: 10;
      }
      .searchField {
        max-width: 500px;
        min-width: 540px;
        width: 100%;
        @include respond-to("tablet") {
          min-width: 100%;
        }
      }
    }
    &__editButton {
      margin-top: 24px;
      width: 100px;

      @include respond-to("tablet") {
        margin-top: auto;
      }
    }
  }
}
