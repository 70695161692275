@import "../../assets/styles/base/variables";
@import "../../assets/styles/base/mixins";

.dashboard-layout {
  display: flex;
  width: 100%;
  height: 100%;
  background: #f8f8f8;
  position: absolute;
  max-width: 1600px;
  margin: auto;
  overflow-y: auto;
  overflow-x: hidden;
  zoom: 95%;

  &__content {
    width: -webkit-fill-available;
    width: -moz-available;
    min-height: 100%;
    padding: 0 32px 50px 32px;
    overflow: auto;
    overflow-x: hidden;

    @include respond-to("small") {
      width: 100%;
      padding: 0 32px 140px 32px;
    }

    @include respond-to("big-tablet") {
      padding: 0 5% 140px 5%;
    }
  }
}
