@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.settlements__table {
  width: 100%;
  background-color: $white-color;
  min-height: 200px;

  @include respond-to("tablet") {
    overflow-x: scroll;
  }

  @include respond-to("mobile") {
    overflow-x: scroll;
  }

  &__header {
    padding: 24px 24px 10px 24px;
    display: flex;
    justify-content: flex-end;
  }

  @include respond-to("small") {
    overflow-x: scroll;
  }

  table {
    border-collapse: collapse;
    min-width: 100%;
    background-color: $white-color;
    border-radius: 16px;
    overflow: hidden;

    @include respond-to("tablet") {
      display: none;
    }

    @include respond-to("mobile") {
      display: none;
    }

    thead {
      tr {
        text-align: left;
        box-shadow: 0px 1px 0px #edf2f7;
        color: #696677;
        font-weight: 300;
      }

      @include respond-to("mobile") {
        display: none;
      }
    }

    th,
    td {
      padding: 20px 25px;
    }

    th {
      font-size: 12px;
    }

    td {
      font-size: 16px;
    }
  }

  .mobile__cards {
    display: none;
    flex-direction: column;
    gap: 24px;
    width: 100%;

    @include respond-to("large") {
      display: none;
    }

    @include respond-to("small") {
      display: none;
    }

    @include respond-to("big-tablet") {
      display: none;
    }

    @include respond-to("tablet") {
      display: flex;
    }

    @include respond-to("mobile") {
      display: flex;
    }

    .mobile__card {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 24px;

      p {
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          font-size: 14px;
          color: $dark-color;
        }
      }

      &__actions {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-top: 20px;
      }
    }
  }

  .primary--account {
    display: inline-block;
    padding: 10px 16px;
    font-size: 10px;
    color: $white-color;
    background: #19ae57;
    border-radius: 8px;
  }

  .secondary--account {
    display: inline-block;
    padding: 10px 16px;
    font-size: 10px;
    color: #19ae57;
    background: #d1efdd;
    border-radius: 8px;
  }
}
