@import "../../../../assets/styles/base/mixins";
@import "../../../../assets/styles/base/variables";

.orderSection {
  display: flex;
  flex-direction: column;
  gap: 15px;
  background: $white-color;
}
.orderDetails {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  & > div {
    width: 20%;
    &:first-child {
      width: 100%;
    }
  }
}
