@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.card_red {
  background: $orange-color;
}

.card_green {
  background: $lumi-light-green;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 196px;
  padding: 24px 20px;
  border-radius: 16px;

  @include respond-to("mobile") {
    height: 156px;
    padding: 14px 10px;
    p {
      font-size: 13px;
    }
  }

  .table_name_desktop {
    @include respond-to("mobile") {
      display: none;
    }
  }

  .table_name_mobile {
    display: none;

    @include respond-to("mobile") {
      display: block;
    }
  }

  .title_diners_conatiner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 16px;

    .seats_container {
      position: relative;
      display: flex;
      align-items: center;

      .menu {
        cursor: pointer;
      }
    }

    .menu_container {
      position: absolute;
      right: 0;
      top: 30px;
      width: 206px;
      z-index: 100;
      cursor: pointer;

      @include respond-to("mobile") {
        width: 100px;
      }

      .clear_table {
        background: $white-color;
        color: #e02020;
        padding: 12px 16px;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;

        &:hover {
          background: $main--bg;
        }

        @include respond-to("mobile") {
          padding: 10px 14px;
        }
      }

      .view_bills {
        background: $white-color;
        color: #15112d;
        padding: 12px 16px;
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;

        &:hover {
          background: $main--bg;
        }

        @include respond-to("mobile") {
          padding: 10px 14px;
        }
      }
    }
  }

  .diners_container {
    display: flex;
    align-items: center;

    .diner_icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      padding: 4px;
      border-radius: 40px;
      background: var(--Lumi-Red-100, #ffeee9);
      margin-right: 8px;
    }
  }

  .btn {
    margin-top: auto;
  }
}
