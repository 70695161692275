@import "../../../../assets/styles/base/variables";
@import "../../../../assets/styles/base/mixins";

.sendReceipt {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  &__form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
