@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.hoverEffect {
  display: flex;
  margin: 1px;
  gap: 5px;
  cursor: pointer;
}

.hoverEffect:hover {
  color: $purple-color;
}

.icon {
  width: 18px;
  height: 18px;
  padding: 0;
  border-radius: 3px;
  border: 1px solid $purple-color;
}
