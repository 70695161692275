@import "../../../../../../../../../assets//styles/base/variables";
@import "../../../../../../../../../assets//styles/base/mixins";

.container {
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 48px;
  box-shadow: 0px 0px 4px 0px #00000024;
  border-radius: 12px;

  @include respond-to("big-tablet") {
    gap: 16px;
  }
}

.sortHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include respond-to("big-tablet") {
    align-items: flex-start;
    gap: 20px;
  }

  .sortItem {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .toggle {
    border-radius: 8px;
    border: 1px solid #7647ee;
    height: 48px;
    width: 88px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include respond-to("big-tablet") {
      height: 24px;
      width: 44px;
      padding: 14px;
    }
  }
}

// .sortContainer {
//   padding: 0 50px;

//   @include respond-to("big-tablet") {
//     padding: 0px;
//   }
// }
