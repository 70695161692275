@import "../../../../assets/styles/base/variables";
@import "../../../../assets/styles/base/mixins";

.accounting_details {
  padding: 3.5em 0;
  display: flex;
  flex-direction: column;
}

.title_container {
  display: flex;
  margin-bottom: 42px;

  @include respond-to("mobile") {
    margin-bottom: 32px;
  }
}

.title_content {
  margin-left: 16px;
  .title {
    color: #15112d;

    @include respond-to("mobile") {
      font-size: 20px;
    }
  }

  .sub_title {
    color: #696677;

    @include respond-to("mobile") {
      font-size: 14px;
    }
  }
}

.iframe {
  border: none;
  border-radius: 16px;
  width: 100%;
  height: 1400px;

  @include respond-to("big-tablet") {
    height: 1800px;
  }

  @include respond-to("mobile") {
    height: 1600px;
  }
}
