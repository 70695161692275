@import "../../../../assets/styles/base/variables";
@import "../../../../assets/styles/base/mixins";

.allTables {
  padding-bottom: 40px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
