@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  align-items: center;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.orders {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
  gap: 30px;
  width: 100%;
}

.vl {
  border-left: 1px solid #696677;
  height: auto;
  @include respond-to("tablet") {
    border-left: none;
    border-bottom: 1px solid #696677;
  }
}
.main {
  margin-top: 15px;
  border-radius: 25px;
  border: 0.75px solid #c5c5c5;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 30px;
  height: 90vh;
  @include respond-to("tablet") {
    flex-direction: column;
  }
}
