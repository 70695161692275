@import "../../../../assets/styles/base/variables";
@import "../../../../assets/styles/base/mixins";

.product-categories-page {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    flex-wrap: wrap;
  }

  &__desc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
