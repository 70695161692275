@import "../../../../assets/styles/base/mixins";
@import "../../../../assets/styles/base/variables";

.bulkRestockPage {
  padding-top: 3.5em;
  display: flex;
  flex-direction: column;
  gap: 30px;

  &__content {
    background: $white-color;
    border-radius: 16px;
    padding-bottom: 24px;

    &__header {
      padding: 24px 24px 24px 24px;
      border-bottom: 2px solid #e7f1fa;
      display: flex;
      justify-content: space-between;
      gap: 10px;
      flex-wrap: wrap;

      @include respond-to("large") {
        padding: 15px 15px 5px 15px;
      }

      .searchSection {
        width: 100%;
        min-width: 500px;
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
  }

  .supplierSection {
    display: flex;
    gap: 10px;
    width: 100%;
    flex-wrap: wrap;

    @include respond-to("mobile") {
      flex-direction: column;
      flex-wrap: nowrap;
      gap: 5px;
    }

    & > div {
      min-width: 200px;
      width: calc(25% - 7.5px);

      @include respond-to("mobile") {
        width: 100%;
      }
    }
  }

  .summarySection {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px 24px;
    border: $light-border;
    border-radius: 8px;

    @include respond-to("large") {
      padding: 15px 15px 5px 15px;
    }

    @include respond-to("mobile") {
      gap: 20px;
    }
  }

  .summaryRow {
    display: flex;
    justify-content: space-between;
    gap: 20px;

    @include respond-to("mobile") {
      flex-direction: column;
      justify-content: flex-start;
      gap: 0px;
    }

    & > div {
      width: 200px;

      @include respond-to("mobile") {
        width: 100%;
      }
    }
  }

  .individualBody {
    margin: 40px 24px 0;
    @include respond-to("large") {
      margin: 15px 15px 5px 15px;
    }
  }

  .notesSection {
    display: flex;
    // align-items: center;
    gap: 26px;
    width: 100%;

    @include respond-to("mobile") {
      flex-direction: column;
    }

    .noteField {
      width: 65%;
      padding-top: 4px;

      @include respond-to("mobile") {
        width: 100%;
      }
    }

    .invoiceField {
      width: 35%;

      @include respond-to("mobile") {
        width: 100%;
      }
    }
  }
}
