@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.invoiceTable {
  .tableSection {
    width: 100%;
    min-height: 90vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
    table {
      border-collapse: collapse;
      background-color: $white-color;
      width: 100%;

      @include respond-to("large") {
        display: none;
      }

      th,
      td {
        padding: 15px 12px;
        font-size: 12px;
        text-align: left;
        min-width: 80px;
        max-width: 140px;

        &:first-child {
          min-width: 250px;
          max-width: 300px;
          padding-left: 24px;
        }
        &:nth-child(2) {
          width: 140px;
        }
        &:nth-child(3) {
          width: 190px;
          max-width: 200px;
        }
        &:nth-child(4) {
          width: 190px;
          max-width: 200px;
        }
        &:last-child {
          max-width: 200px;
          overflow: initial;
        }
      }

      tr {
        border-bottom: 2px solid #e7f1fa;
      }

      thead {
        th {
          color: #696677;
        }
      }

      tbody {
        tr {
          &:last-child {
            border-bottom: none;
          }
        }

        td {
          color: $dark-color;
          padding: 20px 12px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          &:first-child {
            overflow: visible;
            text-overflow: initial;
            white-space: wrap;
          }
        }
      }
    }

    .mobileViewTable {
      display: none;
      flex-direction: column;
      gap: 8px;
      background: #f3f1fc;
      @include respond-to("large") {
        display: flex;
      }
      .rowItem {
        border-radius: 8px;
        border: $light-border;
        width: 100%;
        background: $white-color;
        &__header {
          padding: 8px 15px;
          display: flex;
          justify-content: space-between;
          gap: 20px;
          border-bottom: $light-border;
        }
        &__body,
        &__subBody {
          padding: 10px 15px;
          display: flex;
          flex-direction: column;
          gap: 10px;
          .item {
            display: flex;
            justify-content: space-between;
            gap: 10px;
          }
        }
        &__subBody {
          background: #f3f1fc;
          padding: 10px 0;
          border-bottom: 2px solid #e7f1fa;
          &:last-child {
            border-bottom: none !important;
          }
          .item {
            padding: 0 15px;
          }
          .itemHeader {
            border-bottom: 1px solid $white-color;
          }
        }
        &__bottom {
          display: flex;
          justify-content: space-between;
          border-top: $light-border;
          padding: 10px 15px;

          .btn {
            height: 32px;
            font-size: 12px;
            width: 105px;
          }
        }
      }
    }
  }
  .stockBtn,
  .editBtn {
    font-size: 12px;
    height: 32px;
    padding: 0 16px;
  }
  .editBtn {
    padding: 0 5px 0 8px;
    background: $white-color;
    display: flex;
    font-size: 17px;
    border-radius: 8px;
    border: 1px solid #7647ee;
    cursor: pointer;
    align-items: center;
    outline: none;
    width: 34px;
    &:disabled {
      cursor: not-allowed;
    }
  }
  .repaymentTag {
    background-color: #1e016f;
    padding: 5px;
    border-radius: 8px;
    color: white;
    text-align: center;
  }
}
