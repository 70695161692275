@import "../../../assets/styles/base/mixins";
@import "../../../assets/styles/base/variables";

.stockHistoryTable {
  background-color: $white-color;

  &__content {
    table {
      th,
      td {
        &:first-child {
          min-width: 250px !important;
        }
      }
    }
  }
}
