@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.card {
  display: flex;
  flex-direction: column;
  gap: 12px;
  background: $white-color;
  border-radius: 12px;
  padding: 0 20px 20px 20px;
  overflow: hidden;
  height: -webkit-fill-available;
  height: -moz-available;

  &__img {
    height: 200px;
    width: calc(100% + 40px);
    margin-left: -20px;
    overflow: hidden;
    img {
      width: 100%;

      object-fit: cover;
    }
  }
}
