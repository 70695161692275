@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.sectionDetails {
  display: flex;
  flex-direction: column;
  gap: 30px;
  min-height: 100%;
  &__content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 15px;
    border: 1px solid #ddd;
    border-radius: 12px;
    padding: 0 12px 12px 12px;
    &__header {
      border-bottom: 1px solid #ddd;
      padding: 3px 12px;
      margin-left: -12px;
      width: calc(100% + 24px);
      background: $light-purple;
      display: flex;
      gap: 5px;
      align-items: center;
    }
  }
}
