@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.top-five-customers-table {
  border: 1px solid #f3f1fc;
  background-color: $white-color;
  margin-top: 24px;
  padding: 16px;
  border-radius: 8px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__section {
    margin-top: 16px;
    width: 100%;
    overflow: auto;

    table {
      border-collapse: collapse;
      background-color: $white-color;
      width: 100%;

      tr {
        border-bottom: 1px solid #edf2f7;
      }

      th,
      td {
        padding: 12px 20px;
        font-size: 14px;
      }

      thead {
        th {
          color: #696677;
        }
      }

      tbody {
        tr {
          &:last-child {
            border-bottom: none;
          }
        }

        td {
          color: $dark-color;
          padding: 12px 20px;
        }
      }
    }
  }
}
