@import "../../../assets/styles/base/mixins";
@import "../../../assets/styles/base/variables";

.customerForm {
  padding: 15px;
  .formSection {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 20px;
  }
}
