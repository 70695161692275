.terminal-credentials {
  &__header {
    margin-top: 32px;

    &-item {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }

  &__content {
    margin-top: 24px;
    background-color: white;
    padding: 24px;
    border-radius: 12px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;

    &-item {
      span {
        font-size: 14px;
        color: #696677;
      }

      &.reset__btn {
        span {
          color: white;
        }
      }
    }
  }
}
