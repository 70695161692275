@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.riderOrders {
  max-width: 500px;
  width: 100%;
  margin: auto;
  border: $light-border;
  min-height: 100vh;
  &.details {
    display: flex;
    flex-direction: column;
    gap: "10px";
    padding: 15px;
  }

  .riderListHeader {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: $tertiary-bg;
    padding: 10px 15px;
  }

  .riderList {
    display: flex;
    flex-direction: column;
    padding: 10px 0;

    &__item {
      display: flex;
      flex-direction: column;
      gap: 5px;
      padding: 15px;
      border-bottom: $light-border;
      &:last-child {
        border-bottom: none;
      }
    }
  }

  .orderDetails {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
}
