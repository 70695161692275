.measurrementInputField {
  display: flex;
  flex-direction: column;

  &__content {
    height: 50px;
    border-radius: 8px;
    border-width: 0.75px;
    border-color: rgba(151, 151, 151, 0.8);
    border-style: solid;
    display: grid;
    grid-template-columns: 1fr 0.3fr;
    align-items: center;
    padding: 0 17px;

    &:hover,
    &:focus-within {
      border-color: #646cff;
    }

    input {
      // height: 100%;
      background: transparent;
      border: none;
      outline: none;
      width: 50px;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    select {
      height: 100%;
      border: none;
      outline: none;
      background: transparent;

      option {
        background-color: #646cff;
        color: white;
      }
    }

    .error-message {
      font-size: 13px;
      visibility: hidden;
      display: flex;
      margin-top: 2px;
      min-height: 15px;

      &.show {
        visibility: visible;
      }
    }
  }
}
