.terminalList {
  display: flex;
  flex-direction: column;
  gap: 15px;

  .terminalItem {
    border: 1px solid #ddd;
    padding: 10px 10px;
  }
  .terminalLocation {
    font-size: 10px;
    padding: 0 10px;
    border-radius: 3px;
    color: #f7ad3c;
    background: #fef7eb;
    height: 25px;
    display: flex;
    align-items: center;
    margin-left: 15px;
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .printBtn {
    height: 35px;
    padding: 0 15px;
    font-size: 12px;
  }
}
