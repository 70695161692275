@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.pendingCheckout {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  margin-top: 30px;

  &__content {
    display: flex;
    flex-direction: column;
    border: 2px solid rgba(118, 71, 238, 1);
    min-height: 300px;
    border-radius: 16px;
    overflow: hidden;
  }

  table {
    th,
    td {
      &:nth-child(3) {
        min-width: 200px;
      }
      &:last-child {
        min-width: 200px !important;
      }
    }
  }
}
