@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.billsWidget {
  border-radius: 16px;
  border: 2px solid rgba(118, 71, 238, 1);
  overflow: auto;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  min-height: 90vh;
}

.billsWidgetHeader {
  box-shadow: 0px 1px 0px 0px rgba(237, 242, 247, 1);
  padding: 24px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  @include respond-to("tablet") {
    flex-wrap: wrap;
  }
}

.searchSection {
  max-width: 500px;
  width: 100%;
}

.billsWidgetContent {
  overflow: hidden;
  table {
    th,
    td {
      &:last-child {
        min-width: 350px !important;
      }
    }
  }
}
