@import "../../../../assets/styles/base/variables";
@import "../../../../assets/styles/base/mixins";

.title_container {
  display: flex;
  margin-bottom: 42px;

  @include respond-to("mobile") {
    margin-bottom: 32px;
  }
}

.picker_container {
  display: flex;
  margin-bottom: 22px;

  @include respond-to("mobile") {
    display: block;
  }
}
.profitandloss_details {
  padding: 3.5em 0;
  display: flex;
  flex-direction: column;
}

.title_content {
  margin-left: 16px;
  align-items: center;
  .title {
    color: #15112d;

    @include respond-to("mobile") {
      font-size: 20px;
    }
  }

  .sub_title {
    color: #696677;

    @include respond-to("mobile") {
      font-size: 14px;
    }
  }
}
.card {
  max-width: 950px;
  min-width: 200px;
  background-color: $white-color;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.14);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-top: 30px;
  padding: 15px;
}

.profitAndLost {
  padding: 3.5em 0;
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;

    .headerLeft {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      margin-left: auto;
    }
  }

  &__summary {
    max-width: 950px;
    min-width: 200px;
    background-color: $white-color;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.14);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-top: 0px;
  }

  &__datePicker {
    width: 310px;
    margin-left: 10px;
    margin-right: 10px;

    @include respond-to("mobile") {
      margin-top: 10px;
      width: 320px;
    }
  }

  &__storePicker {
    width: 200px;

    @include respond-to("mobile") {
      margin-top: 10px;
      width: 300px;
      margin-left: 10px;
    }
  }
}
.header {
  background-color: $light-green-lumi;
}
