@import "../../../../assets/styles/base/variables";
@import "../../../../assets/styles/base/mixins";

.billDetails {
  display: flex;
  flex-direction: column;
  min-height: 95vh;

  .orderCover {
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
  }

  .orderDetails {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
    margin-bottom: 20px;
    min-width: 930px;
    p {
      font-size: 14px;
    }
  }

  &__bottom {
    padding: 10px 0;
    display: flex;
    gap: 10px;
    margin-top: auto;
    position: sticky;
    bottom: -10px;
    background: $white-color;

    @include respond-to("mobile") {
      flex-direction: column;
      gap: 10px;
    }
  }
}

.inputField {
  width: 120px;
  height: 35px;
}

.dropdown {
  // @include dropdown(
  //   $width: 350px,
  //   $top: auto,
  //   $padding: 15px 10px,
  //   $right: auto
  // );
  // & {
  //   border: 1px solid $purple-color;
  //   position: initial;
  // }

  .row-item {
    padding: 7px 0;
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    gap: 15px;
    align-items: center;
  }
  .input-height {
    height: 40px;
  }
}
