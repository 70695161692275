@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.usersTable {
  .tableCover {
    overflow: visible;
  }
  table {
    th,
    td {
      &:last-child {
        text-align: right;
      }
    }
  }
}
