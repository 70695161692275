@import "../../../assets/styles/base/mixins";

.empty_container {
  padding: 10px;
  display: flex;
  flex-direction: column;

  .heading {
    align-self: center;
    margin-bottom: 32px;
  }

  .icon_container {
    align-self: center;
    margin-bottom: 44px;

    img {
      margin-bottom: 24px;
    }

    .campaign_text {
      text-align: center;
      font-weight: 400 !important;
    }
  }
}

.container {
  padding: 10px;
  display: flex;
  flex-direction: column;

  .heading {
    align-self: center;
  }

  .checkboxContainer {
    display: flex;
    align-items: center;
    gap: 24px;
    margin-top: 32px;
    margin-bottom: 32px;

    .checkbox {
      @include respond-to("tablet") {
        padding: 12px 10px;
      }
    }
  }

  .contentContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 8px;
    border: 0.75px solid rgba(151, 151, 151, 0.35);
    padding: 20px 16px;
    margin-bottom: 44px;

    @include respond-to("tablet") {
      padding: 16px 12px;
    }

    .giftCardInputContainer {
      display: flex;
      align-items: center;
      border: 1px solid #9797974d;
      padding: 8px 0px;
      width: 100%;
      height: 50px;
      border-radius: 4px;
      padding: 0 1em 2px 1em;

      .giftCardInput {
        height: 100%;
        width: 100%;
        border: none;
        outline: none;
      }
    }

    .whiteBackground {
      border-radius: 2px;
      border: 1px solid #9797974d;
      @include respond-to("tablet") {
        padding: 12px 0px;
      }

      .availableBalanceContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;

        .availableBalance {
          padding: 1px 7px;
          background: #f3f3f3;
          border-radius: 20px;
          font-size: 14px;

          @include respond-to("tablet") {
            padding: 1px 4px;
            font-size: 11px;
          }
        }

        .currencySymbol {
          color: #7647ee;
          font-size: 14px;
        }
      }
    }

    .balanceText {
      @include respond-to("tablet") {
        font-size: 12px;
      }
    }
  }
}
