@import "../../../assets/styles/base/variables";

.itemsInputField {
  width: 100%;

  .itemsCover {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    min-height: 50px;
    max-height: 150px;
    border: 0.75px solid rgba(151, 151, 151, 0.8);
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 5px 1em;
    overflow-y: auto;
    // background: #f3f1fc;
    &:hover {
      border-color: $purple-color;
    }

    &:focus-within {
      border-color: $purple-color;
    }

    &.error-border {
      border-color: $danger-color;
    }

    &__item {
      background: #c5c5c5;
      color: $dark-color;
      border-radius: 3px;
      padding: 2px 4px;
    }
  }

  input {
    height: 40px;
    background: transparent;
    outline: none;
    flex: 1 1 250px;
    min-width: 250px;
    border: none;
  }
}
