@import "../../../../../assets/styles/base/variables";

.customerCard {
  background-color: $white-color;
  border-radius: 12px;
  padding: 24px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 30px;
}
