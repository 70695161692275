@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.transactionDetails {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  gap: 15px;
  padding-bottom: 20px;

  p {
    font-size: 14px;
  }
}
