@import "../../../assets/styles/base/mixins";
@import "../../../assets/styles/base/variables";

.customerUpdate {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  .formSection {
    display: flex;
    flex-direction: column;
    gap: 5px;
    border-radius: 8px;
    border: 1px solid $light-gray-color;
    padding: 10px;
  }
}
