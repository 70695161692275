@import "../../assets/styles/base/variables";
@import "../../assets/styles/base/mixins";

.tags-layout {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
