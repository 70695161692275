@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.giftcardItem {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &__container {
    border: 0.75px solid #ad91f5;
    background: #f3f1fc;
    border-radius: 15.2772px;
    padding: 32px 24px 12px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 15px;

    .giftcardIcon {
      width: 76px;
      height: 76px;
    }

    .customSwitch {
      position: absolute;
      right: 15px;
      top: 10px;
    }

    &__points {
      background: #ffffff;
      border-radius: 4px;
      display: flex;
      align-items: center;
      max-width: fit-content;

      span {
        font-size: 12px;
        padding: 10px;
        font-weight: 500;

        &:first-child {
          background-color: $purple-color;
          color: $white-color;
          //border-top-left-radius: 4px;
          border-radius: 4px;
        }
      }
    }
  }
}
