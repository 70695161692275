@import "../../../../assets/styles/base/variables";
@import "../../../../assets/styles/base/mixins";

.productCard {
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: $white-color;
  padding: 10px;
  @include respond-to("big-tablet") {
    border: $light-border;
  }
  &__top {
    // padding: 10px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    gap: 30px;
    align-items: center;
    &.active {
      border: 1px solid $purple-color;
    }
    @include respond-to("big-tablet") {
      align-items: flex-start;
      gap: 15px;
    }
    @include respond-to("tablet") {
      flex-direction: column;
      gap: 15px;
    }
  }

  .left {
    display: flex;
    gap: 8px;
    width: calc(100% - 250px);
    @include respond-to("big-tablet") {
      align-items: flex-start;
      width: calc(100% - 110px);
    }
    @include respond-to("tablet") {
      width: 100%;
    }

    .productImage {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      width: 60px;
      height: 60px;
      overflow: hidden;
      flex-shrink: 0;
      background: #fdefd8;
      @include respond-to("big-tablet") {
        width: 60px;
        height: 60px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .productName {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: calc(100% - 80px);
      @include respond-to("big-tablet") {
        max-width: 400px;
      }

      @include respond-to("mobile") {
        max-width: 250px;
        font-size: 12px;
      }
    }

    .priceMobileView {
      display: none;
      @include respond-to("big-tablet") {
        // display: flex;
        flex-direction: column;
        gap: 6px;
      }
      p {
        font-size: 12px;
      }
      h6 {
        font-size: 14px;
      }
    }
  }
  .middle {
    display: flex;
    align-items: center;
    gap: 10px;
    max-width: 500px;
    @include respond-to("tablet") {
      max-width: 100%;
    }
    @include respond-to("mobile") {
      flex-wrap: wrap;
      justify-content: space-between;
    }

    p {
      font-size: 12px;
      width: 100%;
      text-align: left;
      max-width: 200px;
      span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      @include respond-to("tablet") {
        max-width: 120px;
      }
      @include respond-to("mobile") {
        max-width: 150px;
      }
      &:last-child {
        min-width: 102px;
        max-width: 110px;
        margin-left: auto;
        @include respond-to("mobile") {
          margin-left: 0;
        }
      }
      &.stock {
        background-color: #19ae57;
        color: $white-color;
        display: flex;
        align-items: center;
        gap: 5px;
        padding: 5px 10px;
        border-radius: 16px;
        height: 22.5px;
        justify-content: center;
        padding: 0 5px;
        width: 100px;
        max-width: none;

        &.lowQuantity {
          background-color: #ed0000;
        }
      }
    }
  }

  .right {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    width: 200px;
    // align-items: flex-start;
    @include respond-to("big-tablet") {
      width: auto;
      max-width: 200px;
    }
    @include respond-to("mobile") {
      width: 100%;
      max-width: none;
    }
  }

  .product__actions {
    display: flex;
    align-items: center;
    gap: 8px;
    @include respond-to("mobile") {
      width: 100%;
    }

    button {
      outline: none;
      background: none;
      border: none;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      border-radius: 4px;
      @include respond-to("mobile") {
        height: 32px;
        width: 100%;
      }

      &.increment {
        background-color: $purple-color;
        color: white;
      }

      &.decrement {
        background-color: $white-color;
        color: $purple-color;
        border: 1px solid $purple-color;
      }
    }

    .countLoader {
      width: 25px;
      font-size: 15px;
      @include respond-to("mobile") {
        width: 100%;
      }
    }
  }

  .addToCartBtn {
    padding: 10px 16px;
    height: 40px;
    font-size: 12px;
    width: 110px;
    @include respond-to("mobile") {
      width: 100%;
    }
  }
}
