@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.allAccounting {
  height: 100%;
  display: flex;
  flex-direction: column;
  .formSection {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 30px;
  }
  .daterangeSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
