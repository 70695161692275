@import "../../../../../../../assets//styles/base/variables";
@import "../../../../../../../assets//styles/base/mixins";

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 56px;
  width: 100%;

  @include respond-to("big-tablet") {
    margin-top: 0;
    gap: 16px;
    font-size: 14px;
  }

  h3 {
    font-size: 20px;

    @include respond-to("big-tablet") {
      font-size: 16px;
      display: none;
    }
  }
}

.createContainer {
  box-shadow: 0px 0px 4px 0px #00000024;
  border-radius: 12px;
  padding: 40px 32px;
  width: 800px;
  align-self: center;

  @include respond-to("big-tablet") {
    width: 100%;
    padding: 12px 8px;
    box-shadow: none;
    border: 0.74px solid #9797974d;
    border-radius: 8px;
  }
}
