@import "../../../../../../../assets//styles/base/mixins";

.sortContainer {
  width: 100%;
}
.sortCategoryHeading {
  h5 {
    font-size: 20px;
  }

  @include respond-to("big-tablet") {
    h5 {
      font-size: 18px;
    }

    p {
      font-size: 12px;
    }
  }
}
.categoryContainer {
  margin-top: 24px;
  .categoryCard {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 0px 4px 0px #00000024;
    padding: 16px 24px;
    border-radius: 12px;
    margin-bottom: 8px;
    cursor: grab;

    @include respond-to("big-tablet") {
      padding: 12px;
    }

    .categoryText {
      color: #15112d;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;

      @include respond-to("big-tablet") {
        font-size: 16px;
      }
    }
  }
  .draggingCard {
    pointer-events: none;
    touch-action: none;
    -ms-touch-action: none;
    box-shadow: 2px 20px 30px rgba(0, 0, 0, 0.2);
  }
}
