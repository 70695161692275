@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.menuGridCard {
  border: $light-border;
  position: relative;
  min-height: 230px;
  height: 100%;
  width: calc((20% - 12px));
  gap: 10px;
  @include respond-to("large") {
    width: calc((25% - 11.25px));
  }
  @include respond-to("small") {
    width: calc((33.3% - 10px));
  }
  @include respond-to("tablet") {
    width: calc((50% - 7.5px));
  }
  @include respond-to("mobile") {
    height: 210px;
  }
}
