@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.card {
  border: $light-border;
  border-radius: 4px;
  padding: 14px;
  margin-top: 25px;
}

.divider {
  margin-left: -22px;
  margin-right: -22px;
}

.dividerInner {
  margin-left: -15px;
  margin-right: -15px;
}

.scrollableCountryDiv {
  height: 500px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  margin-left: -13.9px;
  margin-right: -13.9px;
}

.scrollableCountryDiv::-webkit-scrollbar {
  display: none;
}

.stateCard {
  background: #f3f1fc;
  border-top: 1px solid $light-border;
  margin-top: -8px;
}

.countryList {
  background: #f8f8f8;
  margin-bottom: -9px;
}
