@import "../../../../../../../../../assets//styles/base/variables";
@import "../../../../../../../../../assets//styles/base/mixins";

.container {
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 48px;
  box-shadow: 0px 0px 4px 0px #00000024;
  border-radius: 12px;

  @include respond-to("big-tablet") {
    gap: 32px;
  }
}

.brandHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include respond-to("big-tablet") {
    align-items: flex-start;
    gap: 20px;
  }

  .brandItem {
    display: flex;
    align-items: center;
    gap: 20px;
  }
}

.brandContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  @include respond-to("big-tablet") {
    width: 100%;
  }

  .iconContainer {
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;

    @include respond-to("big-tablet") {
      flex-direction: column;
      gap: 0;
    }
  }

  .brandLogo {
    width: 100%;
    border: 1px solid #97979759;
    border-radius: 8px;
    display: flex;
    flex-direction: column;

    @include respond-to("big-tablet") {
      height: 100%;
    }

    .logoHeading {
      background: #f3f1fc;
      border: 1px solid #9797974d;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      display: flex;
      align-items: center;
      padding: 8px 16px;
    }

    .bannerContainer {
      padding: 8px 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 4px;
      padding-bottom: 72px;

      .uploadContainer {
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;

        p {
          font-size: 12px;
          color: #7647ee;
          font-weight: 700;
        }
      }

      .bannerFrame {
        position: relative;
        width: 100%;
        height: 195px;
        border: 0.8px dashed #979797;
        background: #efefef;
        border-radius: 3.52px;
        display: flex;
        flex-direction: column;
        align-items: center;

        h6 {
          margin-top: 12px;
        }

        .logo {
          height: 100%;
          width: 100%;
          object-fit: contain;
        }

        .logoContainer {
          display: flex;
          align-items: center;
          flex-direction: column;
          gap: 4px;
          cursor: pointer;
          position: absolute;
          left: auto;
          bottom: -60px;

          .logoSection {
            box-shadow: 0px 0px 4px 0px #00000024;
            border: 1px solid #9797974d;
            background: #fff;
            height: 88px;
            width: 88px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px;

            p {
              text-align: center;
              font-size: 10px;
              line-height: 16px;
              font-weight: 700;
              width: 80%;
            }

            .logo {
              height: 100%;
              width: 100%;
              object-fit: contain;
            }
          }

          .uploadItem {
            display: flex;
            align-items: center;
            gap: 8px;

            p {
              font-size: 12px;
              color: #7647ee;
              font-weight: 700;
            }
          }
        }
      }
    }
  }

  .colorPallete {
    width: 100%;
    border: 1px solid #97979759;
    border-radius: 8px;
    display: flex;
    flex-direction: column;

    @include respond-to("big-tablet") {
      height: 100%;
    }

    .colorHeading {
      background: #f3f1fc;
      border: 1px solid #9797974d;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      display: flex;
      align-items: center;
      padding: 8px 16px;
    }

    .colorContainer {
      display: flex;
      justify-content: center;
      padding: 16px;
      gap: 8px;

      @include respond-to("big-tablet") {
        flex-direction: column;
        padding: 12px;
      }

      .colorItem {
        width: 100%;
        border: 1px solid #e8e9eb;
        padding: 12px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        @include respond-to("big-tablet") {
          align-items: flex-start;
        }

        .textContainer {
          p {
            font-size: 14px;
            line-height: 24px;
            color: #15112d;
          }

          span {
            font-size: 12px;
            line-height: 18px;
            color: #696677;
          }
        }

        .colorCircle {
          height: 40px;
          width: 40px;
          border-radius: 999px;
          border: 1.43px solid #000000;
          background: #7647ee;
        }
      }
    }
  }
}

.modalContainer {
  display: flex;
  flex-direction: column;
  gap: 48px;
  padding: 12px;

  .colorPaletteContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .heading {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      p {
        font-size: 24px;
        line-height: 32px;
        font-weight: 500;
        color: #15112d;
      }

      span {
        font-size: 16px;
        line-height: 24px;
        color: #696677;
      }
    }
  }

  .buttonsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
