@import "../../../../../../assets/styles/base/mixins";
@import "../../../../../../assets/styles/base/variables";

.back {
  margin-top: 3.5em;
  margin-bottom: 32px;
}

.role_heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;

  .edit_btn {
    width: 200px;
    @include respond-to("tablet") {
      width: 100px;
    }
  }
}

.table_container {
  padding: 16px;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.14);
  background-color: white;
  @include respond-to("tablet") {
    display: none;
  }
  // p {
  //   width: 33%;
  //   &:first-child {
  //     width: 30%;
  //     padding-left: 0;
  //   }
  // }

  .header {
    display: flex;
    p {
      font-size: 16px;
      font-weight: 700;
      width: 33%;
      padding: 0px 24px 10px 24px;
      &:first-child {
        width: 30%;
        padding-left: 0;
      }
    }
  }
  .title {
    text-transform: uppercase;
    font-size: 14px;
  }
  .authorized_title {
    color: $success-color !important;
    font-weight: 700;
    font-size: 16px !important;
  }
  .unauthorized_title {
    color: $danger-color !important;
    font-weight: 700;
    font-size: 16px !important;
  }

  .table_column {
    padding: 25px 24px 15px 24px;
    border-right: 1px solid #e8e9eb;
    border-bottom: 1px solid #e8e9eb;
    width: 33%;
    display: flex;
    // align-itemss: center;
    flex-direction: column;
    gap: 12px;

    .column_item {
      display: flex;
      align-items: center;
    }

    &:first-child {
      width: 30%;
      padding-left: 0;

      p {
        text-transform: uppercase;
        font-weight: 700;
      }
    }
    &:last-child {
      border-right: none;
    }
    p {
      font-size: 14px;
      color: $dark-color;
      margin-left: 8px;
      text-transform: capitalize;
    }
  }
  .table_row {
    display: flex;
    &:last-child {
      & > div {
        border-bottom: none !important;
      }
    }
  }
}

.planMobileView__content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  display: none;
  @include respond-to("tablet") {
    display: block;
  }

  .card {
    padding: 10px 20px;
    border-radius: 8px;
    border: $light-border;
    position: relative;
    &.isActive {
      .card__header {
        border-bottom: $light-border;
      }
    }

    &__header {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      align-items: center;
      border: none;
      padding-bottom: 5px;
      h5 {
        text-transform: uppercase;
        margin-bottom: 0;
      }
    }

    p {
      color: $dark-color;
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin-top: 15px;

      .authorized_title {
        color: $success-color !important;
        font-weight: 700;
      }

      .unauthorized_title {
        color: $danger-color !important;
        font-weight: 700;
      }

      .column_item {
        display: flex;
        align-items: center;

        p {
          margin-left: 8px;
        }
      }
    }
  }
}
