@import "../../../assets/styles/base/mixins";
@import "../../../assets/styles/base/variables";

.editCampaignForm {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  &__group {
    display: flex;
    gap: 15px;
    @include respond-to("big-tablet") {
      flex-direction: column;
    }
    & > div {
      width: 100%;
    }
  }
  &__store {
    display: grid;
    column-gap: 150px;
    row-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    margin-top: 20px;
  }
}
