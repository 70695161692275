@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.item {
  padding: 24px;
  border-bottom: 1.5px solid #f2f2f2;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
