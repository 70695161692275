@import "./../../../assets/styles/base/mixins";
@import "./../../../assets/styles/base/variables";

.signupPage {
  &__sidebar {
    &__content {
      margin-top: 100px;

      &__title {
        font-weight: normal;
      }
    }
  }

  &__content {
    margin-top: 4em;
    @include respond-to("mobile") {
      margin-top: 30px !important;
    }
  }
}
