@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.addTableForm {
  padding: 32px;

  &__form {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
