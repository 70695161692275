@import "../../../../assets/styles/base/variables";
@import "../../../../assets/styles/base/mixins";

.suppliers {
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
  }

  &__content {
    background: $white-color;
    border-radius: 16px;
    padding-bottom: 24px;
    min-height: 400px;
  }
}
