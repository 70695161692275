@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.suppliersTable {
  &__content {
    background-color: $white-color;
    border-radius: 16px;
    width: 100%;
  }
  table {
    th,
    td {
      &:last-child {
        min-width: 300px !important;
      }
    }
  }
}
