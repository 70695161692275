@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.chart-legend {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 33px;

  min-width: 113px;
  max-width: fit-content;
  padding: 6px 10px;

  border: 1px solid #fd561f;
  border-radius: 4px;

  & > div {
    height: 16px;
    width: 16px;
    border-radius: 2px;
    background-color: #fd561f;
  }

  & > span {
    font-size: 10px;
    color: #fd561f;
  }
}

.inventory-profit-chart-tooltip {
  min-width: 158.96px;
  min-height: 68px;
  padding: 20px;
  border: 1px solid #fd561f;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  outline: none;
  z-index: 2;

  filter: drop-shadow(0px 8px 24px rgba(44, 63, 88, 0.1));

  &__label {
    display: flex;
    align-items: center;
    margin-top: 10px;

    font-size: 12px;
    font-weight: 400;
    color: #15112d;
  }

  &__value {
    display: flex;
    align-items: center;
    flex-direction: row;

    font-size: 16px;
    font-weight: 500;
    color: #15112d;

    &::before {
      content: "";
      display: block;
      width: 8px;
      height: 8px;
      background-color: #fd561f;
      border-radius: 100%;
      margin-right: 10px;
    }
  }
}
