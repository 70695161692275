@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.allStores {
  .storeList {
    gap: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

    &__item {
      height: 225px;
      background: $white-color;
      border-radius: 25px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 24px;
      border: 0.75px solid #c5c5c5;
    }
  }
}
