@import "../../../../assets/styles/base/mixins";

.cards_container {
  display: grid;
  place-items: center;
  column-gap: 20px;
  row-gap: 24px;
  grid-template-columns: repeat(3, minmax(0, 1fr));

  @include respond-to("large") {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @include respond-to("big-tablet") {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @include respond-to("tablet") {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @include respond-to("mobile") {
    column-gap: 10px;
    row-gap: 10px;
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

.title {
  color: #15112d;
  text-align: center;
}

.card {
  border: 0.75px solid #c5c5c5;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.14);
  background: #fff;
  border-radius: 12px;
  display: flex;
  padding: 48px 24px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 40px;
  flex: 1 0 0;
  width: 100%;
}
