@import "../../assets/styles/base/variables";
@import "../../assets/styles/base/mixins";

.dashboard {
  width: 100%;
  height: 100%;

  &__content {
    &__actions {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: flex-end;
      margin-left: auto;
      gap: 8px;
    }

    &__accounts__menu {
      margin-left: auto;
      max-width: 20%;
    }
  }
}
