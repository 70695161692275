@import "../../../../assets/styles/base/variables";
@import "../../../../assets/styles/base/mixins";

.supplierDetails {
  padding: 3.5em 0;
  display: flex;
  flex-direction: column;
  gap: 30px;

  &__header {
    justify-content: space-between;
    gap: 30px;
  }

  &__card {
    display: flex;
    gap: 20px;
    background-color: $white-color;
    border-radius: 12px;
    padding: 24px;
    flex-wrap: wrap;
    & > div {
      width: calc(25% - 15px);
      @include respond-to("big-tablet") {
        width: calc(33.33% - 13.33px);
      }
      @include respond-to("tablet") {
        width: calc(50% - 10px);
      }
    }
  }
}
