@import "../../assets/styles/base/mixins";
@import "../../assets/styles/base/variables";

.customers-layout {
  &__buttons {
    display: flex;
    gap: 16px;
    width: fit-content;
  }
}
