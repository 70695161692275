@import "../../../assets/styles/base/variables";
@import "../../../assets//styles/base/mixins";

.wallet__option {
  width: 100%;
  max-width: 350px;
  display: flex;
  align-items: flex-start;
  padding: 15px 16px;
  background-color: $white-color;
  border: 1.5px solid rgba(149, 149, 149, 0.35);
  border-radius: 8px;
  gap: 12px;
  cursor: pointer;

  @include respond-to("tablet") {
    max-width: 100%;
  }

  &.checked {
    border: 2px solid #7647ee;
  }
  &.disabled {
    cursor: not-allowed;
  }

  input[type="radio"] {
    width: 100%;
    height: 100%;
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    // cursor: pointer;

    &:checked {
      & + .wallet__option__indicator {
        &::before {
          opacity: 1;
        }
      }
    }
  }

  &__indicator {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid #c5c5c5;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    &::before {
      content: "";
      width: 15.6px;
      height: 15.6px;
      border-radius: 50%;
      opacity: 0;
      background: #7647ee;
    }
  }
}
