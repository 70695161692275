.bvnConsent {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px;

  &__content {
    max-width: 100%;
    min-height: 400px;
    border: 1.5px solid #ddd;
    border-radius: 12px;
  }
}
