@import "../../../../assets/styles/base/variables";
@import "../../../../assets/styles/base/mixins";

.profile-page {
  &__section {
    display: flex;
    gap: 70px;

    &__left {
      width: 100%;
      max-width: 450px;
      min-height: 300px;
      height: fit-content;
      background-color: white;
      display: flex;
      flex-direction: column;
      // justify-content: center;
      align-items: center;
      border-radius: 12px;
      padding: 25px 15px;
      gap: 10px;

      @include respond-to("big-tablet") {
        display: none;
      }

      &__avatar {
        width: 80px;
        height: 80px;
        flex-shrink: 0;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $purple-color;
        font-size: 2rem;
        background: #e4dafc;
      }
      &__logo {
        width: 100px;
        text-align: center;
        img {
          width: 80%;
        }
      }
    }

    &__right {
      width: 100%;
      // height: 657px;
      border-radius: 12px;
      box-shadow: -4px 8px 24px 0px #2c3f5805;
      background-color: white;
      padding: 30px;

      @include respond-to("big-tablet") {
        width: 100%;
      }
      @include respond-to("tablet") {
        padding: 20px 15px;
      }
    }
  }
}
