@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.allProductsTable {
  .tableSection {
    table {
      th,
      td {
        padding: 20px 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &:first-child {
          min-width: 120px;
        }
        &:nth-child(2) {
          min-width: 220px;
          max-width: 270px;
          padding-left: 24px;
          overflow: visible;
          text-overflow: initial;
          white-space: wrap;
        }
        &:nth-child(3) {
          width: 140px;
        }
        &:nth-child(4) {
          width: 190px;
          max-width: 200px;
        }
        &:nth-child(5) {
          width: 190px;
          max-width: 200px;
        }
        &:last-child {
          max-width: 260px;
          overflow: initial;
        }
      }

      tr {
        border-bottom: 2px solid #e7f1fa;
        &:last-child {
          border-bottom: none;
        }
      }
    }

    .mobileViewTable {
      display: none;
      flex-direction: column;
      gap: 8px;
      background: #f3f1fc;
      @include respond-to("big-tablet") {
        display: flex;
      }
      .rowItem {
        border-radius: 8px;
        border: $light-border;
        width: 100%;
        background: $white-color;
        &__header {
          padding: 8px 15px;
          display: flex;
          justify-content: space-between;
          gap: 20px;
          border-bottom: $light-border;
        }
        &__body,
        &__subBody {
          padding: 10px 15px;
          display: flex;
          flex-direction: column;
          gap: 10px;
          .item {
            display: flex;
            justify-content: space-between;
            gap: 10px;
          }
        }
        &__subBody {
          background: #f3f1fc;
          padding: 10px 0;
          border-bottom: 2px solid #e7f1fa;
          &:last-child {
            border-bottom: none !important;
          }
          .item {
            padding: 0 15px;
          }
          .itemHeader {
            border-bottom: 1px solid $white-color;
          }
        }
        &__bottom {
          display: flex;
          justify-content: space-between;
          border-top: $light-border;
          padding: 10px 15px;

          .btn {
            height: 32px;
            font-size: 12px;
            width: 105px;
          }
        }
      }
    }
  }

  .stockBtn,
  .editBtn {
    font-size: 12px;
    height: 32px;
    padding: 0 16px;
    width: 90px;
  }
  .editBtn {
    padding: 0 5px 0 8px;
    background: $white-color;
    display: flex;
    font-size: 17px;
    border-radius: 8px;
    border: 1px solid #7647ee;
    cursor: pointer;
    align-items: center;
    outline: none;
    width: 34px;
    &:disabled {
      cursor: not-allowed;
    }
  }
}
