@import "../../../assets/styles/base/variables";

.send-receipt-widget {
  &__content {
    background-color: $white-color;
    height: 100%;
    padding: 24px 0px;
    .back-btn {
      height: 40px;
      padding: 0 15px;
    }
    .close__icon {
      cursor: pointer;
      font-size: 25px;
    }

    &__title {
      margin-top: 24px;
    }

    &__form {
      margin-top: 32px;
      display: flex;
      flex-direction: column;
      gap: 13px;

      .boxed-radio {
        padding: 16px;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.14);
        border: none;
        height: 56px;
      }
    }
  }
}
