@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.storeCheckout {
  padding: 20px 0 20px 0;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__purchaseOptions {
    display: flex;
    gap: 16px;
    width: 100%;
    margin: 16px 0;
    flex-wrap: wrap;
    @media screen and (max-width: 500px) {
      flex-direction: row;
      gap: 10px;
    }
  }

  &__purchaseOption {
    border: 1px solid rgba(0, 0, 0, 0.1);
    min-width: 115px;
    border-radius: 12px;
    @include respond-to("mobile") {
      width: calc(50% - 5px);
    }

    .wallet__option {
      justify-content: center;
      padding: 16px;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      @media screen and (max-width: 500px) {
        justify-content: flex-start;
      }
    }
  }
  .optionContents {
    margin-top: 25px;
  }
}
