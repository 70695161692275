@import "../../../assets/styles/base/variables";

.radio__input {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  height: 72px;
  padding: 24px 16px 24px 24px;
  position: relative;
  cursor: pointer;
  background: #ffffff;
  border-radius: 4px;
  border: 0.75px solid rgba(151, 151, 151, 0.8);

  &.checked {
    border: 2px solid $purple-color;
  }

  input {
    position: absolute;
    visibility: hidden;
  }

  input:checked + .radio__input-indicator::before {
    background: $purple-color;
    color: #fff;
  }

  &-indicator {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid #c5c5c5;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
      content: "";
      display: block;
      width: 15.6px;
      height: 15.6px;
      border-radius: 50%;
      background-color: $white-color;
    }
  }
}
