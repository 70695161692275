@import "../../../../assets/styles/base/variables";
@import "../../../../assets/styles/base/mixins";

.productCard {
  display: flex;
  flex-direction: column;
  gap: 4px;

  &__top,
  &__bottom {
    padding: 10px;
    border-radius: 8px;
    background-color: $white-color;
    @include respond-to("big-tablet") {
      border: 0.75px solid rgba(149, 149, 149, 0.35);
      // align-items: flex-start;
      // gap: 15px;
    }
    &.active {
      border: 1.5px solid $purple-color;
      @include respond-to("big-tablet") {
        box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.16);
      }
    }
  }

  &__top {
    display: flex;
    flex-direction: column;
    gap: 8px;
    &__sub {
      display: flex;
      justify-content: space-between;
      gap: 30px;
      cursor: pointer;
      align-items: center;
      @include respond-to("big-tablet") {
        align-items: flex-start;
      }
      @include respond-to("tablet") {
        flex-direction: column;
        gap: 15px;
      }
    }
  }

  &__bottom {
    padding: 14px 0px;
    display: flex;
    gap: 10px;
    flex-direction: column;

    .left {
      @include respond-to("big-tablet") {
        flex-direction: column;
        gap: 2px !important;
      }
    }
  }

  .left {
    display: flex;
    gap: 8px;
    width: calc(100% - 250px);
    @include respond-to("big-tablet") {
      align-items: flex-start;
      width: 100%;
    }

    .productImage {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      width: 60px;
      height: 60px;
      background: #fdefd8;
      @include respond-to("big-tablet") {
        width: 60px;
        height: 60px;
      }

      img {
        width: 100%;
        object-fit: cover;
        height: 100%;
      }
    }
    .productName {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: calc(100% - 80px);
      @include respond-to("big-tablet") {
        max-width: 400px;
      }

      @include respond-to("mobile") {
        font-size: 12px;
        max-width: 250px;
      }
    }
  }
  .middle {
    display: flex;
    align-items: center;
    gap: 10px;
    max-width: 500px;
    @include respond-to("tablet") {
      max-width: 100%;
    }
    @include respond-to("mobile") {
      flex-wrap: wrap;
      justify-content: space-between;
    }

    p {
      font-size: 12px;
      width: 100%;
      text-align: left;
      max-width: 202px;
      span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      @include respond-to("tablet") {
        max-width: 125px;
      }
      @include respond-to("mobile") {
        max-width: 150px;
      }

      &.stock {
        background-color: #19ae57;
        color: $white-color;
        display: flex;
        align-items: center;
        gap: 5px;
        padding: 5px 10px;
        border-radius: 16px;
        height: 22.5px;
        justify-content: center;
        padding: 0 5px;
        width: 100px;
        max-width: none;

        &.lowQuantity {
          background-color: #ed0000;
        }
      }
    }
  }

  .right {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    width: 200px;
    @include respond-to("big-tablet") {
      width: auto;
      max-width: 200px;
    }
    @include respond-to("mobile") {
      width: 100%;
      max-width: none;
    }
  }

  .variantHeader {
    border-bottom: 1px solid #ddd;
    padding-left: 10px;
    margin-bottom: 0;
    padding-bottom: 5px;
    width: 100%;
  }

  .productSubCard {
    display: flex;
    border-bottom: 1px solid #ddd;
    padding: 0 10px 8px 10px;
    justify-content: space-between;
    @include respond-to("big-tablet") {
      align-items: flex-start;
    }
    @include respond-to("tablet") {
      flex-wrap: wrap;
      padding-left: 10px;
    }

    .left {
      flex-direction: column;
      @include respond-to("tablet") {
        margin-bottom: 10px;
      }
    }
    .middle {
      max-width: 560px;
      justify-content: space-between;
      p {
        &:first-child {
          max-width: 265px;
          @include respond-to("tablet") {
            max-width: 185px;
          }
          @include respond-to("mobile") {
            max-width: 150px;
          }
        }
        &:last-child {
          min-width: 102px;
          max-width: 110px;
          margin-left: auto;
          @include respond-to("mobile") {
            width: auto;
            text-align: right;
            max-width: none;
          }
        }
      }
    }

    &:last-child {
      border: none;
      padding-bottom: 0;
    }
  }

  .product__actions {
    display: flex;
    align-items: center;
    gap: 8px;
    @include respond-to("mobile") {
      width: 100%;
    }

    button {
      outline: none;
      background: none;
      border: none;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      border-radius: 4px;
      @include respond-to("mobile") {
        height: 32px;
        width: 100%;
      }

      &.increment {
        background-color: $purple-color;
        color: white;
      }

      &.decrement {
        background-color: $white-color;
        color: $purple-color;
        border: 1px solid $purple-color;
      }
    }

    .countLoader {
      width: 25px;
      font-size: 15px;
      @include respond-to("mobile") {
        width: 100%;
      }
    }
  }

  .viewBtn,
  .addToCartBtn {
    padding: 10px 16px;
    height: 40px;
    font-size: 12px;
    width: 110px;
    @include respond-to("mobile") {
      width: 100%;
    }
  }
}

.variantImg {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  img {
    background-size: contain;
    width: 100%;
  }
}
