@import "../../../../../assets/styles/base/mixins";

.storeCustomerForm {
  height: 100%;
  display: flex;
  flex-direction: column;
  &__bottom {
    position: sticky;
    bottom: 0px;
    margin-top: auto;
    padding-top: 10px;
    background: #fff;
    display: flex;
    gap: 20px;
  }
}

.back_icon_container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  border-radius: 8px;
  border: 1px solid #7647ee;
  padding: 12px 0px;
  cursor: pointer;
}

.checkout_heading_container {
  margin-top: 16px;
  margin-bottom: 24px;
}

.checkout_selection {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;

  .selection_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    @include respond-to("big-tablet") {
      width: 100%;
      flex-direction: column;
      gap: 8px;
    }

    .selection {
      cursor: pointer;
      border: 1px solid #7647ee;
      border-radius: 8px;
      padding: 8px 16px;
      display: flex;
      align-items: flex-start;
      gap: 12px;

      @include respond-to("big-tablet") {
        padding: 8px 16px;
        width: 100%;
      }

      .radio_selected {
        width: 24px;
        height: 24px;
        border-radius: 30px;
        border: 1.5px solid #7647ee;
        display: flex;
        align-items: center;
        justify-content: center;

        .radio_inner {
          width: 15.6px;
          height: 15.6px;
          border-radius: 30px;
          background: #7647ee;
        }
      }

      .radio_unselected {
        width: 24px;
        height: 24px;
        border-radius: 30px;
        border: 1.5px solid #c5c5c5;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .text_container {
        h6 {
          font-size: 20px;
          font-weight: 400;
        }

        p {
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }
}

.existing_user {
  border-radius: 8px;
  border: 0.75px solid rgba(151, 151, 151, 0.35);
  padding: 20px;
  margin-bottom: 24px;

  h5 {
    font-weight: 500;
    margin-bottom: 16px;
  }
}

.restaurant_table_container {
  border-radius: 8px;
  border: 0.75px solid rgba(151, 151, 151, 0.35);
  padding: 20px;
  margin-bottom: 24px;
}

.restaurant_table {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.selected_table_container {
  width: "100%";
  display: flex;
  align-items: flex-start;
}

.selected_table {
  display: flex;
  align-items: flex-end;
  margin-bottom: 24px;
  background: rgba(197, 197, 197, 0.3);
  border-radius: 8px;
  padding: 8px 12px;
  .table_name {
    font-size: 10px;
    color: #15112d;
    margin-right: 8px;
    span {
      font-weight: 700;
    }
  }
}

.restaurant_heading_container {
  width: 70%;
  margin-bottom: 16px;
}

.restaurant_heading {
  display: flex;
  align-items: center;
}

.restaurant_text {
  margin-left: 10px;
  font-weight: 500;
  font-size: 20px;
  color: #15112d;
}

.order_notes_container {
  padding: 12px 16px;
  border-radius: 8px;
  border: 0.75px solid rgba(151, 151, 151, 0.35);
  margin-bottom: 24px;
}

.text_area {
  font-size: 14px;
  margin-top: 8px;
}

.logistics_container {
  padding: 16px;
  border-radius: 8px;
  border: 0.75px solid rgba(151, 151, 151, 0.35);
  margin-bottom: 56px;
}
