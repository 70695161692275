.generatedPrescriptions {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__items {
    display: flex;
    flex-direction: column;
    gap: 15px;

    &-item {
      border: 1px solid #ddd;
      padding: 10px 10px;
      margin-top: 20px;

      .waybill_header {
        font-size: 10px;
        padding: 0 10px;
        border-radius: 3px;
        color: #f7ad3c;
        background: #fef7eb;
        height: 25px;
        display: flex;
        align-items: center;
        margin-left: 15px;
        max-width: 250px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .printBtn {
        height: 35px;
        padding: 0 15px;
        font-size: 12px;
      }
    }
  }
}
