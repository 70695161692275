@import "../../../assets/styles/base/variables";

.revenue-chart-tooltip {
  width: 158.96px;
  min-height: 68px;
  border: 1px solid $purple-color;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  outline: none;
  z-index: 2;

  &__label {
    font-size: 12px;
    font-weight: 400;
    color: #696677;
  }

  &__value {
    font-size: 16px;
    font-weight: 500;
    color: #15112d;
  }
}
