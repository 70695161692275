@import "./variables";

$breakpoints: (
  "mobile": 485px,
  "tablet": 650px,
  "big-tablet": 850px,
  "small": 1000px,
  "large": 1199px,
);

@mixin respond-to($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (max-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

@mixin dropdown(
  $width: 100%,
  $background: $white-color,
  $padding: 25px,
  $right: 0,
  $top: 55px
) {
  background: $background;
  border-radius: 8px;
  width: $width;
  padding: $padding;
  position: absolute;
  right: $right;
  top: $top;
  z-index: 5;
  box-shadow: 1px 1px 3px 2px #ddd;
}
