.invoiceHistoryTable {
  table {
    th,
    td {
      min-width: 200px;
      max-width: 250px;
      &:nth-child(2) {
        min-width: 150px;
        max-width: 200px;
      }
      &:first-child {
        min-width: 250px;
        max-width: 350px;
      }
    }
  }
}
