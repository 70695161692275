@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.expenses-table {
  &__content {
    background-color: $white-color;
    border-radius: 16px;
    width: 100%;
  }
}
