@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.users-table {
  width: 100%;
  overflow: auto;

  table {
    border-collapse: collapse;
    background-color: $white-color;
    border-radius: 16px;
    width: 100%;

    @include respond-to("tablet") {
      display: none;
    }

    th,
    td {
      padding: 18px 24px;
      font-size: 12px;
      text-align: left;
      min-width: 140px;
      max-width: 200px;

      &:first-child {
        min-width: 50px;
      }
    }

    tr {
      border-bottom: 1px solid #edf2f7;
    }

    thead {
      th {
        padding: 24px;
        color: #696677;
      }
    }

    tbody {
      tr {
        &:last-child {
          border-bottom: none;
        }
      }

      td {
        color: $dark-color;
        padding: 18px 24px;

        &:last-child {
          padding: 12px 30px;
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .mobile-table {
    background: $white-color;
    display: none;

    @include respond-to("tablet") {
      display: block;
    }

    &__row {
      padding: 16px;
      border: 1px solid #97979759;
      display: flex;
      flex-direction: column;
      gap: 10px;
      border-radius: 8px;
      margin-bottom: 12px;
    }

    .row-item {
      display: flex;
      align-items: center;
      gap: 20px;
      justify-content: space-between;
      border-bottom: 1px solid #edf2f7;
      margin-bottom: 8px;
      padding-bottom: 8px;
      &:last-child {
        margin-bottom: 0;
        border-bottom: 0px;
      }

      &__value {
        font-size: 12px;
      }

      &__title {
        margin-bottom: 0;
        font-weight: normal;
        font-size: 12px;
      }
    }
  }
}
