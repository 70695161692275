@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.slug-input-field {
  width: 100%;
  position: relative;

  &__input-section {
    height: 50px;
    border: 0.75px solid rgba(151, 151, 151, 0.8);
    border-radius: 4px;
    display: flex;
    align-items: center;
    // padding: 0 1em 2px 0;
    background: #fff;
    position: relative;

    &.disabled {
      background: rgba(0, 0, 0, 0.05);
      color: -internal-light-dark(rgb(84, 84, 84), rgb(170, 170, 170));
      border-color: rgba(151, 151, 151, 0.8);
    }

    &:hover {
      border-color: $purple-color;
    }

    &:focus-within {
      border-color: $purple-color;
    }

    &.error-border {
      border-color: $danger-color;
    }

    span {
      padding: 1em;
      background-color: $purple-color;
      color: $white-color;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    p {
      padding: 1em;
      background-color: #f3f1fc;
      color: $purple-color;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;

      @include respond-to("tablet") {
        padding: 1.3em;
      }
    }
    input {
      padding-left: 0.8em;
      height: 100%;
      flex: 1;
      outline: none;
      border-radius: 4px;
      border: 1px;
      background: #fff;
      color: #000;

      &:disabled {
        cursor: not-allowed;
        background: rgba(0, 0, 0, 0.05);
        color: -internal-light-dark(rgb(84, 84, 84), rgb(170, 170, 170));
        border-color: rgba(151, 151, 151, 0.8);
      }
    }

    .dropdownMenu {
      position: absolute;
      top: 98%;
      right: 0;
      background-color: white;
      border: 1px solid #ccc;
      z-index: 1000;
      width: 14em;
      list-style: none;
      margin: 0;
      padding: 0;
      border-radius: 4px;

      .dropdownItem {
        padding: 1em;
        cursor: pointer;

        &:hover {
          background-color: #f0f0f0;
        }
      }
    }
  }
  &__domain-select {
    width: 16em;
    padding-left: 0.5em;
    background: #f3f1fc;
    display: flex;
    justify-content: center;
    gap: 5px;
    color: $white-color;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    max-height: 4em;

    @include respond-to("mobile") {
      max-width: 900px;
    }
  }

  .error-message {
    font-size: 13px;
    visibility: hidden;
    display: flex;
    margin-top: 2px;
    min-height: 15px;

    &.show {
      visibility: visible;
    }
  }
}
