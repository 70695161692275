@import "../../../assets/styles/base/mixins";

.menuContainer {
  display: flex;
  gap: 20px;
  background: #f8f8f8;
  // height: 100vh;
  // margin-top: -3px;

  @include respond-to("big-tablet") {
    flex-direction: column;
    height: 100%;
    gap: 14px;
  }

  .scrollSwipeContainer {
    z-index: 1;
    background: #f8f8f8;
    margin-left: -4px;
    display: none;
    position: sticky;
    top: 122px;

    @include respond-to("big-tablet") {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      border-bottom: 1px solid #ededed;
      top: 80px;
      // margin-bottom: 20px;
    }

    .swipeContainer {
      display: flex;
      align-self: center;
      background: #ececec;
      padding: 0px 10px;
      border: 0.3px solid #c5c5c5;
      border-radius: 20px;
      margin-top: 8px;
      margin-bottom: 10px;
      margin-left: -20px;

      p {
        color: #696677;
      }
    }

    .menuItemScrollContainer {
      // width: 110%;
      overflow-x: scroll;
      scroll-behavior: smooth;
      display: none;
      column-gap: 24px;
      padding-right: 50px;
      scrollbar-width: none;
      padding-left: 20px;

      @include respond-to("big-tablet") {
        display: flex;
        align-items: center;
      }

      .menuItemScroll {
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        cursor: pointer;
      }

      .active {
        border-bottom: 1px solid #7647ee;
        color: #7647ee;
      }

      .inactive {
        border-bottom: transparent;
        color: #696677;
      }
    }
  }

  .sidebar {
    display: flex;
    flex-direction: column;
    max-width: 220px;
    background: white;
    border: 1px solid #9797974d;
    border-radius: 12px;
    position: sticky;
    top: 100px;
    height: calc(100vh - 140px);
    padding: 16px 8px;
    gap: 16px;
    overflow: hidden;

    @include respond-to("big-tablet") {
      display: none;
    }

    .businessContainer {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0 8px;

      .logo {
        height: 64px;
        width: 64px;
        border-radius: 9999px;
        border: 1px solid #696677;
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: center;
        margin-bottom: 8px;
      }

      .businessName {
        .title {
          font-size: 20px;
          font-weight: 700;
          color: #15112d;
          margin-bottom: 6px;
        }
        .location {
          display: flex;
          align-items: flex-start;
          gap: 4px;
          margin-bottom: 4px;

          .locIcon {
            margin-top: 1px;
          }

          .place {
            color: #696677;
            font-size: 14px;
            font-weight: 400;
          }
        }

        .mealType {
          margin-top: 8px;
          display: flex;

          .mealText {
            background: #fef7eb;
            padding: 2px 8px;
            border-radius: 8px;
            color: #f7ad3c;
            font-size: 12px;
            font-weight: 400;
          }
        }
      }
    }

    .socialContainer {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 14px;
      padding: 0 8px;

      .iconContainer {
        cursor: pointer;
        height: 20px;
        width: 24px;
        box-shadow: 0px 0px 2px 0px #00000024;
        border: 0.5px solid #9797974d;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .filtersContainer {
      margin-top: 4px;
      padding: 0 8px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      overflow-y: scroll;
      scroll-behavior: smooth;
      scrollbar-width: none;
      height: 100%;

      .filter {
        display: flex;
        align-items: center;
        margin-bottom: 6px;

        .filterText {
          margin-left: 10px;
          font-size: 16px;
          color: #15112d;
          font-weight: 700;
        }
      }

      .filterItem {
        display: flex;

        .filterText {
          padding: 8px 24px 8px 12px;
          border-radius: 24px;
          color: white;
          font-size: 16px;
          font-weight: 400;
          cursor: pointer;
          text-transform: capitalize;
        }
      }
    }
  }

  .content {
    flex: 1;
    background: white;
    border-radius: 12px;
    height: 100%;
    width: calc(100vw - 300px);
    padding: 16px;

    @include respond-to("big-tablet") {
      margin-left: 0px;
      width: 100%;
      padding: 8px;
      // margin-top: 20px;
    }
  }

  .allowPadding {
    padding: 16px;

    @include respond-to("big-tablet") {
      padding: 8px;
    }
  }

  .contentContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 100px;

    @include respond-to("big-tablet") {
      padding-bottom: 0px;
    }

    .appertizerContainer {
      overflow: hidden;
      .appertizerHeading {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h4 {
          @include respond-to("big-tablet") {
            font-size: 20px;
          }

          @include respond-to("mobile") {
            font-size: 16px;
          }
        }

        .control {
          display: flex;
          align-items: center;
          gap: 12px;

          @include respond-to("big-tablet") {
            display: none;
          }

          div {
            border: 1.17px solid #7647ee;
            width: 28px;
            height: 28px;
            border-radius: 9.33px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
        }
      }
      .appertizerCardContainer {
        margin-top: 16px;
        width: 100%;
        overflow-x: scroll;
        scroll-behavior: smooth;
        display: flex;
        gap: 24px;
        scrollbar-width: none;
        padding: 5px 0;
        padding-left: 5px;

        @include respond-to("big-tablet") {
          gap: 8px;
          width: 100%;
          margin-top: 8px;
        }
      }
    }

    .mainsContainer {
      @include respond-to("big-tablet") {
        padding: 8px;
      }

      h4 {
        @include respond-to("big-tablet") {
          font-size: 20px;
        }

        @include respond-to("mobile") {
          font-size: 16px;
        }
      }

      .mainsCard {
        border-radius: 12px;
        padding: 16px;
        border: 1px solid #9797974d;
        margin-top: 10px;
        &__items {
          gap: 20px;
          grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
          place-items: center;
          display: grid;
        }
      }
    }

    .sidesContainer {
      border-radius: 12px;
      padding: 16px;
      border: 1px solid #9797974d;

      @include respond-to("big-tablet") {
        padding: 8px;
      }

      h4 {
        @include respond-to("big-tablet") {
          font-size: 20px;
        }

        @include respond-to("mobile") {
          font-size: 16px;
        }
      }

      .sidesCard {
        margin-top: 16px;
        display: grid;
        place-items: center;
        gap: 20px;
        grid-template-columns: repeat(3, minmax(0, 1fr));

        @include respond-to("big-tablet") {
          margin-top: 8px;
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        @include respond-to("tablet") {
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        @include respond-to("mobile") {
          gap: 8px;
          grid-template-columns: repeat(1, minmax(0, 1fr));
        }
      }
    }
  }

  .cartSection {
    position: fixed;
    height: 100vh;
    width: 400px;
    top: 0px;
    right: 20px;
    z-index: 2;
    transition: 0.1s ease;
    @include respond-to("large") {
      width: 100%;
      top: auto;
      bottom: 0;
      position: fixed;
      left: 0;
      height: 0px;
      overflow: hidden;
    }
    &.active {
      @include respond-to("large") {
        height: calc(100% - 157px);
      }
    }
  }
}

// .menu {
//   display: flex;
//   gap: 25px;
//   flex-direction: column;

//   &__category {
//     display: flex;
//     flex-direction: column;
//     gap: 20px;
//     padding: 15px 10px;
//     background: $white-color;
//     border: $light-border;
//     border-radius: 12px;
//   }
//   &__list {
//     display: flex;
//     gap: 15px;
//     flex-wrap: wrap;
//   }
// }
