@import "../../../assets/styles/base/variables";
.search__field {
  display: flex;
  align-items: center;
  height: 50px;
  border-radius: 4px;
  background: $white-color;
  padding: 0 5px 0 10px;
  border: 1px solid $purple-color;
  // max-width: 803px;

  input {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background: none;
    font-size: 14px;
    color: #696677;
    padding: 0;
  }

  .search-btn {
    height: 35px;
  }
}
