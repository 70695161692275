@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.deleteStockWidget {
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.closeWidgetButton {
  background: none;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  align-self: flex-end;

  &:focus {
    outline: none;
    border: none;
  }
}

.deleteStockForm {
  flex: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 50px;
}

.backButton {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white-color;
  border-radius: 8px;
  border: 1px solid rgba(118, 71, 238, 1);

  &:focus {
    outline: none;
  }
}
