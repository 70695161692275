@import "../../../../assets/styles/base/mixins";

.passwordForm {
  max-width: 600px;
  @include respond-to("small") {
    margin: auto;
  }
  &__body {
    display: flex;
    flex-direction: column;
    gap: 7px;
    margin-top: 1em;
    background-color: #fff;
    padding: 48px 32px;
    border-radius: 12px;
    box-shadow: -4px 8px 24px 0px #2c3f5805;
    width: 100%;
    @include respond-to("small") {
      padding: 24px 4%;
    }
  }
}
