@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.backButton {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white-color;
  border-radius: 8px;
  border: 1px solid rgba(118, 71, 238, 1);

  &:focus {
    outline: none;
  }
}
