@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.allAccounting {
  height: 100%;
  display: flex;
  flex-direction: column;
  .formSection {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
  }
  .daterangeSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  &__bottom {
    padding: 25px 0px;
    position: sticky;
    gap: 12px;
    margin-top: 15px;
    height: 50px;
    bottom: 10px;
  }
}
