@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.sectionCard {
  background: $white-color;
  border-radius: 12px;
  border: 1px solid #c5c5c5;
  background: $white-color;
  overflow: hidden;
  display: flex;
  gap: 20px;
  flex-direction: column;
  padding: 0 15px 15px 15px;
  height: max-content;

  &__header {
    display: flex;
    align-items: center;
    background: $light-cancel;
    padding: 10px 15px;
    width: calc(100% + 30px);
    margin-left: -15px;
    &.received {
      background: $light-purple;
    }
    &.progress {
      background: $deep-yellow;
    }
    &.completed {
      background: $light-green;
    }
  }

  &__mobile {
    display: flex;
    flex-direction: column;
    gap: 20px;
    // @include respond-to("tablet") {
    //   display: none;
    // }
  }
}
