@import "../../../assets/styles/base/mixins";
@import "../../../assets/styles/base/variables";

.storeForm {
  padding: 20px;
  .formSection {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 13px;

    .customCheckCard {
      padding: 10px;
      border-radius: 8px;
      border: $light-border;
      margin-bottom: 10px;
      width: 49%;
      &__top {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}
.inputSection {
  display: flex;
  gap: 38px;
  align-items: center;
  justify-content: center;

  @include respond-to("tablet") {
    flex-direction: column;
    gap: 0;
  }
}

.cardWrap {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  flex-wrap: wrap;
}

.fixedBtn {
  position: sticky;
  bottom: -50px;
  padding: 16px;
  display: flex;
  flex-wrap: wrap;
  margin: -20px;
  justify-content: space-between;
  background: $white-color;
  border: 3px $white-color;
  box-shadow: 0px -4px 24px #c5c5c533;
}

.cardSession {
  background: $white-color;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 5px;
  border-radius: 8px;
}

.storefront {
  background: #c5c5c533;
  height: 100%;
  margin-top: -16px;
  margin-left: -18px;
  margin-right: -18px;
  border-radius: 8px 0 0;
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 18px;
  //flex-wrap: wrap;
}

.selectedLocation {
  border-radius: 1px;
  //border: $light-border;
  padding: 10px;
  width: 25%;
  @include respond-to("mobile") {
    width: 100%;
  }

  @include respond-to("tablet") {
    width: 100%;
  }
}

.locationBtn {
  font-size: 12px;
  height: 32px;
  padding: 0 16px;
  width: 100px;
}

.addRegion {
  padding: 1px;

  @include respond-to("big-tablet") {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  @include respond-to("tablet") {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  @include respond-to("large") {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  @include respond-to("tablet") {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  @include respond-to("mobile") {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}

.cardWithBorder {
  // border: $light-border;
  // border-radius: 4px;
  // padding: 4px;

  @include respond-to("tablet") {
    gap: 5px;
  }
}

.locationCard {
  display: flex;
  flex-wrap: wrap;
  width: 80%;

  @include respond-to("big-tablet") {
    width: 100%;
  }

  @include respond-to("tablet") {
    flex-direction: column;
  }
}
