.productsExport {
  height: 100%;
  display: flex;
  flex-direction: column;
  .formSection {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 30px;
  }
}
