.storeCustomerForm {
  height: 100%;
  display: flex;
  flex-direction: column;
  &__bottom {
    position: sticky;
    bottom: 0px;
    margin-top: auto;
    padding-top: 10px;
    background: #fff;
    display: flex;
    gap: 20px;
  }
}

.back_icon_container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  border-radius: 8px;
  border: 1px solid #7647ee;
  padding: 12px 0px;
  cursor: pointer;
}

.free_delivery {
  background: #f3f1fc;
  padding: 10px;
  font-size: 11px;
  border-radius: 5px;
}

.checkout_heading_container {
  margin-top: 16px;
  margin-bottom: 24px;
}

.order_summary {
  .cart_length {
    color: #696677;
  }

  .divider {
    margin: 10px 0;
    border: 0.75px solid #97979733;
  }

  .cart_items {
    .cart_item {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .cart_item_content {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .name {
          font-size: 16px;
          line-height: 28px;
          color: #15112d;
          font-weight: 400;
          line-clamp: 1;
        }

        .qty {
          font-size: 12px;
          line-height: 15.6px;
          color: #15112d;
        }

        .price {
          font-size: 16px;
          line-height: 28px;
          color: #15112d;
          font-weight: 700;

          .cancelled_price {
            font-size: 14px;
            color: #e02020;
            text-decoration: line-through;
          }
          .off_price {
            font-size: 14px;
            font-weight: 400;
            color: #e02020;
            font-style: italic;
          }
        }
      }

      .cart_item_img {
        width: 54px;
        height: 54px;
        flex-shrink: 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          border-radius: 4px;
        }
      }
    }

    .divider {
      margin: 10px 0;
      border: 0.75px solid #97979733;
    }
  }

  .subtotal_container {
    margin-top: 36px;
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .divider {
      margin: 4px 0;
      border: 0.75px solid #97979733;
    }

    .subtotal {
      display: flex;
      align-items: center;
      justify-content: space-between;
      // margin-top: -10px;

      .amount {
        color: #15112d;
      }

      .total,
      .total_amount {
        font-weight: 700;
        color: #15112d;
        font-size: 20px;
        line-height: 28px;
      }
      .total_amount {
        color: #7647ee;
      }
    }
  }
}
