@import "../../../assets/styles/base/variables";

.user-roles__modal {
  padding: 16px 32px;

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &-close {
    width: 32px;
    height: 32px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #e4dafc;
    cursor: pointer;
  }

  &__roles-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 34px;

    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__icon {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: $purple-color;
        display: grid;
        place-content: center;
      }
    }
  }
}
