@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.expenseAnalytics {
  display: flex;
  gap: 30px;
  @include respond-to("tablet") {
    flex-wrap: wrap;
  }

  .chartSection {
    position: relative;
    width: 100%;
    max-width: 500px;
    @include respond-to("tablet") {
      order: 1;
    }
  }

  &__chart {
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  &__total {
    position: absolute;
    top: 42%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
  }

  &__legend {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    width: 100%;
    max-width: 700px;
    padding-top: 40px;
    height: fit-content;
    @include respond-to("tablet") {
      order: 2;
      padding-top: 0;
    }
    &Item {
      display: flex;
      align-items: center;
      height: 40px;

      .indicator {
        width: 4px;
        height: 28px;
        display: block;
        background-color: #696677;
        border-radius: 5px;
      }
      .title {
        font-size: 14px;
        color: #696677;
        margin-right: 35px;
      }
    }
  }
}
