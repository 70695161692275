.subscribtionNotification {
  text-align: center;
  padding: 10px 30px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  .content {
    max-width: 520px;
    text-align: left;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .account {
    max-width: 450px;
    text-align: left;
    margin: auto;
  }
}
