@import "../../../../../../../assets//styles/base/variables";
@import "../../../../../../../assets//styles/base/mixins";

.bottom {
  padding: 15px 0px;
  background: #ffffff;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  margin-top: 15px;
  bottom: -25px;
  @include respond-to("mobile") {
    flex-direction: column;
  }
}

.heading {
  text-align: center;
  @include respond-to("mobile") {
    text-align: left;
    font-size: 18px;
  }
}

.location {
  display: flex;
  width: 100%;
  gap: 10px;

  @include respond-to("mobile") {
    flex-direction: column;
  }
}

.locationInfo {
  font-style: italic;
}
