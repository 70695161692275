@import "./mixins";
@import "./variables";

.rowNavigations {
  border-bottom: 1px solid #e8e9eb;
  // margin: 25px 0 2em 0;
  display: flex;
  overflow-y: auto;

  &__item {
    display: flex;
    align-items: center;
    transition: all 0.2s ease-in-out;
    font-size: 16px;
    text-decoration: none !important;
    padding: 0.5em 0;
    border-bottom: 2px solid transparent;
    min-width: fit-content;
    margin-right: 2rem;
    cursor: pointer;
    @include respond-to("tablet") {
      margin-right: 15px;
    }

    &.active {
      color: $purple-color !important;
      border-color: $purple-color;
    }
  }
}

.tableWrapper {
  min-height: 500px;
  background: $white-color;
  border-radius: 16px;
  padding-bottom: 24px;
  &.addBorder {
    border: 2px solid $purple-color;
  }
}

.tableHeader {
  padding: 24px 24px 24px 24px;
  border-bottom: 2px solid #e7f1fa;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  @include respond-to("large") {
    padding: 15px 15px 5px 15px;
  }
  .searchField {
    max-width: 600px;
    min-width: 350px;
    width: 100%;
    @include respond-to("tablet") {
      min-width: 100%;
    }
  }
}

.plan {
  color: #1e016f;
  background: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 12px;
  border-radius: 16px;
  border: 2px solid #1e016f;
  max-width: max-content;
  font-size: 12px;
  &.growthPlan {
    background: $success-color;
    color: $white-color;
    border-color: $success-color;
  }
  &.essentialPlan {
    background: $dark-yellow;
    color: $white-color;
    border-color: $dark-yellow;
  }
  &.scalePlan {
    background: $purple-color;
    color: $white-color;
    border-color: $purple-color;
  }
}

.cardWithBackground {
  background-color: #f3f1fc;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-radius: 8px;
}

.cardWithBorder {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-radius: 8px;
  border: $light-border;
}

.pagePaddings {
  padding-top: 3.5em;
  padding-bottom: 30px;
  @include respond-to("big-tablet") {
    padding-top: 1.5em;
  }
}

.borderBottom {
  border-bottom: $light-border;
}

.borderTop {
  border-top: $light-border;
  &Purple {
    border-color: $purple-color;
  }
}

.rowSpaceBetween {
  display: flex;
  justify-content: space-between;
}

.pageColumnGaps {
  gap: 25px;
  display: flex;
  flex-direction: column;
}

.pageHeaders {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  flex-wrap: wrap;
}

.prodouctTypeTag {
  background: #efefef;
  padding: 2px 8px;
  border-radius: 8px;
  width: fit-content;
  font-size: 12px;
  color: $light-gray-color;
}

.errorMessage {
  font-size: 13px;
  visibility: hidden;
  display: flex;
  margin-top: 2px;
  min-height: 15px;

  &.show {
    visibility: visible;
  }
}

.statusBlock {
  display: block;
  border-radius: 8px;
  padding: 5px 16px;
  width: 100px;
  max-width: 100px;
  font-size: 12px;
  text-align: center;
}
