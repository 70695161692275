@import "../../../../assets/styles/base/mixins";
@import "../../../../assets/styles/base/variables";

.subscriptionPeriod {
  border: 1px solid $purple-color;
  background: $white-color;
  border-radius: 30px;
  width: 100%;
  max-width: 350px;
  margin: auto;
  display: flex;
  padding: 4px;
  height: 45px;
  align-items: center;

  &__item {
    width: 40%;
    height: 100%;
    justify-content: center;
    cursor: pointer;
    border-radius: 30px;
    display: flex;
    align-items: center;
    &:last-child {
      width: 60%;
    }
    h5 {
      margin-bottom: 0;
    }
    &.active {
      background: $purple-color;
      h5 {
        color: $white-color;
        span {
          color: $white-color !important;
        }
      }
    }
  }
}
