@import "../../../../assets/styles/base/mixins";
@import "../../../../assets/styles/base/variables";

.checkItem {
  display: flex;
  gap: 5px;
  align-items: flex-start;
  .checked {
    min-width: 14px;
    min-height: 14px;
    max-width: 14px;
    max-height: 14px;
    background: $purple-color;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 10px;
    border-radius: 2px;
  }
}
