@import "../../../assets/styles/base/variables";
.bulkRestockComboBox {
  position: relative;

  .resultSection {
    padding: 7px 12px;
    border-radius: 8px;
    min-height: 200px;
    max-height: 350px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 5px;
    background: $white-color;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 4px;
    width: 100%;
    position: absolute;
    border: 1px solid $purple-color;
    z-index: 2;
  }

  .nameContainer {
    width: calc(100% + 24px);
    margin-left: -12px;

    .listItemParent {
      background: $white-color;
      padding: 7px 25px;
      cursor: pointer;
      border-bottom: $light-border;
      display: flex;
      justify-content: space-between;
      gap: 20px;
      align-items: center;
      p {
        color: $purple-color;
      }
      &:hover {
        background-color: $purple-color;
        p {
          color: $white-color !important;
        }
      }

      &.isVariant {
        &:hover {
          background-color: $white-color;
          p {
            color: $purple-color !important;
          }
        }
      }
      &.active {
        border: none;
      }

      &.isSubVariant {
        padding: 7px 50px;
        border: none;
        background: #f2edfe;
        &:last-child {
          border-bottom: $light-border;
        }
        &:hover {
          background-color: $purple-color;
          p {
            color: $white-color !important;
          }
        }
      }
    }
  }
}
