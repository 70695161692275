@import "../../../../../assets/styles/base/mixins";

.cardContainer {
  background: #ffffff;
  border-radius: 12px;
  padding: 8px;
  width: 100%;
  cursor: pointer;

  .mainContainer {
    display: flex;
    gap: 12px;
    position: relative;

    .soldOut {
      background: #ed0000;
      padding: 4px 8px;
      border-radius: 16px;
      color: #ffffff;
      font-size: 10px;
      line-height: 10px;
      font-weight: 700;
      position: absolute;
      bottom: 4px;
      right: 4px;
    }

    .imageContainer {
      position: relative;
      width: 50%;
      height: 136px;
      border-radius: 4px;
      border: 0.75px solid #c5c5c5;

      .addIcon {
        position: absolute;
        bottom: 2px;
        right: 4px;
      }

      .productImg {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .descriptionContainer {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .description {
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: #15112d;
      margin-top: 8px;
    }

    .priceContainer {
      display: flex;
      flex-direction: column;

      .price {
        display: flex;
        align-items: center;

        .dot {
          height: 4px;
          width: 4px;
          border-radius: 999px;
          margin-right: 4px;
          background: #fd561f;
        }

        .priceText {
          font-size: 12px;
          font-weight: 700;
          color: #7647ee;
        }
      }

      .offContainer {
        display: flex;
        align-items: center;

        .offPrice {
          font-size: 10px;
          font-weight: 700;
          color: #e02020;
          margin-right: 4px;
          text-decoration: line-through;
        }

        .offPerc {
          font-size: 10px;
          font-style: italic;
          font-weight: 400;
          color: #e02020;
        }
      }
    }
  }
}
