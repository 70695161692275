@import "../../../../assets/styles/base/variables";
@import "../../../../assets/styles/base/mixins";

.new-terminal {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;

    @include respond-to("big-tablet") {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 16px;
    }
  }

  &__content {
    display: flex;
    gap: 32px;
    width: 100%;
    margin-top: 24px;

    &-img {
      width: calc(45% - 32px);
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $white-color;
      box-shadow: 0px 0px 4px 0px #00000024;
      border-radius: 12px;

      @include respond-to("large") {
        display: none;
      }

      img {
        width: 100%;
      }
    }
  }
}
