@import "../../../assets/styles/base/variables";

.image-upload-input {
  &__btn {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1.5px dashed rgba(151, 151, 151, 0.8);
    cursor: pointer;
    border-radius: 4px;

    &:hover {
      border-color: $purple-color;
    }

    .upload-icon {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: $purple-color;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 40px;
      padding-bottom: 4px;
    }
  }

  .image-show-room {
    width: 50px;
    height: 50px;
    border: 1px solid #ddd;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .error-message {
    font-size: 13px;
    visibility: hidden;
    display: flex;
    margin-top: 2px;
    min-height: 15px;

    &.show {
      visibility: visible;
    }
  }
}
