@import "../../../assets/styles/base/mixins";
@import "../../../assets/styles/base/variables";

.credit-log-table {
  background-color: $white-color;

  table {
    th,
    td {
      &:nth-child(1) {
        min-width: 210px !important;
      }
      &:last-child {
        min-width: 300px !important;
      }
    }
  }
}
