@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.orderComboBox {
  position: relative;
  display: flex;
  flex-direction: column;
}

.orderComboBoxLabel {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 4px;
}

.orderComboBoxControl {
  position: relative;
}

.orderComboBoxInput {
  width: 100%;
  height: 50px;
  border: 1px solid #979797;
  border-radius: 4px;
  padding: 0 30px;

  &:hover {
    border-color: $purple-color;
  }

  &:focus {
    border-color: $purple-color;
    outline: none;
  }
}

.orderComboBoxMenu {
  position: absolute;
  top: 100%;
  bottom: 0;
  right: 0;
  z-index: 10;
  width: 100%;
  height: fit-content;
  max-height: 180px;
  max-width: 100%;
  background-color: $white-color;
  overflow-y: scroll;
  margin: 4px 0px 0px 0px;
  padding: 0;
  border: 1px solid #979797;
  display: flex;
  flex-direction: column;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow:
    0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.orderComboBoxMenuItem {
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 30px;

  &:hover {
    background-color: $purple-color;
    color: $white-color;
  }
}
