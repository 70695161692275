@import "../../../../../assets/styles/base/variables";
@import "../../../../../assets/styles/base/mixins";

.walletSection {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  column-gap: 30px;
  background-color: $white-color;
  row-gap: 30px;
  border-radius: 12px;
  padding: 24px;
}

.orderContainer {
  background: #ffffff;
  padding: 24px;
  border-radius: 12px;
}

.horizontalScrollContainer {
  width: 100%;
  overflow-x: auto;
}

.horizontalScroll {
  min-width: 700px;
}

.orderDetails {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  width: 100%;
  margin-bottom: 24px;

  @include respond-to("large") {
    gap: 8px;
    margin-bottom: 20px;
  }
}

.priceSumaryContainer {
  margin-top: 52px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include respond-to("large") {
    margin-top: 32px;
    gap: 8px;
  }
}
