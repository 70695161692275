@import "../../../../assets/styles/base/variables";
@import "../../../../assets/styles/base/mixins";

.editSupplier {
  padding-top: 3.5em;
  display: flex;
  flex-direction: column;
  gap: 30px;

  @include respond-to("mobile") {
    padding: 20px;
  }
  &__details {
    border-radius: 8px;
    background: $white-color;
    padding: 24px;
    max-width: 700px;
    min-height: 400px;
  }
}
