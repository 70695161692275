@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.foodMenuWidget {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  gap: 30px;
  .printSection {
    max-width: 100% !important;
    padding: auto !important;
  }

  .qR__content {
    .qrCode,
    .qrCodeInfo {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $white-color;
      border: $light-border;
      border-radius: 12px;
      padding: 12px;
      width: 100%;
    }
    .qrCode {
      min-height: 230px;
    }
    .qrCodeInfo {
      background-color: $white-color;
      gap: 16px;
      flex-direction: column;
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    gap: 30px;
    margin-top: auto;
  }
}

.circle {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: $dark-yellow;
  margin: 3px;
}
.verticalLine {
  width: 1px;
  background-color: $dark-color;
  margin-bottom: -8px;
}
.horizontalLine {
  height: 0px;
  background-color: $dark-color;
}
.qrCodeContainer {
  border-radius: 16px;
  padding: 18px;
  display: "flex";
  flex-direction: column;
  gap: 25px;
  box-shadow: 0px 4px 4px 4px rgba(0, 0, 0, 0.2);
}
.noSizeSelected {
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 450px;
  padding: 10px;
  border: 1px solid $gray-color;
  border-radius: 8px;
  height: 45em;
  justify-content: center;
  align-items: center;
  background-color: $white-color;
  @include respond-to("mobile") {
    height: 100%;
  }
}
.qrCodeSection {
  border: 1px solid #979797;
}
.qrSection {
  display: flex;
  justify-content: space-between;

  @include respond-to("mobile") {
    flex-wrap: wrap;
    min-height: 100%;
  }
}
