@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.expenses-table {
  margin-top: 32px;
  @include respond-to("tablet") {
    margin-top: 15px;
  }

  &__content {
    background-color: $white-color;
    padding: 24px 0;
    border-radius: 16px;
    width: 100%;
  }
}
