@import "../../../../../assets/styles/base/mixins";
@import "../../../../../assets/styles/base/variables";

.sub-accounts {
  &__users {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    gap: 30px;
    @include respond-to("tablet") {
      grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    }
  }

  &__user {
    padding: 32px 24px;
    border-radius: 8px;
    background: $white-color;
    box-shadow: 0px 0px 2px 1px #e5e5e5;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }

  &__user-branch {
    border-radius: 32px;
    font-size: 20px;
  }

  &__edit-user-account {
    color: $purple-color;
    cursor: pointer;
  }

  &__user-info-wrapper {
    display: grid;
    gap: 4px;
  }

  &__user-info {
    display: flex;
    justify-content: space-between;
  }

  &__acct-no-wrapper {
    display: flex;
    gap: 5px;
  }
}
