@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.average-spend-chart {
  background-color: $white-color;
  padding: 24px;
  border: 1px solid #f4f1fc;
  border-radius: 8px;

  &__header {
    display: flex;
    // align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }
  @include respond-to("tablet") {
    h4 {
      font-size: 16px !important;
    }
  }
}
