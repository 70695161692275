@import "../../../assets/styles/base/mixins";
@import "../../../assets/styles/base/variables";

.subscriptionPlanHeader {
  background: $white-color;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.14);
  @include respond-to("large") {
    justify-content: center;
    background: $secondary-bg;
  }

  .card {
    border-right: 1px solid #e8e9eb;
    padding: 24px;
    width: 19.5%;
    display: flex;
    gap: 10px;
    flex-direction: column;
    @include respond-to("large") {
      display: none;
    }

    &:last-child {
      border: none;
    }
    &.select {
      width: 22%;
      @include respond-to("large") {
        display: flex;
        width: 100%;
        h5 {
          margin-top: 0 !important;
          text-align: center;
        }
      }
    }

    .btn {
      width: 123px;
      text-transform: capitalize;
    }
    &.active {
      text-align: center;
      gap: 15px;
      .btn {
        margin: 0 auto;
        border-radius: 30px;
        width: 170px;
      }
    }
  }
}
