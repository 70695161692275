@import "../../../../../assets/styles/base/variables";
@import "../../../../../assets/styles/base/mixins";

.subscriptionPlans {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__desktop,
  &__mobile {
    flex-direction: column;
    gap: 8px;
  }
  &__desktop {
    display: flex;
    @include respond-to("large") {
      display: none;
    }
  }
  &__mobile {
    display: none;
    @include respond-to("large") {
      display: flex;
    }
  }
}
