@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.bottom {
  position: sticky;
  bottom: 0px;
  background: $white-color;
  padding: 10px 16px 10px 16px;
  width: calc(100% + 32px);
  margin-left: -16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  &.active {
    @include respond-to("small") {
      position: relative;
    }
  }
}
