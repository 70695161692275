@import "../../../../assets/styles/base/variables";
@import "../../../../assets/styles/base/mixins";

.printOnDevice {
  display: flex;
  flex-direction: column;
  gap: 30px;

  &__items {
    gap: 12px;
    display: flex;
    flex-direction: column;

    &__card {
      border: 2px solid $purple-color;
      border-radius: 8px;
      padding: 12px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      &.isPrinted {
        // opacity: 0.8;
        border-color: #ddd;
      }

      .cardTop {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 30px;
        padding-bottom: 10px;
        border-bottom: $light-border;
      }
    }
  }
}
