@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";
.expenseReview {
  &__form {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 30px;
  }
  &__bottom {
    padding: 15px 0px;
    background: #ffffff;
    position: sticky;
    width: 100%;
    display: flex;
    gap: 12px;
    margin-top: 15px;
    bottom: -10px;
  }
}
