@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.bulkRestockTable {
  table {
    th,
    td {
      min-width: 200px;
      max-width: 300px;
      padding: 12px !important;
      &:nth-child(2) {
        min-width: 100px;
        max-width: 200px;
      }
      &:first-child {
        min-width: 250px;
        max-width: 350px;
      }
    }
  }
}

.unit {
  margin-left: 19px;
  margin-top: 18px;
}
