@import "../../../../../assets/styles/base/variables";
@import "../../../../../assets/styles/base/mixins";

.allFoodMenu {
  padding-bottom: 50px;

  .menuItemScrollContainer {
    width: 100%;
    overflow-x: scroll;
    scroll-behavior: smooth;
    display: flex;
    align-items: center;
    column-gap: 48px;
    padding-right: 10px;
    scrollbar-width: none;
    margin-bottom: 32px;
    border-bottom: 2px solid #ededed;

    @include respond-to("big-tablet") {
      column-gap: 24px;
    }

    .menuItemScroll {
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      cursor: pointer;

      @include respond-to("big-tablet") {
        font-size: 14px;
      }
    }

    .active {
      border-bottom: 4px solid #7647ee;
      color: #7647ee;
    }

    .inactive {
      border-bottom: transparent;
      color: #696677;
    }
  }

  &__content {
    border-radius: 16px;
    padding-bottom: 24px;
    background: $white-color;
    min-height: 500px;

    .searchSection {
      padding: 24px;
      max-width: 546px;
    }
  }
}

.pageHeaders {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include respond-to("mobile") {
    align-items: flex-start;
    flex-direction: column;
  }
}

.mainBtnContainer {
  display: flex;
  align-items: center;
  gap: 15px;

  @include respond-to("mobile") {
    width: 100%;
    flex-direction: column-reverse;
  }

  button {
    @include respond-to("mobile") {
      width: 100%;
    }
  }
}

.linksContainer {
  .closeIcon {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .headingContainer {
    width: 400px;
    margin-bottom: 12px;

    @include respond-to("big-tablet") {
      width: 90%;
    }
  }

  .contentContainer {
    margin-top: 32px;
    background: #f1f1f1;
    padding: 8px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    text-align: center;

    @include respond-to("mobile") {
      margin: auto;
      width: 80%;
    }

    span {
      @include respond-to("mobile") {
        font-size: 14px;
        width: 80%;
      }
    }

    .templateContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      @include respond-to("big-tablet") {
        gap: 20px;
      }

      @include respond-to("mobile") {
        flex-direction: column;
      }

      .template {
        margin: auto;
        width: 250px;

        @include respond-to("big-tablet") {
          width: 100%;
        }

        .imgContainer {
          position: relative;
          height: 200px;
          width: 100%;
          border-radius: 8px;
          border: 1px solid #fdefd8;
          background: #fff;
          box-shadow: 0px 0px 8px -2px #00000029;

          @include respond-to("mobile") {
            height: 250px;
          }

          img {
            border-radius: 8px;
            height: 95%;
            width: 100%;
          }

          .badgeContainer {
            position: absolute;
            left: 0;
            bottom: 0;
            height: 60px;
            width: 100%;
            background: #fff;
            display: flex;
            align-items: flex-end;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;

            .badge {
              background: #f3f1fc;
              border: 0.75 solid #e4dafc;
              border-radius: 40px;
              display: flex;
              font-size: 14px;
              font-weight: 500;
              padding: 0 8px;
              margin-bottom: 8px;
              margin-left: 8px;
            }
          }
        }

        .btnContainer {
          padding: 8px 0;
          display: flex;
          align-items: center;
          gap: 8px;

          button {
            width: 100%;

            span {
              @include respond-to("mobile") {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}
