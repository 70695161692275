@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.sideModal {
  width: 100%;
  height: 100%;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  margin: auto;
  bottom: 0;
  display: flex;
  align-items: center;
  z-index: 3002;

  &.positionRight {
    justify-content: right;
  }
  &.positionLeft {
    justify-content: left;
  }

  @include respond-to("tablet") {
    align-items: flex-end;
  }

  &__container {
    background-color: $white-color;
    height: 100%;
    overflow: auto;
    width: 500px;
    padding: 10px 40px;
    z-index: 2;
    position: relative;
    @include respond-to("tablet") {
      padding: 10px 5%;
    }
  }

  &__backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1;
    overflow: hidden;
  }

  &.sellMode {
    .sideModal__backdrop {
      display: none;
    }
    .sideModal__container {
      border-radius: 12px 0px 0px 12px;
      border-top: 1px solid rgba(118, 71, 238, 1);
      border-left: 1px solid rgba(118, 71, 238, 1);
      border-bottom: 1px solid rgba(118, 71, 238, 1);
    }
  }
}
