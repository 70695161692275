@import "../../../assets/styles/base/variables";

.unverified-account-widget {
  width: 100%;
  background-color: $white-color;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 48px 0px 48px 0px;

  p {
    max-width: 404px;
  }
}
