@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.stepper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  // width: 740px;

  &::before {
    content: "";
    display: block;
    position: absolute;
    width: 65%;
    height: 2px;
    top: 23%;
    left: 50%;
    transform: translateX(-50%);
    background-color: $purple-color;

    @include respond-to("mobile") {
      width: 90%;
      top: 40%;
    }
  }

  &__step {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
    cursor: pointer;
    z-index: 1;

    @include respond-to("mobile") {
      width: 15%;
    }

    &-number {
      width: 38px;
      height: 38px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f2edfe;
      color: $purple-color;
      border-radius: 50%;
      padding-top: 1px;
      transition: 0.2s ease;
    }

    &-title {
      margin-top: 13px;
      font-size: 20px;

      @include respond-to("mobile") {
        display: none;
      }
    }
  }
}
