@import "../../../assets/styles/base/variables";

.custom-radio {
  &__input {
    display: none;
  }

  &__tag {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: 2px solid #bbc0c9;
    padding: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    padding-top: 2px;
    cursor: pointer;

    &::after {
      transition: all 0.2s ease-in-out;
      content: "";
      width: 95%;
      height: 95%;
      border-radius: 50%;
      background: $white-color;
    }
  }

  &__input:checked + &__tag {
    background: $white-color;
    border-color: $purple-color;
  }

  &__input:checked + &__tag::after {
    background: $purple-color;
  }
}
