@import "../../../../assets/styles/base/variables";
@import "../../../../assets/styles/base/mixins";

.marketDetails {
  &__card {
    background-color: $white-color;
    border-radius: 12px;
    padding: 24px;
    margin-top: 20px;
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    @include respond-to("mobile") {
      width: 100%;
    }
    @include respond-to("big-tablet") {
      width: 100%;
    }
  }
  &__sideCard {
    background-color: $white-color;
    border-radius: 12px;
    padding: 24px;
    margin-top: 20px;
    width: 27%;
    height: 40%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    @include respond-to("mobile") {
      width: 100%;
    }
    @include respond-to("big-tablet") {
      width: 100%;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 5px;
  }
}
