@import "../../../../assets/styles/base/mixins";
@import "../../../../assets/styles/base/variables";

.card {
  border-color: $purple-color;
  gap: 15px;
}
.cardItem {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  border-bottom: $light-border;
  padding-bottom: 5px;
}
