@import "../../../../assets/styles/base/variables";
@import "../../../../assets/styles/base/mixins";

.customers {
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
  }
}
