@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.cartContainer {
  background-color: $white-color;
  border-radius: 8px;
  padding: 12px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 4px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  position: relative;
}
.product {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  .product-image {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    overflow: hidden;
    // background: #fdefd8;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .product-details {
    flex: auto;
  }

  .product-name {
    max-width: calc(100% - 70px);
  }

  &-order {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 45px;
  }

  &-actions {
    display: flex;
    gap: 8px;
    align-items: center;
    margin-left: auto;

    .increment,
    .decrement,
    .count,
    .delete {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 4px;
      border: none;
      outline: none;
      font-size: 12px;
    }

    .increment {
      background-color: $purple-color;
      color: $white-color;
    }

    .decrement {
      background-color: $white-color;
      border: 1px solid #7647ee;
      color: $purple-color;
    }

    .count {
      background-color: #f2edfe;
    }

    .delete {
      color: $danger-color;
      border: 1px solid $danger-color;
    }
  }
}

.tagBtn {
  color: $purple-color;
  cursor: pointer;
  display: flex;
  gap: 5px;
  width: max-content;
  align-items: center;
}

.tagSection {
  margin-top: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  padding: 25px 20px 15px 20px;
}

.tagStatus {
  font-size: 12px;
  color: $white-color;
  padding: 7px 15px;
  border-top-right-radius: 8px;
  position: absolute;
  top: 0;
  right: 0;
  background: $dark-yellow;
}
