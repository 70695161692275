@import "../../../assets/styles/base/mixins";
@import "../../../assets/styles/base/variables";

.subscriptionPay {
  display: flex;
  flex-direction: column;
  gap: 30px;
  min-height: 100%;
  padding-bottom: 20px;

  .subscriptionPay__top {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 10px;
    border: $light-border;
    border-radius: 8px;
  }

  .totalSection {
    width: calc(100% + 80px);
    margin-left: -40px;
    border-bottom: $light-border;
    border-top: $light-border;
    padding: 0 40px;
    @include respond-to("tablet") {
      width: calc(100% + 10%);
      margin-left: -5%;
    }
    &__item {
      padding: 10px 0;
      border-bottom: $light-border;
      display: flex;
      justify-content: space-between;
      &:last-child {
        border: navajowhite;
      }
    }
  }

  .bankSection {
    border: $light-border;
    border-radius: 8px;
    padding: 15px 10px;
  }
}
