@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";
.input {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 40px;
  border-radius: 4px;
  border: none;
  outline: none;
  font-size: 12px;
  background-color: #f2edfe;
  border: none;
  text-align: center;
  &.isProductCard {
    @include respond-to("mobile") {
      height: 32px;
      width: 100%;
    }
  }

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
  }
}
