.gift-card-success-modal-wrapper {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 74px 62px 64px;
  h3 {
    margin: 16px 0 24px;
    font-weight: 700;
    font-size: 27px;
  }
}
