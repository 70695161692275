@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.terminals-table {
  background-color: $white-color;
  padding: 24px 0;
  border-radius: 16px;
  width: 100%;

  &__header {
    display: flex;
    align-items: center;
    padding: 0 24px 24px 24px;
    border-bottom: 1px solid #edf2f7;
    gap: 1.5em;
    @include respond-to("tablet") {
      padding: 0 10px 10px 10px;
      gap: 15px;
      flex-wrap: wrap;
    }

    .search-section {
      width: 700px;

      @include respond-to("large") {
        flex: none;
        width: 100%;
      }
    }
  }
}
