@import "../../../../assets/styles/base/mixins";
@import "../../../../assets/styles/base/variables";

.salesTransactions {
  .tabItemsContainer {
    width: 100%;
    overflow-x: scroll;
    scroll-behavior: smooth;
    display: flex;
    align-items: center;
    column-gap: 48px;
    padding-right: 10px;
    scrollbar-width: none;
    margin-bottom: 32px;
    border-bottom: 2px solid #ededed;
    margin-top: 40px;

    @include respond-to("big-tablet") {
      column-gap: 24px;
    }

    .tabItem {
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      cursor: pointer;

      @include respond-to("big-tablet") {
        font-size: 14px;
      }
    }

    .active {
      border-bottom: 4px solid #7647ee;
      color: #7647ee;
    }

    .inactive {
      border-bottom: transparent;
      color: #696677;
    }
  }

  &__header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    gap: 10px;
    flex-wrap: wrap;
  }

  &__content {
    background: $white-color;
    min-height: 500px;
    &__header {
      padding: 24px 24px 24px 24px;
      border-bottom: 2px solid #e7f1fa;
      display: flex;
      justify-content: space-between;
      gap: 10px;
      flex-wrap: wrap;
      @include respond-to("large") {
        padding: 15px 15px 5px 15px;
      }
      .searchField {
        max-width: 600px;
        min-width: 350px;
        width: 100%;
        @include respond-to("tablet") {
          min-width: 100%;
        }
      }
    }
  }
}
