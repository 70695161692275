@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.add-single-service {
  background-color: $white-color;

  &-form {
    margin-top: 24px;
    max-width: 1150px;

    &__container {
      padding: 48px 32px;
      border-radius: 12px;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.14);

      &-group {
        width: 100%;
        display: flex;
        gap: 112px;

        @include respond-to("big-tablet") {
          flex-direction: column-reverse;
          width: 100%;
          gap: 50px;
        }

        .service-fields-section {
          display: flex;
          flex-direction: column;
          width: 80%;
          gap: 10px;

          @include respond-to("big-tablet") {
            width: 100%;
          }

          &__pricing {
            // margin-top: 24px;
            padding: 16px;
            border: 1px solid rgba(151, 151, 151, 0.3);
            border-radius: 12px;

            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 24px;

            @include respond-to("big-tablet") {
              flex-direction: column;
              justify-content: center;
            }
          }
        }

        .service-image-section {
          align-self: flex-start;
          padding: 20px;
          width: 266px;
          background: #ffffff;
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.14);
          border-radius: 12px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 30px;

          @include respond-to("big-tablet") {
            margin-inline: auto;
          }

          &__container {
            width: 218px;
            height: 166px;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              max-width: 100%;
              height: 100%;
              display: block;
            }
          }
        }
      }
    }

    &__actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 72px;

      @include respond-to("big-tablet") {
        padding: 0 16px 18px;
      }
    }
  }
}
