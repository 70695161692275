@import "../../../assets/styles/base/variables";

.kyb-response-widget {
  border: 1px solid #f3f1fc;
  box-shadow: 0px 0px 4px 0px #00000024;
  border-radius: 20px;
  padding: 50px;
  background-color: $white-color;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 100px auto;
}
