.transaction-count {
  background-color: #fff;
  padding: 24px;
  border: 1px solid #f3f1fc;
  border-radius: 8px;

  &__actions {
    display: flex;
    align-items: center;
    gap: 25px;
    margin-top: 24px;

    &__item {
      display: flex;
      align-items: center;
      border: 1px solid #c5c5c5;
      color: #c5c5c5;
      border-radius: 4px;
      min-width: 60px;
      height: 36px;
      padding: 8px;
      cursor: pointer;

      &.active {
        border-color: currentColor;

        &.inflow {
          color: #19ae57;
        }

        &.cashflow {
          color: #fd561f;
        }
      }

      .icon {
        display: block;
        height: 16px;
        width: 16px;
        border-radius: 2px;
        margin-right: 4px;
        background: currentColor;
      }

      .title {
        display: block;
        font-size: 9px;

        &.inflow {
          color: #19ae57;
        }

        &.cashflow {
          color: #fd561f;
        }
      }
    }
  }

  &__chart {
    margin-top: 24px;
  }
}
