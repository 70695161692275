@import "../../../../assets/styles/base/variables";
@import "../../../../assets/styles/base/mixins";

.container {
  padding-top: 4px;

  .dropdrown {
    position: absolute;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    background: #ffffff;
    box-shadow: 0px 20px 36px rgba(0, 0, 0, 0.05);
    z-index: 5;
    width: 150px;
    gap: 16px;
    padding: 14px;
    margin-top: 10px;
    border: $light-border;
    right: 100px;

    .dropdrownItem {
      cursor: pointer;
      padding: 4px 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        display: flex;
        align-items: center;
      }
    }
  }
}
