@import "../../../../assets/styles/base/variables";
@import "../../../../assets/styles/base/mixins";

.productGridCard {
  padding: 8px;
  border-radius: 8px;
  border: $light-border;
  background: $white-color;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 190px;
  cursor: pointer;
  overflow: hidden;
  @include respond-to("mobile") {
    height: 200px;
  }

  .productImage {
    min-height: 100px;
    max-height: 100px;
    width: calc(100% + 16px);
    flex-shrink: 0;
    margin-left: -8px;
    margin-top: -8px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #75ce9a;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .rowItem {
    display: flex;
    justify-content: space-between;
    gap: 5px;
    // align-items: center;
    p {
      font-size: 14px;
      @include respond-to("mobile") {
        font-size: 10px;
      }

      &.stock {
        min-width: 30px;
        background-color: #19ae57;
        color: $white-color;
        display: flex;
        align-items: center;
        gap: 5px;
        padding: 5px;
        border-radius: 16px;
        max-height: 20px;
        justify-content: center;
        font-size: 10px;
        &.lowQuantity {
          background-color: #ed0000;
        }
      }
    }
  }

  .product__actions {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .variantIcon {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 24px;
    height: 24px;
    background: $white-color;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
