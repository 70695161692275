@import "../../../assets/styles/base/mixins";
@import "../../../assets/styles/base/variables";

.batchForm {
  padding: 20px;
  .formSection {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 13px;
  }
}
.inputSection {
  display: flex;
  gap: 38px;
  align-items: center;
  justify-content: center;

  @include respond-to("tablet") {
    flex-direction: column;
    gap: 0;
  }
}

.cardSession {
  background: $white-color;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 5px;
  border-radius: 8px;
}
