@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.bottom {
  padding: 15px 0px;
  background: #ffffff;
  position: sticky;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  margin-top: 15px;
  bottom: -25px;
  @include respond-to("mobile") {
    flex-direction: column;
  }
}

.location {
  display: flex;
  gap: 10px;
  @include respond-to("mobile") {
    flex-direction: column;
  }
}

.locationInfo {
  font-style: italic;
}
