@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.transferStockWidget {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 40px;
}

.transferStockHeader {
  display: flex;
  align-items: center;
  padding: 42px 0;

  h3 {
    margin-left: 16px;
  }
}

.transferStockForm {
  flex: auto;
  margin-top: 42px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.transferStockFormSection {
  height: 100%;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.transferStockFormGroup {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.dropdownSection {
  width: 100%;

  & > div.custom-dropdown {
    width: 100%;
    max-width: 100%;
  }
}

.backButton {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white-color;
  border-radius: 8px;
  border: 1px solid rgba(118, 71, 238, 1);

  &:focus {
    outline: none;
  }
}
