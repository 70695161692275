@import "../../../../assets/styles/base/variables";
@import "../../../../assets/styles/base/mixins";

.customerCard {
  background-color: $white-color;
  border-radius: 12px;
  padding: 24px;
}

.customerCard__details {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  column-gap: 30px;
  row-gap: 15px;
  margin-top: 10px;
  margin-bottom: 20px;
}
