@import "../../../../../../../assets//styles/base/mixins";

.mainContainer {
  width: 100%;
  .btnSaveBtnContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 3.5em;
    padding-bottom: 60px;
    @include respond-to("big-tablet") {
      padding-top: 1.5em;
      padding-bottom: 40px;
    }

    .saveBtnContainer {
      display: none;
      @include respond-to("big-tablet") {
        display: block;
      }
    }
  }

  .paymentMainContainer {
    width: 100%;

    .paymentHead {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 4px;

      // @include respond-to("big-tablet") {
      //   display: none;
      // }
    }

    .selectPaymentMethod {
      display: flex;
      align-items: center;
      gap: 28px;
      margin-top: 24px;
      width: 100%;

      .payment {
        border: 1px solid #95959559;
        padding: 32px;
        border-radius: 12px;
        display: flex;
        align-items: center;
        gap: 24px;
        width: 100%;
        cursor: pointer;

        @include respond-to("big-tablet") {
          padding: 12px 8px;
          gap: 10px;
        }

        .radioUnselect {
          border: 2px solid #c5c5c5;
          width: 32px;
          height: 32px;
          border-radius: 40px;

          @include respond-to("big-tablet") {
            width: 20px;
            height: 20px;
          }
        }

        .radioSelect {
          width: 32px;
          height: 32px;
          border-radius: 40px;
          border: 2px solid #7647ee;
          display: flex;
          align-items: center;
          justify-content: center;

          @include respond-to("big-tablet") {
            width: 20px;
            height: 20px;
          }

          .inside {
            width: 20.8px;
            height: 20.8px;
            border-radius: 999px;
            background: #7647ee;

            @include respond-to("big-tablet") {
              width: 10.4px;
              height: 10.4px;
            }
          }
        }

        .paymentText {
          color: black;
          font-size: 24px;
          line-height: 32px;

          @include respond-to("big-tablet") {
            font-size: 14px;
            line-height: 18px;
          }
        }
      }
    }

    .paymentSectionContainer {
      margin-top: 48px;
      border: 1px solid #7647ee;
      padding: 16px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include respond-to("big-tablet") {
        flex-direction: column;
        align-items: flex-start;
        border: 0px;
        padding: 0px;
      }

      .keyMainContainer {
        display: flex;
        flex-direction: column;
        gap: 16px;
        @include respond-to("big-tablet") {
          gap: 0px;
        }
        .keyContainer {
          .secretHeading {
            // margin-bottom: 24px;
            .title {
              font-size: 16px;
              line-height: 24px;
              font-weight: 700;

              span {
                font-size: 14px;
                font-style: italic;
                font-weight: 400;
                line-height: 24px;
                color: rgb(255, 132, 0);
              }
            }

            .description {
              color: #696677;
              margin-top: 4px;
              font-size: 14px;
              line-height: 20px;
              width: 60%;

              @include respond-to("big-tablet") {
                width: 90%;
              }

              span {
                font-size: 14px;
                color: #7647ee;
                text-decoration: underline;
                cursor: pointer;
              }
            }
          }

          .inputContainer {
            margin-bottom: 16px;
          }

          .webhookContainer {
            box-shadow: 0px 0px 4px 0px #00000024;
            padding: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 12px;
            margin-top: 16px;
            position: relative;

            @include respond-to("big-tablet") {
              padding: 10px 14px;
            }

            span {
              color: #15112d;
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;
            }

            .copyButton {
              border: 1px solid #7647ee;
              padding: 12px 24px;
              border-radius: 8px;
              display: flex;
              align-items: center;
              cursor: pointer;

              @include respond-to("big-tablet") {
                padding: 8px 14px;
              }

              span {
                color: #7647ee;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                margin-left: 8px;

                @include respond-to("big-tablet") {
                  font-size: 12px;
                  margin-left: 4px;
                }
              }
            }
          }
        }
        .saveBtnContainer {
          margin-top: 48px;

          @include respond-to("big-tablet") {
            display: none;
          }
        }
      }

      .videoContainer {
        padding: 0px 24px;
        border-left: 1px solid #eaeaea;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include respond-to("big-tablet") {
          border-left: 0px;
          padding: 0px;
          margin-top: 40px;
          width: 100%;
        }

        .keySetupContainer {
          @include respond-to("big-tablet") {
            align-self: flex-start;
          }
          .setupTitle {
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
            margin-bottom: 12px;
          }

          .keySetupSteps {
            display: flex;
            flex-direction: column;
            gap: 12px;
            margin-top: 12px;
            width: 400px;

            @include respond-to("big-tablet") {
              width: 100%;
            }

            .step {
              display: flex;
              align-items: flex-start;

              p {
                color: black;
                font-size: 14px;
                line-height: 20px;
                margin-left: 8px;

                span {
                  font-size: 14px;
                  color: #7647ee;
                  text-decoration: underline;
                  cursor: pointer;
                }
              }
            }
          }
        }

        .divider {
          background: #c8b5f8;
          height: 1px;
          width: 100%;
          margin: 32px 0;
        }

        .heading {
          display: flex;
          flex-direction: column;
          align-items: center;
          .title {
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            color: black;
          }
          .paystackLink {
            font-size: 14px;
            line-height: 20px;
            margin-top: 4px;

            span {
              color: #7647ee;
              text-decoration: underline;
              cursor: pointer;
            }

            img {
              margin-left: 2px;
            }
          }
        }

        .or {
          margin: 4px 0;
          color: #696677;
          font-size: 14px;
          line-height: 20px;
          align-self: center;
        }

        .videoTopText {
          color: #696677;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          width: 80%;
        }

        .video {
          position: relative;
          background: #15112d;
          width: 248px;
          height: 124px;
          border-radius: 8px;
          margin-top: 4px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0); /* Transparent */
            cursor: pointer;
          }
        }
      }
    }
  }
}
