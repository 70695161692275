@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.endOfDayStatement {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.formSection,
.endOfDayDetails {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 30px 0;
}
.rowCover {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.rowItem {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  &.rowBorder {
    border-radius: 8px;
    border: $light-border;
    padding: 15px 20px;
    cursor: pointer;
    align-items: center;
  }
  .item {
    width: 100%;
    max-width: calc(30% - 10px);
    text-transform: capitalize;
    &:last-child {
      max-width: calc(40% - 10px);
    }
  }
}
.orderBody {
  display: flex;
  flex-direction: column;
  gap: 30px;

  &__table {
    border: $light-border;
    border-radius: 8px;
    padding: 15px;
    &__header {
      border-bottom: $light-border;
      margin-bottom: 10px;
    }
  }
}

.daterangeSection {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .timeInput {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
  }
  .dateInput {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.downloadContainer {
  display: flex;
  flex-direction: column;
  margin-top: 150px;
  padding-top: 10px;
  //padding-bottom: 5px;
  position: sticky;
  bottom: 0;
  background: white;

  .documentItem {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-right: auto;
  }

  .emailContainer {
    border: 1px solid #7647ee;
    height: 42px;
    width: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    margin-left: 8px;
    cursor: pointer;
  }
}

.processingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin: auto 0;
  width: 100%;
}

.progressContainer {
  background: #cac6dc;
  width: 100%;
  height: 12px;
  border-radius: 60px;
}

.progress {
  height: 100%;
  border-radius: 60px;
  background: #7647ee;
}
