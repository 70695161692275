@import "../../../../../assets//styles/base/variables";
@import "../../../../../assets//styles/base/mixins";

.add-single-product-page {
  border-radius: 8px;

  &__content {
    margin-top: 24px;
    padding: 24px;
    background-color: $white-color;
    min-height: 400px;

    @include respond-to("mobile") {
      padding: 0;
    }

    &-options {
      min-width: 648px;
      display: flex;
      align-items: center;
      gap: 28px;
      margin-bottom: 48px;

      @include respond-to("big-tablet") {
        flex-direction: column;
        width: 100%;
        min-width: 100%;
        padding: 16px;
      }
      @include respond-to("mobile") {
        margin-bottom: 0;
      }
      & > label {
        @include respond-to("big-tablet") {
          max-width: 100%;
        }
      }
    }

    &-header {
      @include respond-to("mobile") {
        padding: 24px;
      }
    }
  }
}
