.createExpenseCategory {
  padding: 20px;
  &__form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-top: 30px;
  }
}
