@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

@mixin flex-row($gap: 15px) {
  display: flex;
  gap: $gap;
}

.table_container {
  padding: 0 15px;
  @include flex-row;
  flex-direction: column;
  @include respond-to("mobile") {
    padding: 0px;
  }
}

.table_header {
  @include flex-row;
}

.card_container {
  display: grid;
  place-items: center;
  column-gap: 16px;
  row-gap: 16px;
  grid-template-columns: repeat(4, minmax(0, 1fr));

  @include respond-to("large") {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @include respond-to("big-tablet") {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @include respond-to("tablet") {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @include respond-to("mobile") {
    column-gap: 10px;
    row-gap: 10px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.no_table_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 0;

  img {
    @include respond-to("big-tablet") {
      width: 50%;
    }
  }
}

.no_table_text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 32px 0;
}
