@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.terminal-request-form {
  width: 55%;
  box-shadow: 0px 0px 4px 0px #00000024;
  background-color: $white-color;
  border-radius: 12px;
  padding: 48px 32px;

  @include respond-to("large") {
    width: 70%;
  }

  @include respond-to("small") {
    width: 100%;
  }

  @include respond-to("tablet") {
    padding: 24px;
  }
  &__field {
    margin-bottom: 10px;
  }
}
