@import "../../../../assets/styles/base/variables";
@import "../../../../assets/styles/base/mixins";

.markets {
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
  }
  &__apps {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
    column-gap: 55px;
    row-gap: 65px;
  }
  &__card {
    display: flex;
    flex-direction: column;
    gap: 45px;

    .card-content:hover h6 {
      color: $purple-color;
    }
  }
}

.card {
  overflow: hidden;
  border-radius: 8px;

  img {
    width: 30%;
    height: 100%;
    object-fit: cover;
  }

  .card-content {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 1px;
    padding-top: 1px;
  }

  .card-content:hover h6 {
    color: $purple-color;
  }
}
