@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.storeProductsPage {
  display: flex;
  width: 100%;
  gap: 20px;
  padding: 20px 20px 0 20px;
  @include respond-to("small") {
    flex-direction: column;
  }
  @include respond-to("big-tablet") {
    background: $white-color;
    min-height: 80vh;
  }

  .productsList {
    flex: auto;
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: calc(100% - 470px);
    @include respond-to("small") {
      padding-bottom: 70px;
      max-width: 100%;
    }

    .viewMenuContainer {
      display: flex;
      justify-content: flex-end;
      margin-left: auto;
      gap: 7px;
      flex-wrap: wrap;
      img {
        cursor: pointer;
        height: 37px;
        width: 35px;
        border-radius: 5px;
        &.active {
          border: 2px solid #f7ad3c;
        }
      }
    }

    .tableOrderText {
      padding: 10px 16px;
      background-color: #ffeee9;
      display: inline-block;
      border-radius: 8px;
      font-size: 16px;
      line-height: 24px;
      color: #fd561f;
      margin-bottom: 10px;
      span {
        font-weight: 700;
      }

      @include respond-to("mobile") {
        padding: 4px 6px;
        font-size: 12px;
      }
    }
  }

  .productsGridList {
    display: grid;
    gap: 15px;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    @include respond-to("mobile") {
      grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    }
  }

  .cartSection {
    position: sticky;
    height: calc(100vh - 55px);
    width: 470px;
    top: 87px;
    margin-top: -80px;
    margin-right: -20px;
    z-index: 2;
    transition: 0.1s ease;
    @include respond-to("small") {
      width: 100%;
      top: auto;
      bottom: 0;
      position: fixed;
      left: 0;
      height: 70px;
      overflow: hidden;
    }
    &.active {
      @include respond-to("small") {
        height: calc(100% - 157px);
      }
    }
  }
}
