@import "../../../assets/styles/base/mixins";
@import "../../../assets/styles/base/variables";

.headingContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0px;
  border-bottom: 0.75px solid #97979759;
  margin-bottom: 40px;

  .heading {
    display: flex;
    align-items: center;
    gap: 12px;

    @include respond-to("tablet") {
      gap: 2px;
    }

    h4 {
      color: #fd561f;
    }
  }

  .routeButton {
    border: 1px solid #7647ee;
    border-radius: 8px;
    background: #f3f1fc;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px 14px;
    cursor: pointer;

    @include respond-to("tablet") {
      padding: 6px 12px;
    }

    span {
      color: #7647ee;
      font-size: 14px;
      font-weight: 500;

      @include respond-to("tablet") {
        font-size: 12px;
      }
    }
  }
}

.orderDetails {
  display: flex;
  gap: 1%;
  justify-content: space-between;
  margin-bottom: 20px;

  p {
    overflow: hidden;
    width: 24%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.transactionDetails__column {
  display: flex;
  gap: 20px;
  padding: 15px;
  border-radius: 8px;
  border: $light-border;
  flex-wrap: wrap;
  section {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: $light-border;

    .statusHeading {
      width: 100%;
      display: flex;
      align-items: center;

      p {
        margin-right: 8px;
      }

      h4 {
        color: #f7ad3c;
      }
    }

    .actionContainer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      gap: 20px;
    }
  }
  & > div {
    width: calc(33.3% - 15px);
    @include respond-to("tablet") {
      width: calc(50% - 10px);
    }
  }
}

.order_note {
  margin-top: 24px;
  margin-bottom: 12px;
  border: 0.75px solid #9797974d;
  padding: 12px 16px;
  border-radius: 12px;

  .order_note_field {
    margin-top: 8px;
    border: 0.75px solid #9797974d;
    padding: 18px 16px;
    border-radius: 4px;
  }
}

.buttonContainer {
  display: flex;
  margin-top: auto;
  gap: 10px;
  position: sticky;
  bottom: -10px;
  background: #fff;
  padding: 10px 0;
}

.notificationContainer {
  width: 664px;
  // height: 92px;
  background: #5d5fef;
  position: absolute;
  left: 30%;
  top: 20px;
  z-index: 999;
  border-radius: 12px;
  border: 2px solid #c5c6f6;
  padding: 16px;
  display: flex;
  align-items: flex-start;
  position: fixed;

  @include respond-to("small") {
    left: 10%;
    width: 80%;
    padding: 10px;
  }

  @include respond-to("big-tablet") {
    left: 8%;
    width: 80%;
    padding: 10px;
  }

  .notificationIcon {
    margin-top: 4px;

    @include respond-to("big-tablet") {
      margin-top: 12px;
    }
  }

  .notificationContent {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-left: 12px;
    height: 100%;
    width: 100%;

    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      width: 70%;
      // height: 100%;

      .textContent {
        .mainText {
          color: white;
          opacity: 0.8;
          font-size: 20px;
          line-height: 28px;
          span {
            font-weight: 700;
          }

          @include respond-to("big-tablet") {
            font-size: 16px;
            line-height: 20px;
          }
        }
        .subText {
          opacity: 0.8;
          color: white;
          font-size: 16px;
          line-height: 24px;

          @include respond-to("big-tablet") {
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }

    .button {
      margin-top: 4px;
      padding: 12px 24px;
      background: white;
      color: #7647ee;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      cursor: pointer;
      width: auto;

      @include respond-to("small") {
        font-size: 14px;
        padding: 10px 12px;
      }

      @include respond-to("big-tablet") {
        font-size: 12px;
        padding: 8px 10px;
      }
    }
  }

  .closeContainer {
    cursor: pointer;
    position: absolute;
    right: -14px;
    top: -14px;
  }
}
