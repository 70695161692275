@import "../../../assets/styles/base/mixins";
@import "../../../assets/styles/base/variables";

.tableSection {
  width: 100%;
  // transform: rotateX(180deg);
  // -ms-transform: rotateX(180deg); /* IE 9 */
  // -webkit-transform: rotateX(180deg); /* Safari and Chrome */
  // overflow-x: auto;
  overflow-x: auto;
  overflow-y: hidden;
  min-height: 200px;
  padding-top: 20px;
  &.dontShowOnMobile {
    @include respond-to("big-tablet") {
      display: none;
    }
  }
  &::-webkit-scrollbar {
    width: 8px; /* Set the width of the scrollbar */
    height: 8px;
    position: absolute;
    top: 0; /* Position the scrollbar on the left of the element */
  }

  &::-webkit-scrollbar-thumb {
    background-color: $purple-color; /* Set the color of the thumb */
  }
  &.handleScroll {
    overflow: auto;
    padding-top: 0;
    padding-bottom: 20px;
    max-height: calc(100vh);
    // transform: rotateX(0deg);
    // -ms-transform: rotateX(0deg); /* IE 9 */
    // -webkit-transform: rotateX(0deg); /* Safari and Chrome */
    table,
    // .mobileTable {
    //   transform: rotateX(0deg);
    //   -ms-transform: rotateX(0deg); /* IE 9 */
    //   -webkit-transform: rotateX(0deg); /* Safari and Chrome */
    // }

    table {
      thead {
        position: sticky;
        top: 0;
        background: $white-color;
        z-index: 1;
      }
    }
  }

  table {
    border-collapse: collapse;
    background-color: $white-color;
    min-width: 100%;

    // transform: rotateX(180deg);
    // -ms-transform: rotateX(180deg); /* IE 9 */
    // -webkit-transform: rotateX(180deg); /* Safari and Chrome */
    // transform: rotateX(180deg) scale(1);
    // -ms-transform: rotateX(180deg) scale(1); /* IE 9 */
    // -webkit-transform: rotateX(180deg) scale(1); /* Safari and Chrome */

    @include respond-to("big-tablet") {
      display: none;
    }

    th,
    td {
      padding: 10px 24px;
      font-size: 12px;
      text-align: left;
      min-width: 140px;
      max-width: 140px;

      &:first-child {
        min-width: 150px;
      }
    }

    tr {
      border-bottom: 1px solid #edf2f7;
    }

    thead {
      th {
        color: #696677;
      }
    }

    tbody {
      tr {
        &:last-child {
          border-bottom: none;
        }
      }

      td {
        color: $dark-color;
        padding: 12px 24px;
      }
    }
  }

  .mobileTable {
    background: $white-color;
    display: none;
    // transform: rotateX(180deg);
    // -ms-transform: rotateX(180deg); /* IE 9 */
    // -webkit-transform: rotateX(180deg); /* Safari and Chrome */

    @include respond-to("big-tablet") {
      display: block;
    }

    &__row {
      padding: 10px 10px;
      border-bottom: 1px solid #ddd;
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    .rowItem {
      display: flex;
      gap: 20px;
      justify-content: space-between;
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }

      &__value {
        font-size: 12px;
      }

      &__title {
        margin-bottom: 0;
        font-weight: normal;
        font-size: 12px;
      }
    }
  }
}
