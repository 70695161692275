@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";
.compositeForm {
  &__content {
    display: flex;
    flex-direction: column;
    gap: 50px;
  }
  &__stepperCover {
    width: 100%;
    max-width: 600px;
    margin: auto;
    position: relative;
  }
}
