.storeCustomerForm {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  &__bottom {
    position: sticky;
    bottom: 0px;
    margin-top: auto;
    padding-top: 10px;
    display: flex;
    gap: 20px;
  }
}

.close_icon {
  margin-top: 10px;
  display: block;
  margin-left: auto;
}

.bordererd {
  border-radius: 8px;
  border: 0.75px solid rgba(151, 151, 151, 0.35);
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.restaurant_table {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.selected_table_container {
  width: "100%";
  display: flex;
  align-items: flex-start;
}

.selected_table {
  display: flex;
  align-items: flex-end;
  background: rgba(197, 197, 197, 0.3);
  border-radius: 8px;
  padding: 8px 12px;
  .table_name {
    font-size: 10px;
    color: #15112d;
    margin-right: 8px;
    span {
      font-weight: 700;
    }
  }
}

.restaurant_heading_container {
  width: 70%;
}

.restaurant_heading {
  display: flex;
  align-items: center;
  gap: 10px;
}

.restaurant_text {
  margin-left: 10px;
  font-weight: 500;
  font-size: 20px;
  color: #15112d;
}

.order_notes_container {
  padding: 12px 16px;
  border-radius: 8px;
  border: 0.75px solid rgba(151, 151, 151, 0.35);
  margin-bottom: 24px;
}

.text_area {
  font-size: 14px;
  margin-top: 8px;
}

.logistics_container {
  padding: 16px;
  border-radius: 8px;
  border: 0.75px solid rgba(151, 151, 151, 0.35);
  margin-bottom: 56px;
}
