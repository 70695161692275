@import "../../../assets/styles/base/mixins";

.kyb-verification-page {
  padding-top: 3.5em;

  @include respond-to("tablet") {
    padding: 2em 0;
  }

  @include respond-to("mobile") {
    padding: 2em 0;
  }

  &__form__header {
    &-options {
      display: flex;
      align-items: center;
      margin-top: 32px;
      gap: 30px;

      @include respond-to("big-tablet") {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
      }

      .wallet__option {
        @include respond-to("big-tablet") {
          max-width: 100%;
        }
      }
    }
  }

  &__form__content {
    margin-top: 32px;
    max-width: 730px;

    @include respond-to("big-tablet") {
      max-width: 100%;
    }
  }
}
