@import "../../../../assets/styles/base/variables";
@import "../../../../assets/styles/base/mixins";

.terminal-details {
  &__header {
    margin-top: 32px;
  }

  &__content {
    margin-top: 24px;
    background-color: $white-color;
    padding: 24px;
    border-radius: 12px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
    @include respond-to("big-tablet") {
      grid-template-columns: repeat(3, 1fr);
    }
    @include respond-to("tablet") {
      padding: 15px;
      grid-template-columns: repeat(2, 1fr);
    }

    &-item {
      span {
        font-size: 14px;
        color: #696677;
      }
    }
  }
}
