@import "../../../assets/styles/base/mixins";
@import "../../../assets/styles/base/variables";

.subscribeUpgradeNotification {
  display: flex;
  flex-direction: column;
  gap: 25px;
  height: 100%;
  .messageSection {
    flex-direction: row;
    gap: 10px;
  }

  .planSection {
    text-align: center;
    gap: 20px;
    .currentPlan {
      width: fit-content;
      margin: auto;
      border-radius: 30px;
      border: $light-border !important;
      cursor: context-menu;
    }
  }
}
