@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.existingClientConnect {
  width: 100%;
  height: 100%;
  background: #f8f8f8;
  position: absolute;
  max-width: 1600px;
  margin: auto;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  padding: 40px 5%;
  &__content {
    position: relative;
    z-index: 1;
    width: 100%;
    max-width: 600px;
    margin: auto;
    padding: 20px 30px;
    background: $white-color;
    border-radius: 8px;
    border: 1px solid #00000024;
    display: flex;
    flex-direction: column;
    gap: 40px;
    &__header {
      padding-bottom: 15px;
      border-bottom: $light-border;
      width: calc(100% + 60px);
      margin-left: -30px;
      text-align: center;
    }
    &__bottom {
      display: flex;
      gap: 15px;
      margin-top: auto;
    }

    .businessDetails {
      display: flex;
      flex-direction: column;
      &__item {
        width: calc(100% + 60px);
        margin-left: -30px;
        display: flex;
        justify-content: space-between;
        gap: 20px;
        padding: 15px 30px;
        border-top: $light-border;
        align-items: center;
        &:last-child {
          border-bottom: $light-border;
        }
      }
    }
  }
  &__design {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
