@import "../../../../assets/styles/base/variables";
@import "../../../../assets/styles/base/mixins";

.overview {
  margin-top: 33px;

  &__cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    gap: 24px;

    &__card {
      background-color: $white-color;
      padding: 24px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      overflow-x: auto;

      &.available__balance {
        background: url(../../../../assets/images/wallet-card.png);
        background-size: cover;
        background-repeat: no-repeat;
        color: $white-color;

        .overview__cards__card__title,
        h5,
        .overview__cards__card__subtitle {
          color: inherit;
        }

        .withdraw__btn {
          height: 32px;
          width: 141px;
          font-size: 12px;
          margin-top: 16px;
          color: #19ae57;
          border-color: #19ae57;

          .d-flex {
            .fa-spin {
              color: $purple-color !important;
              font-size: 14px !important;
            }
          }
        }
      }

      &__details {
        width: 100%;

        .btn__copy {
          height: 35px;
          width: 100%;
          padding: 7px 24px 7px 24px;
        }

        .btn__verify {
          height: 32px;
          width: 141px;
          font-size: 12px;
        }
      }

      &__title {
        font-size: 14px;
        color: #696677;
      }

      &__subtitle {
        font-size: 12px;
        color: #19ae57;

        &.red {
          color: #e02020;
        }
      }
    }
  }

  &__summary {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 24px;
    margin-top: 24px;

    @include respond-to("mobile") {
      padding: 34px 15px;
      grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    }

    &__card {
      background: #ffffff;
      padding: 24px;
      border: 1px solid #f3f1fc;
      border-radius: 8px;
      @include respond-to("tablet") {
        display: none;
      }

      &.chart-card {
        padding: 24px 24px 24px 0;
      }

      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;
      }

      &-title {
        font-size: 20px;
      }

      &-link {
        font-size: 14px;
        text-decoration: underline;
      }

      .recent__transactions__list {
        display: flex;
        flex-direction: column;

        .recent__transaction {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          padding: 14px 47px 14px 0;
          gap: 40px;

          p {
            font-size: 14px;
          }
        }
      }
    }
  }
}
