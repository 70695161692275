@import "../../../../../assets/styles/base/variables";
@import "../../../../../assets/styles/base/mixins";

.createCampaign {
  gap: 25px;
  &__content {
    background-color: $white-color;
    padding: 32px;
    border-radius: 8px;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
