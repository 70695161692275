@import "../../../../../assets/styles/base/variables";
@import "../../../../../assets/styles/base/mixins";

.creditLog {
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;

  &__content {
    background: $white-color;
    border-radius: 16px;
    padding-bottom: 24px;
    min-height: 400px;

    &__header {
      padding: 24px 24px 24px 24px;
      border-bottom: 2px solid #e7f1fa;
      display: flex;
      align-items: center;
      // justify-content: space-between;
      gap: 10px;
      flex-wrap: wrap;
      @include respond-to("large") {
        padding: 15px 15px 5px 15px;
      }
      .searchField {
        max-width: 600px;
        min-width: 350px;
        width: 100%;
        @include respond-to("tablet") {
          min-width: 100%;
        }
      }
    }
  }
}
