@import "../../assets/styles/base/variables";
@import "../../assets/styles/base/mixins";

.storeLayout {
  background: #ededed;
  min-width: 1200px;
  max-width: 1600px;
  width: 100%;
  height: 100%;
  position: absolute;
  margin: auto;
  overflow-y: auto;
  overflow-x: hidden;
  zoom: 95%;
  @include respond-to("large") {
    min-width: 0;
  }

  &__header {
    position: sticky;
    top: 0px;
    left: 0;
    z-index: 1;

    &__top {
      padding: 15px;
      background-color: $purple-color;
      height: 87px;

      .navSection {
        color: $white-color;
        display: flex;
        align-items: flex-start;
        gap: 10px;
        justify-content: space-between;
      }

      .storeName {
        width: 120px;
        height: 50px;
        color: $white-color;
        display: flex;
        padding: 0 15px;
        align-items: center;
        background: $dark-yellow;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        @include respond-to("tablet") {
          display: none;
        }
      }

      .headerLeft {
        display: flex;
        align-items: center;
        @include respond-to("tablet") {
          width: 100%;
        }
      }

      .searchSection {
        min-width: 550px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        @include respond-to("tablet") {
          min-width: 350px;
          width: 100%;
        }
        @include respond-to("mobile") {
          min-width: calc(100% - 80px);
        }
      }

      .selectContainer {
        min-width: 150px;
        @include respond-to("big-tablet") {
          display: none;
        }
      }

      .backButton {
        @include respond-to("tablet") {
          display: none;
        }
      }

      .midSection {
        display: flex;
        align-items: center;
        gap: 10px;
        @include respond-to("big-tablet") {
          width: 100%;
        }
      }

      .navSearchSection {
        display: flex;
        align-items: center;
        @include respond-to("tablet") {
          width: 100%;
        }
      }

      .storeMenu {
        display: flex;
        gap: 20px;

        & > div {
          @include respond-to("small") {
            display: none;
          }
        }
        & > div:last-child {
          @include respond-to("small") {
            display: block;
          }
        }
      }
      .divider {
        width: 1px;
        background: $white-color;
        height: 87px;
        margin-top: -15px;
      }
    }

    .noOfPends {
      width: 25px;
      height: 25px;
      flex-shrink: 0;
      background: $danger-color;
      border-radius: 25px;
      color: $white-color;
      position: absolute;
      top: -12.5px;
      right: -12.5px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
    }
  }

  &__categories {
    padding: 12px 20px;
    display: flex;
    align-items: center;
    gap: 24px;
    overflow-x: hidden;
    width: calc(100% - 470px);
    background-color: $dark-purple-color;

    @include respond-to("small") {
      width: 100%;
    }

    .categoryContainer {
      display: flex;
      align-items: center;
      gap: 10px;
      overflow: auto;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      /* Hide scrollbar for Chrome, Safari and Opera */
      &::-webkit-scrollbar {
        display: none;
      }
    }

    .categoryScroller {
      min-width: 30px;
      min-height: 30px;
      border-radius: 30px;
      align-items: center;
      justify-content: center;
      background: #f3f1fc;
      display: none;
      cursor: pointer;
      color: $purple-color;
      &.active {
        display: flex;
      }
    }

    .category {
      padding: 7px 15px;
      border-radius: 24px;
      color: $white-color !important;
      flex-shrink: 0;
      font-size: 14px;
      max-width: 200px;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      &:hover {
        color: $white-color !important;
      }
    }

    .isActive {
      background-color: $purple-color !important;
      color: white;
      &:hover {
        color: $white-color !important;
        text-decoration: none;
      }
    }
  }
}

.table_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
}

.card_container {
  display: grid;
  place-items: center;
  gap: 16px;
  grid-template-columns: auto-fill, minmax(300px, 1fr);
}
