.trackItem {
  .custom-dropdown {
    width: 150px;
    &__select {
      &__control {
        min-height: 40px !important;
      }
    }
  }
}
