@import "../../../assets/styles/base/variables";

.amount-container {
  width: 100%;

  &__input-section {
    height: 50px;
    border: 0.75px solid rgba(151, 151, 151, 0.8);
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 0 1em 2px 1em;
    background: #f3f1fc;
    width: 100%;

    &:hover {
      border-color: $purple-color;
    }

    &:focus-within {
      border-color: $purple-color;
    }

    &.error-border {
      border-color: $danger-color;
    }

    &.isDisabled {
      cursor: not-allowed !important;
      background: rgba(0, 0, 0, 0.05) !important;
      color: -internal-light-dark(
        rgb(84, 84, 84),
        rgb(170, 170, 170)
      ) !important;
      border-color: rgba(151, 151, 151, 0.8) !important;
    }

    input {
      margin-left: 2px;
      height: 100%;
      outline: none;
      border: none;
      background: transparent;
      width: calc(100% - 36px);
      &:disabled {
        cursor: not-allowed !important;
      }
    }
  }

  .error-message {
    font-size: 13px;
    visibility: hidden;
    display: flex;
    margin-top: 2px;
    min-height: 15px;

    &.show {
      visibility: visible;
    }
  }
}
