@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.card {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: $white-color;
  padding: 5px;
  margin-top: 5px;
  margin-bottom: 0px;
}

.progressBar {
  height: 15px;
  background-color: #d2d0d8;
  width: 93%;
  border-radius: 16px;

  &__progressive {
    height: 15px;
    background-color: $purple-color;
    border-radius: 16px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.guide {
  display: flex;
  flex-direction: column;

  &__items {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    border: $light-border;
    height: auto;
    justify-content: space-between;
  }
  &__collapse {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 6px;
    cursor: pointer;
  }
}

.dropDown {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: $lumi-get-started;
  margin-bottom: 5px;
  border-radius: 8px;
  &__container {
    height: 180px;
    margin-top: 20px;
    border-radius: 8px;
  }
  &__content {
    display: flex;
    flex-direction: column;
    column-gap: 1px;
    row-gap: 5px;
    overflow-wrap: break-word;
    margin: 5px;
  }
}
.video {
  border-radius: 17px;
  margin-top: 15px;
  overflow: hidden;
  position: relative;
}
