@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.login-page {
  background-color: $white-color;
  max-width: 500px;
  border: 1px solid #f3f1fc;
  box-shadow: -4px 8px 24px 0px #2c3f5805;
  padding: 56px 32px;
  border-radius: 20px;
  margin: auto;

  @include respond-to("mobile") {
    padding: 25px 16px;
  }

  &__container {
    .login__form {
      margin-top: 2em;
    }
    .route {
      display: none;
      @include respond-to("mobile") {
        display: block;
      }
    }
  }
}
