@import "../../../assets/styles/base/variables";

.sectionView {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 5px 20px 30px 20px;
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;

  p {
    color: $dark-color !important;
    font-weight: bold !important;
    font-size: 25px;
  }

  .smallSize {
    p {
      font-size: 18px !important;
    }
  }

  &__item {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
}
