@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.salesWidget {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  gap: 20px;
}

.salesWidgetHeader {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.salesWidgetContent {
  flex: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.salesWidgetTableContainer {
  display: flex;
  flex-direction: column;
  border: 2px solid rgba(118, 71, 238, 1);
  margin-top: 30px;
  height: 90vh;
  border-radius: 16px;
}

.searchSection {
  max-width: 500px;
  width: 100%;
}

.salesWidgetTableContainerHeader {
  box-shadow: 0px 1px 0px 0px rgba(237, 242, 247, 1);
  padding: 24px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  @include respond-to("tablet") {
    flex-wrap: wrap;
  }
}
