.transactionsTable {
  table {
    th,
    td {
      &:last-child {
        min-width: 200px;
        max-width: 200px;
      }
    }
  }
}
