@import "../../../../assets/styles/base/variables";
@import "../../../../assets/styles/base/mixins";

.editStoreDetails {
  border-radius: 8px;
  background: $white-color;
  padding: 24px;
  max-width: 700px;
  min-height: 400px;
}
