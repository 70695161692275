@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.dropdown {
  background: $white-color;
  border-radius: 8px;
  width: 250px;
  position: absolute;
  left: 0px;
  top: 55px;
  box-shadow: 1px 1px 3px 2px #ddd;
  overflow: hidden;

  p {
    padding: 10px 20px;
    cursor: pointer;
    position: relative;
    z-index: 2;
    &:hover {
      background: $purple-color;
      color: $white-color;
    }
  }
}
