@import "../../../../assets/styles/base/variables";
@import "../../../../assets/styles/base/mixins";

.inventory-analytics {
  padding-bottom: 41px;
  &__header {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    flex-wrap: wrap;
  }

  &__cards {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }

  &__card {
    min-width: 220px;
    width: calc(25% - 11.25px);
    background-color: $white-color;
    padding: 24px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.14);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    @include respond-to("large") {
      width: calc(33.333% - 10px);
    }
    @include respond-to("big-tablet") {
      width: calc(50% - 7.5px);
    }
    @include respond-to("big-tablet") {
      width: 100%;
    }
  }

  &__summary {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(348px, 1fr));
    gap: 31.5px;

    @include respond-to("large") {
      display: flex;
      flex-direction: column;
      gap: 31.5px;
    }

    &-item {
      background-color: $white-color;
      padding: 24px;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.14);
      border-radius: 8px;

      &__details {
        display: flex;
        flex-direction: column;
        gap: 8px;

        &-title {
          font-size: 14px;
          color: #696677;
        }
      }

      &__profit {
        font-size: 12px;
        color: #19ae57;
        margin-top: 16px;
      }
    }
  }

  &__performing-products {
    background-color: $white-color;
    border: 1px solid #f3f1fc;
    border-radius: 8px;
    max-height: 450px;
    box-shadow: 0px 0px 4px 0px #00000024;
    overflow-y: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__graphs {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(420px, 1fr));
    gap: 31.5px;
    min-height: 336px;

    @include respond-to("large") {
      display: flex;
      flex-direction: column;
      gap: 31.5px;
    }

    &-revenue {
      background-color: $white-color;
      border-radius: 8px;
      box-shadow: 0px 0px 4px 0px #00000024;
      padding: 24px;
    }

    &-profit {
      background-color: $white-color;
      border-radius: 8px;
      box-shadow: 0px 0px 4px 0px #00000024;
      padding: 24px;
    }
  }
}
