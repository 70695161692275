.storeCustomerForm {
  height: 100%;
  display: flex;
  flex-direction: column;
  &__bottom {
    position: sticky;
    bottom: 0px;
    margin-top: auto;
    padding-top: 10px;
    background: #fff;
    display: flex;
    gap: 20px;
  }
}

.back_icon_container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  border-radius: 8px;
  border: 1px solid #7647ee;
  padding: 12px 0px;
  cursor: pointer;
}

.checkout_heading_container {
  margin-top: 16px;
  margin-bottom: 24px;
}

.payment_type_container {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .payment_type {
    border: 1px solid #9797974d;
    padding: 16px 12px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    gap: 10px;
  }
}

.radio {
  width: 28px;
  height: 28px;
  border-radius: 28.33px;
  border: 1.17px solid #c5c5c5;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  .radio_inside {
    width: 22px;
    height: 22px;
    border-radius: 999px;
    background: #7647ee;
  }
}
