@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.lm-date-range {
  position: relative;

  &__input {
    padding: 0 25px;
    min-width: 250px;
    max-width: 305px;
    font-size: 14px;
    height: 50px;
    border-radius: 4px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $purple-color;
    cursor: pointer;
    background: $white-color;
  }

  &__dropdown {
    position: absolute;
    right: 0;
    margin: 5px 0;
    display: none !important;
    box-shadow: 1px 1px 3px 2px #ddd;
    border-radius: 8px;
    z-index: 5;

    .rdrDateDisplay {
      color: $purple-color !important;
    }

    .rdrDayToday .rdrDayNumber span:after {
      background: $purple-color !important;
    }

    .rdrNextPrevButton {
      outline-color: $purple-color;
    }
  }

  &.active {
    .lm-date-range__input {
      border-color: $purple-color;
    }

    .lm-date-range__dropdown {
      display: inline-flex !important;
    }
  }
}
