@import "../../../../assets/styles/base/variables";
@import "../../../../assets/styles/base/mixins";

.mainContainer {
  padding-top: 40px;

  .optionContainer {
    display: flex;
    align-items: center;
    gap: 28px;
    width: 100%;

    @include respond-to("tablet") {
      flex-direction: column;
      gap: 16px;
    }
  }

  .contentContainer {
    box-shadow: 0px 0px 4px 0px #00000024;
    border-radius: 12px;
    padding: 0 24px;
    padding-bottom: 24px;

    @include respond-to("tablet") {
      padding: 0 16px;
    }

    .headingContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 8px;
      border-bottom: 0.5px solid #9797974d;
      margin-bottom: 32px;

      @include respond-to("tablet") {
        padding: 12px 0px;
      }
    }

    .setupGuideContainer {
      width: 600px;
      display: flex;
      flex-direction: column;
      gap: 32px;
      margin: 0 auto;

      @include respond-to("tablet") {
        width: 100%;
        gap: 16px;
      }

      .attentionContainer {
        background: #ffeee9;
        border: 0.75px solid #ffddd2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 10px;
        border-radius: 4px;
        padding: 12px;

        h6 {
          color: #e02020;
        }
      }

      .stepContainer {
        display: flex;
        align-items: flex-start;
        gap: 4px;
        margin-bottom: 20px;

        @include respond-to("big-tablet") {
          align-items: flex-start;
          flex-direction: column;
        }

        .badge {
          background: #efefef;
          border-radius: 8px;
          font-size: 12px;
          padding: 0 8px;
          width: 85px;
          font-weight: 700;

          span {
            color: #19ae57;
          }
        }

        .shortLine {
          background: #9797974d;
          width: 68px;
          height: 1px;
          margin-top: 8px;
        }

        .stepContent {
          width: 420px;

          @include respond-to("tablet") {
            width: 100%;
            margin-top: 4px;
          }

          a {
            font-size: 14px;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
