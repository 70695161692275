@import "../../../../assets/styles/base/mixins";
@import "../../../../assets/styles/base/variables";

.planMobileView {
  border: $light-border;
  border-radius: 8px;
  padding: 15px 8px;
  background: $white-color;

  &__header {
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    margin-bottom: 25px;
    .btn {
      width: 170px;
      margin: auto;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .card {
      padding: 10px 20px;
      border-radius: 8px;
      border: $light-border;
      position: relative;
      &.isActive {
        .card__header {
          border-bottom: $light-border;
        }
      }

      &__header {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        align-items: center;
        border: none;
        padding-bottom: 5px;
        h5 {
          text-transform: uppercase;
          margin-bottom: 0;
        }
      }

      p {
        color: $dark-color;
      }

      &__content {
        display: flex;
        flex-direction: column;
        gap: 30px;
        margin-top: 15px;
      }
    }
  }
}
