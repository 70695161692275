@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.icon {
  width: 18px;
  height: 18px;
  padding: 0;
  border-radius: 3px;
  border: 1px solid $purple-color;
}

.container {
  cursor: pointer;
  position: relative;
  display: flex;
  gap: 4px;
  padding: 5px;
}
.containerTree {
  cursor: pointer;
  position: relative;
  display: flex;
  padding: 2px;
}

.header {
  background-color: $light-green-lumi;
}
