@import "../../../../assets/styles/base/variables";
@import "../../../../assets/styles/base/mixins";

.settlements-page {
  .settlement-options {
    display: flex;
    margin-top: 24px;
    gap: 24px;

    @include respond-to("tablet") {
      flex-direction: column;
    }

    &__item {
      display: flex;
      align-items: center;
      gap: 8px;
      user-select: none;
    }
  }

  &__content {
    margin-top: 44px;
  }
}
