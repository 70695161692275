@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.fancySelectField {
  height: 48px;
  width: 100%;
  display: block;
  font-size: 12px;
  &__input {
    height: 100%;
    width: 100%;
    border: $input-border;
    border-radius: 4px;
    background: $white-color;
    padding: 10px 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    &:hover {
      border-color: $purple-color;
    }
  }
  &__dropdown {
    position: absolute;
    margin-top: 5px;
    width: 100%;
    max-width: 140px;
    background: $white-color;
    padding: 10px 1em;
    display: flex;
    border: 1px solid #9797974d;
    box-shadow: 0px 4px 4px 0px #00000024;
    z-index: 5;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }
  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    padding: 4px 8px;
    width: max-content;
    color: $white-color;
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
    }
    &.input:hover {
      transform: scale(1);
    }
  }
}
