@import "../../../assets/styles/base/variables";

.phone-container {
  width: 100%;

  &__input-section {
    height: 50px;
    border: 0.75px solid rgba(151, 151, 151, 0.8);
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 0 1em 2px 1em;
    background: #f3f1fc;
    // width: 100%;

    &:hover {
      border-color: $purple-color;
    }

    &:focus-within {
      border-color: $purple-color;
    }

    &.error-border {
      border-color: $danger-color;
    }

    .select-code {
      width: 100px;
      height: 100%;
      padding-top: 3px;
      margin-left: 8px;
    }

    input {
      height: 100%;
      width: 100%;
      outline: none;
      border: none;
      background: inherit;
    }
  }

  .error-message {
    font-size: 13px;
    visibility: hidden;
    display: flex;
    margin-top: 2px;
    min-height: 15px;

    &.show {
      visibility: visible;
    }
  }
}
