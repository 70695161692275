@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.serviceCard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 10px;
  border-radius: 8px;
  background-color: $white-color;

  @include respond-to("big-tablet") {
    align-items: flex-start;
    border: 0.75px solid rgba(149, 149, 149, 0.35);
    gap: 15px;
  }

  @include respond-to("mobile") {
    flex-direction: column;
  }

  .serviceCardDetails {
    display: flex;
    align-items: center;
    gap: 15px;
    width: 200px;

    .serviceImage {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      width: 40px;
      height: 40px;
      flex-shrink: 0;

      @include respond-to("big-tablet") {
        width: 60px;
        height: 60px;
      }

      img {
        width: 100%;
        object-fit: cover;
      }
    }
  }

  .servicePricing {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 18px;
    width: 400px;

    @include respond-to("mobile") {
      // flex-direction: column;
      width: 100%;
      align-items: flex-start;
    }

    & > div {
      flex: 1;

      @include respond-to("mobile") {
        flex: none;
      }
    }
  }

  .serviceActions {
    .product__actions {
      display: flex;
      align-items: center;
      gap: 8px;

      button {
        outline: none;
        background: none;
        border: none;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        border-radius: 4px;

        &.increment {
          background-color: $purple-color;
          color: white;
        }

        &.decrement {
          background-color: $white-color;
          color: $purple-color;
          border: 1px solid $purple-color;
        }
      }

      .count {
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        background-color: #f2edfe;
      }
    }
  }

  .addToCartBtn {
    padding: 10px 16px;
    height: 35px;
    font-size: 12px;
    width: 110px;
  }
}
