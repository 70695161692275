@import "../../../assets/styles/base/mixins";

.foodMenuTable {
  .selectAll {
    display: none;
    margin-left: 20px;
    margin-top: 15px;

    @include respond-to("big-tablet") {
      display: flex;
      margin-left: 10px;
      padding-bottom: 10px;
    }
  }
  table {
    th,
    td {
      &:first-child {
        min-width: 300px;
        max-width: 300px;
      }
    }
  }

  &__content {
    flex: auto;
    overflow-y: scroll;
    height: fit-content;
    max-height: 700px;
  }
}

.sellingPrice {
  position: relative;
  display: flex;
  align-items: center;

  .changePrice {
    padding: 0px 8px;
    border-radius: 8px;
    background: #efefef;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;

    .priceType {
      font-size: 12px;
      line-height: 18px;
      color: #696677;
    }

    .changePriceIcon {
      width: 14px;
      height: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ffffff;
      border-radius: 2px;
      border: 1px solid #7647ee;
    }
  }

  .pricingsContainer {
    position: absolute;
    left: 0px;
    top: 24px;
    z-index: 1;
    background: #ffffff;
    border: 1px solid #7647ee;
    width: 380px;
    border-radius: 8px;
    padding: 12px;

    hr {
      margin: 10px 0;
    }

    .pricingsTable {
      margin-top: 20px;
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));

      span {
        font-size: 10px;
        color: #696677;
      }
    }

    .prices {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      cursor: pointer;

      span {
        font-size: 12px;
        color: #15112d;
      }

      .unchecked {
        height: 20px;
        width: 20px;
        border-radius: 25px;
        border: 1.25px solid #c5c5c5;
      }

      .checkedContainer {
        height: 20px;
        width: 20px;
        border-radius: 25px;
        border: 1.25px solid #7647ee;
        display: flex;
        align-items: center;
        justify-content: center;

        .checkedInside {
          height: 13px;
          width: 13px;
          border-radius: 99px;
          background: #7647ee;
        }
      }
    }
  }
}
