.add-settlement-account {
  padding: 24px;

  &__form {
    &__actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 26px;
    }
  }
}
