@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.loyaltyTable {
  .tableSection {
    width: 100%;
    overflow: auto;
    min-height: 400px;

    table {
      border-collapse: collapse;
      background-color: $white-color;
      width: 100%;

      @include respond-to("tablet") {
        display: none;
      }

      th,
      td {
        padding: 10px 24px;
        font-size: 12px;
        text-align: left;
        min-width: 140px;
        max-width: 140px;

        &:first-child {
          min-width: 150px;
        }
      }

      tr {
        border-bottom: 1px solid #edf2f7;
      }

      thead {
        th {
          color: #696677;
        }
      }

      tbody {
        tr {
          &:last-child {
            border-bottom: none;
          }
        }

        td {
          color: $dark-color;
          padding: 12px 24px;
        }
      }
    }

    .mobileTable {
      background: $white-color;
      display: none;

      @include respond-to("tablet") {
        display: block;
      }

      &__row {
        padding: 10px 10px;
        border-bottom: 1px solid #ddd;
      }

      .rowItem {
        display: flex;
        gap: 20px;
        justify-content: space-between;
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }

        &__value {
          font-size: 12px;
        }

        &__title {
          margin-bottom: 0;
          font-weight: normal;
          font-size: 12px;
        }
      }
    }
  }
}
