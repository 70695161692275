@import "../../../../../assets/styles/base/variables";
@import "../../../../../assets/styles/base/mixins";

.content {
  border-radius: 16px;
  padding-bottom: 24px;
  background: $white-color;
  min-height: 500px;

  .searchSection {
    padding: 24px;
    max-width: 546px;
  }
}
