@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.coming-soon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f3f1fc;
  padding: 48px 32px 48px 32px;
}

.coming-soon__progress-bar {
  max-width: 720px;
  width: 720px;
  height: 16px;
  background: #cac6dc;
  border-radius: 60px;
  margin-top: 32px;
  position: relative;
  @include respond-to("big-tablet") {
    width: 100%;
  }
  &::before {
    content: "";
    background: linear-gradient(90deg, #766df4 14.61%, #1d006e 85.04%);
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 460px;
    height: 16px;
    border-radius: 60px;
    @include respond-to("big-tablet") {
      width: 65%;
    }
  }
}
