@import "../../../../../assets/styles/base/variables";
@import "../../../../../assets/styles/base/mixins";

.edit-single-product-page {
  border-radius: 8px;
  padding-top: 3.5em;

  @include respond-to("mobile") {
    padding: 20px;
  }

  @include respond-to("mobile") {
    padding: 0;
  }

  &__content {
    margin-top: 24px;
    padding: 24px;
    background-color: $white-color;
    min-height: 500px;

    @include respond-to("mobile") {
      padding: 0;
    }

    &-header {
      @include respond-to("mobile") {
        padding: 24px;
      }
    }
  }
}
