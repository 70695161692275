@import "../../../../assets/styles/base/mixins";
@import "../../../../assets/styles/base/variables";

.compositeDetailsForm {
  .formSection {
    padding: 40px 30px;
    border-radius: 12px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.14);
  }
}
.buttonSection {
  display: flex;
  justify-content: space-between;
  column-gap: 30px;
  row-gap: 20px;
  flex-wrap: wrap;
  @include respond-to("mobile") {
    button:first-child {
      order: 2;
      width: 100% !important;
    }
    button:last-child {
      order: 1;
      width: 100%;
    }
  }
}

.topFormSection {
  display: flex;
  gap: 120px;
  @include respond-to("small") {
    flex-direction: column;
    align-items: center;
    gap: 50px;
  }
  &__left {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    @include respond-to("small") {
      order: 2;
    }
  }
  &__right {
    width: 100%;
    max-width: 266px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.14);
    border-radius: 12px;
    padding: 20px;
    height: max-content;
    @include respond-to("small") {
      order: 1;
    }
    @include respond-to("tablet") {
      max-width: 100%;
    }
    .uploadBtn {
      height: 35px;
      font-size: 12px;
      margin: auto;
      margin-bottom: 20px;

      @include respond-to("tablet") {
        margin-bottom: 30px;
      }
    }
    .imageContainer {
      width: 220px;
      height: 170px;
      @include respond-to("tablet") {
        width: 170px;
        height: 150px;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
    .imagesContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      max-height: 588px;
      overflow-y: scroll;

      @include respond-to("tablet") {
        flex-direction: row;
      }

      .imageDeleteContainer {
        display: flex;
        align-items: center;
        gap: 20px;

        @include respond-to("tablet") {
          flex-direction: column;
        }

        .imageContainer {
          width: 220px;
          height: 170px;
          @include respond-to("tablet") {
            width: 170px;
            height: 150px;
          }
          img {
            width: 100%;
            height: 100%;
          }
        }

        .deleteContainer {
          border: 1px solid rgba(224, 32, 32, 1);
          height: 24px;
          width: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          cursor: pointer;
        }
      }
    }
  }
}
