@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.custom-dropdown {
  &__select {
    width: 100%;
    color: $dark-color !important;
    &__control {
      display: flex;
      align-items: center;
      border: $input-border;
      border-radius: 4px !important;
      @include respond-to("tablet") {
        min-height: 42px !important;
      }
      // background: #f3f1fc;

      &:hover {
        border-color: $purple-color;
      }

      &:focus {
        border-color: $purple-color;
      }
    }

    &__indicators {
      .custom-dropdown__select__indicator-separator {
        display: none !important;
      }
    }

    &__input {
      outline: none !important;
    }
  }

  .error-message {
    font-size: 13px;
    visibility: hidden;
    display: flex;
    margin-top: 2px;
    min-height: 15px;

    &.show {
      visibility: visible;
    }
  }
}
