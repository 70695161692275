@import "../../../assets/styles/base/variables";

.statement-of-account {
  .close-icon {
    margin-left: auto;
    display: block;
    cursor: pointer;
    font-size: 25px;
  }

  .statement-form {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .daterange-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
