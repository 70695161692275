@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.nav-link {
  display: flex;
  align-items: center;
  transition: all 0.2s ease-in-out;
  font-size: 16px;
  text-decoration: none !important;
  padding: 0.5em 0;
  color: #696677;
  border-bottom: 2px solid transparent;
  min-width: fit-content;

  &.active {
    color: $purple-color;
    border-color: $purple-color;
  }
}
