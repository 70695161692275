@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.container {
  display: flex;
  flex-direction: column;
  gap: 3em;
  padding: 15px;
}
.pricing {
  border-radius: 8px;
  border: 0px solid $light-ash;
  padding: 0px;
}
