@import "../../../../assets/styles/base/variables";
@import "../../../../assets/styles/base/mixins";

.transactionDetails {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  gap: 15px;
  padding-bottom: 20px;

  p {
    font-size: 14px;
  }

  .buttonsContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 7px;
  }
  .orderDetails {
    display: flex;
    gap: 1%;
    justify-content: space-between;
    margin-bottom: 10px;
    p {
      width: 24%;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__column {
    display: flex;
    gap: 20px;
    padding: 0 15px 15px 15px;
    border-radius: 8px;
    border: $light-border;
    flex-wrap: wrap;

    & > div {
      width: calc(25% - 15px);
      @include respond-to("tablet") {
        width: calc(50% - 10px);
      }
    }
  }
}
.actionContainer {
  display: flex;
  align-items: center;
  gap: 5px;
}

.order_note {
  margin-top: 14px;
  margin-bottom: 12px;
  border: 0.75px solid #9797974d;
  padding: 12px 16px;
  border-radius: 12px;

  .order_note_field {
    margin-top: 8px;
    border: 0.75px solid #9797974d;
    padding: 18px 16px;
    border-radius: 4px;
  }
}

.tnxImportantDetails {
  display: grid;
  width: 100%;
  border-bottom: $light-border;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 10px;
  @include respond-to("big-tablet") {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @include respond-to("mobile") {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  & > div {
    display: flex;
    flex-direction: column;
    gap: 5px;
    border-right: $light-border;
    padding: 10px 0;
    &:nth-child(4) {
      border-right: none;
    }
    @include respond-to("big-tablet") {
      border: none;
    }
  }
}
