@import "../../../../../../../../../assets//styles/base/variables";
@import "../../../../../../../../../assets//styles/base/mixins";

.container {
  padding: 16px 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  @include respond-to("big-tablet") {
    padding: 12px;
  }
}

.itemContainer {
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include respond-to("big-tablet") {
    padding: 16px 12px;
  }

  .itemEditContainer {
    display: flex;
    align-items: center;
    gap: 10px;

    .editContainer {
      border: 1px solid #7647ee;
      width: 64px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      border-radius: 4px;
      cursor: pointer;

      span {
        color: #7647ee;
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
      }
    }

    h4 {
      font-size: 20px;
      margin-top: 2px;
    }
  }

  p {
    @include respond-to("big-tablet") {
      width: 70%;
    }
  }

  span {
    @include respond-to("big-tablet") {
      font-size: 14px;
    }
  }

  span {
    @include respond-to("mobile") {
      font-size: 12px;
    }
  }
}

.divider {
  border: 0.75px solid #9797974d;
}
