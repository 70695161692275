@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.campaign-card {
  background-color: $white-color;
  box-shadow: 0px 0px 4px 0px #00000024;
  padding: 16px 24px;
  border-radius: 12px;

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include respond-to("large") {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 30px;
    }

    .mobile {
      display: none;

      @include respond-to("large") {
        display: block;
      }
    }

    &-left {
      width: 304px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include respond-to("large") {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
      }

      h5 {
        @include respond-to("large") {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          gap: 100px;
        }
      }
    }

    &-middle {
      // width: 241px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;

      @include respond-to("large") {
        width: 100%;
      }

      .brand-url__heading {
        @include respond-to("large") {
          margin-left: auto;
        }
      }
    }

    &-right {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 15px;
      @include respond-to("large") {
        width: 100%;
      }
    }
  }
}
