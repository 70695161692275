@import "../../../../assets/styles/base/mixins";
@import "../../../../assets/styles/base/variables";

.accountingLayout {
  padding-top: 3.5em;
  display: flex;
  flex-direction: column;
  gap: 25px;
  @include respond-to("tablet") {
    padding-top: 2em;
  }
}

.cards_container {
  display: grid;
  column-gap: 40px;
  row-gap: 24px;
  grid-template-columns: repeat(4, minmax(0, 1fr));

  @include respond-to("large") {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @include respond-to("big-tablet") {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @include respond-to("tablet") {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @include respond-to("mobile") {
    column-gap: 10px;
    //row-gap: 10px;
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

.title {
  text-align: center;
  border: $light-border;
  border-radius: 4px;
  max-width: fit-content;
  margin-bottom: 5px;
}

.card {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 15px;
  border-radius: 8px;
  padding: 20px 30px;
  background: $white-color;
  border: $light-border;
}

.tableBody {
  background: $white-color;
  border-radius: 16px;
  padding-bottom: 24px;
  min-height: 400px;
  &__header {
    padding: 24px;
    border-bottom: 2px solid #e7f1fa;
    display: flex;
    // justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap;
    @include respond-to("large") {
      padding: 15px 15px 5px 15px;
    }
    @include respond-to("mobile") {
      padding: 0;
    }
    .searchField {
      max-width: 560px;
      min-width: 350px;
      width: 100%;
      @include respond-to("tablet") {
        min-width: 100%;
      }
    }
  }
}
