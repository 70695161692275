@import "../../../assets/styles/base/mixins";
@import "../../../assets/styles/base/variables";

.stockTransferForm {
  &__header {
    display: flex;
    align-items: center;
    gap: 15px;
    border-radius: 16px;
    border: $light-border;
    padding: 20px 20px 5px 20px;
    margin: 20px;
    @include respond-to("big-tablet") {
      flex-direction: column;
    }
  }
  &__headerTwo {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px;
  }
}
