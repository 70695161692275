@import "../../../assets/styles/base/mixins";
@import "../../../assets/styles/base/variables";

.page {
  background: #f6f4ff;
  position: absolute;
  width: 100%;
  height: 100%;
  max-width: 1600px;
  margin: auto;
  padding: 40px 5%;
}
.mainContentContainer {
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
  box-shadow: 0px 0px 4px 0px #00000024;
  padding: 20px 30px;
  gap: 25px;
  background: $white-color;

  .orderStatusConnections {
    margin-top: 20px;
    display: flex;
    align-items: flex-start;
    gap: 27px;
    width: 100%;
    padding-bottom: 20px;

    @include respond-to("big-tablet") {
      gap: 16px;
    }

    .statusConnections {
      display: flex;
      flex-direction: column;
      align-items: center;

      .divider {
        height: 64px;
        width: 1px;
        background: #47be79;
      }
    }

    .orderStatus {
      display: flex;
      flex-direction: column;
      gap: 45px;
      width: 100%;

      .orderItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        h6 {
          margin-bottom: 0;
        }
      }
    }
  }

  .footerContent {
    border-top: 0.75px solid #97979733;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    p {
      color: #15112d;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
    }

    h2 {
      color: #7647ee;
      font-size: 32px;
      line-height: 48px;
      font-weight: 700;
    }
  }
}

.enlumiArrows {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 13rem;

  // mobile screens
  @include respond-to("small") {
    display: none;
  }
}
