@import "../../../assets/styles/base/mixins";
@import "../../../assets/styles/base/variables";

.supplierForm {
  padding: 20px;
  .formSection {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 30px;
  }
}
