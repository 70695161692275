@import "../../../../../../../../../assets//styles/base/variables";
@import "../../../../../../../../../assets//styles/base/mixins";

.container {
  padding: 16px 0;
  display: flex;
  flex-direction: column;

  @include respond-to("big-tablet") {
    padding: 16px;
  }

  .heading {
    display: flex;
    align-items: center;
    gap: 16px;
    padding-left: 16px;
    margin-bottom: 8px;

    @include respond-to("big-tablet") {
      padding-left: 0;
      margin-bottom: 0;
      gap: 8px;
      align-items: flex-start;
    }
  }

  hr {
    background: #9797974d;
  }

  .templateContainer {
    margin-top: 36px;
    padding: 0 24px;
    display: flex;
    align-items: center;
    gap: 40px;

    @include respond-to("big-tablet") {
      margin-top: 20px;
      flex-direction: column;
      padding: 0;
      gap: 24px;
    }

    .template {
      flex: 1;
      display: flex;
      flex-direction: column;
      background: #f1f1f1;
      border: 1px sold #e8e9eb;
      height: 530px;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      box-shadow: 0px 0px 4px 0px #00000024;
      overflow: hidden;

      @include respond-to("big-tablet") {
        flex-direction: column-reverse;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }

      .templateTopContainer {
        height: 48px;
        padding: 8px 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include respond-to("big-tablet") {
          height: auto;
          flex-direction: column;
          gap: 8px;
          align-items: flex-start;
          padding: 8px;
        }

        .left,
        .right {
          display: flex;
          align-items: center;
          gap: 8px;

          h5 {
            margin-bottom: 0;
          }

          .applied {
            width: 84px;
            height: 32px;
            display: flex;
            align-items: center;
            gap: 8px;

            p {
              color: #7647ee;
              font-size: 14px;
              font-weight: 500;
            }
          }
        }

        .inUse {
          background: #e5fff0;
          color: #19ae57;
          font-size: 10px;
          line-height: 13px;
          padding: 2px 8px;
          border-radius: 8px;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}

.modalContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.modalHeading {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 20px;
}

.modalContent {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 32px 0;
}

.modalButtonContainer {
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: sticky;
  bottom: -10px;
  padding: 10px 0;
  background: #fff;
}
