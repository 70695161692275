@import "../../../../../assets/styles/base/variables";
@import "../../../../../assets/styles/base/mixins";

.reconcileStocks {
  padding-top: 3.5em;
  display: flex;
  flex-direction: column;
  gap: 25px;
  @include respond-to("tablet") {
    padding-top: 2em;
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 25px;
    flex-wrap: wrap;
  }
  &__search {
    width: 100%;
    max-width: 650px;
  }
}
