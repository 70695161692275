.sectionView {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 15px;
  width: 100%;
  max-width: 400px;
  font-family: Arial, Helvetica, sans-serif;
  h6,
  p {
    font-weight: bold !important;
    font-size: 20px;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
}
