@import "../../../assets/styles/base/mixins";
@import "../../../assets/styles/base/variables";

.cardWrap {
  display: flex;
  justify-content: space-between;
  gap: 20px;

  @include respond-to("mobile") {
    flex-wrap: wrap;
  }
}
