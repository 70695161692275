.confirm-dialog {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
  }
}
