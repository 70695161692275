@import "../../../../assets/styles/base/variables";
@import "../../../../assets/styles/base/mixins";

.printerConfig {
  display: flex;
  flex-direction: column;
  gap: 30px !important;

  &__header {
    padding: 0px 3% 10px;
    border-bottom: $light-border;
    width: calc(100% + 50px);
    margin-left: -25px;
    gap: 10px;
  }

  .section {
    display: flex;
    gap: 7px;
    &.largeView {
      @include respond-to("tablet") {
        flex-wrap: wrap;
      }
    }

    &__number {
      background: #efefef;
      padding: 5px 5px;
      border-radius: 10px;
      min-width: 80px;
      text-align: center;
      height: fit-content;
      font-weight: 700;
      font-size: 12px;
    }

    &__line {
      min-width: 100px;
      margin: 12px 0;
      &.small {
        min-width: 60px;
      }
    }

    &__content {
      padding-top: 3px;
      display: flex;
      flex-direction: column;
      gap: 15px;
      width: -webkit-fill-available;
    }
  }

  .osCard {
    gap: 20px;
    width: 100%;
    max-width: 430px;
  }
}
