@import "../../../assets/styles/base/variables";

.gift-card-widget {
  height: 100%;
  &__content {
    padding: 33px 0 24px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    &__main {
      flex-grow: 1;
    }
    &__close {
      text-align: right;
      img {
        height: 23px;
        width: 23px;
      }
    }
    &__form {
      margin-top: 32px;
      gap: 16px;
      display: flex;
      flex-direction: column;
      &--gender {
        gap: 16px;
      }
    }
    &__button {
      width: 100%;
    }
  }
}

.add-customers {
  &__form {
    margin-top: 32px;
    gap: 16px;
    display: flex;
    flex-direction: column;
    &--gender {
      gap: 16px;
    }
  }
}
