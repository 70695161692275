@import "../../../../../assets/styles/base/variables";
@import "../../../../../assets/styles/base/mixins";

.roles_table {
  width: 100%;
  overflow: auto;

  table {
    border-collapse: collapse;
    background-color: $white-color;
    border-radius: 16px;
    width: 100%;

    @include respond-to("tablet") {
      display: none;
    }

    .viewBtn {
      width: 90px;
    }

    th,
    td {
      padding: 18px 24px;
      font-size: 12px;
      text-align: left;
      width: 200px;

      &:first-child {
        width: 100px;
      }
      &:last-child {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
    }

    tr {
      border-bottom: 1px solid #edf2f7;
    }

    thead {
      th {
        padding: 24px;
        color: #696677;
      }
    }

    tbody {
      tr {
        &:last-child {
          border-bottom: none;
        }
      }

      td {
        color: $dark-color;
        padding: 18px 24px;
        width: 200px;

        &:last-child {
          padding: 12px 30px;
          width: 100%;
        }
      }
    }
  }

  .actions_container {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  .viewMobileBtn {
    width: 100%;
  }
  .editBtn {
    font-size: 12px;
    height: 32px;
    padding: 0 16px;
  }
  .editBtn {
    padding: 0 5px 0 8px;
    background: $white-color;
    display: flex;
    font-size: 17px;
    border-radius: 8px;
    border: 1px solid #7647ee;
    cursor: pointer;
    align-items: center;
    outline: none;
    width: 34px;
    &:disabled {
      cursor: not-allowed;
    }
  }

  .role_mobile_table {
    background: $white-color;
    display: none;
    display: flex;
    flex-direction: column;
    gap: 12px;
    display: none;

    @include respond-to("tablet") {
      display: block;
    }

    .role_mobile_table_row {
      padding: 16px;
      border: 1px solid #97979759;
      display: flex;
      flex-direction: column;
      gap: 10px;
      border-radius: 8px;
      margin-bottom: 12px;
    }

    .role_mobile_table_item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      border-bottom: 1px solid #edf2f7;
      margin-bottom: 8px;
      padding-bottom: 8px;
      &:last-child {
        margin-bottom: 0;
        border-bottom: 0px;
      }

      .value {
        font-size: 12px;
        color: #15112d;
        font-weight: 500;
      }

      .title {
        margin-bottom: 0;
        font-weight: normal;
        font-size: 12px;
        color: #696677;
      }
    }
  }
}
