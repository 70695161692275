@import "../../../../../../../../../assets/styles/base/variables";
@import "../../../../../../../../../assets/styles/base/mixins";

.container {
  padding-top: 4px;

  .dropdrown {
    position: absolute;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    background: #ffffff;
    box-shadow: 0px 20px 36px rgba(0, 0, 0, 0.05);
    z-index: 5;
    width: 180px;
    gap: 16px;
    padding: 14px;
    margin-top: 10px;
    border: $light-border;
    right: 0;
    p {
      cursor: pointer;
    }
  }
}
