.subscriptionFeatureCollapse {
  position: absolute;
  right: 10px;
  top: 15px;
  height: 40px;
  padding: 14px;
  &.mobile {
    position: relative;
    padding: 0 8px;
    height: 25px;
    top: 0;
    right: 0;
  }
}
