@import "../../../assets/styles/base/variables";

.file-input__container {
  width: 100%;

  .error-message {
    font-size: 13px;
    visibility: hidden;
    display: flex;
    margin-top: 2px;
    min-height: 15px;
    &.show {
      visibility: visible;
    }
  }

  &.disabled {
    .file-input {
      background: #e6e6e6;
      border: 1.5px solid rgba(151, 151, 151, 0.3);
      cursor: not-allowed;
    }
  }

  .file-input {
    width: 100%;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1.5px solid $purple-color;
    border-radius: 4px;
    position: relative;
    cursor: pointer;
    background-color: $white-color;

    input[type="file"] {
      width: 100%;
      height: 100%;
      visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
    }
  }
}
