@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.barcodeDisplay {
  display: flex;
  flex-direction: column;
  height: 100%;

  .barcodeCover {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 30px;
    .barcodeBox {
      border-radius: 8px;
      padding: 12px;
      border: $light-border;
      text-align: center;
    }
  }

  .textwithbackground {
    background: #f3f1fc;
    padding: 10px 12px;
    border-radius: 15px;
    width: fit-content;
    margin: 0 auto;
  }

  .bottomSection {
    background: $white-color;
    padding: 10px 0;
    margin-top: auto;
    position: sticky;
    bottom: -12px;
    display: flex;
    gap: 10px;
  }
}
