@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.toolTip {
  &__placeholder {
    &:hover ~ .toolTip__content {
      display: block;
    }
  }
  &__content {
    display: none;
    position: absolute;
    z-index: 1;

    &Styles {
      border-radius: 8px;
      border: 1px solid $purple-color;
      background: $white-color;
      padding: 8px 12px;
    }
  }
}
