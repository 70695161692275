@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.addProductStock {
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .storeList {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 30px 0;
    flex-wrap: wrap;
    span {
      height: 32px;
      max-width: max-content;
      padding: 0 10px;
      font-size: 16px;
    }
  }
  .formSection {
    border: $light-border;
    border-radius: 12px;
    padding: 15px 12px;
    gap: 15px;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    .rowItem {
      display: flex;
      gap: 10px;
      align-items: flex-start;
    }

    .priceCover {
      border: 1px solid $purple-color;
      border-radius: 12px;
      padding: 15px 12px 0;
      width: 100%;
    }
    .priceSection {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      // border-bottom: $light-border;
      &:nth-child(2) {
        border: none;
      }
      & > div {
        width: 100%;
        min-width: 200px;
        max-width: calc((100% - 30px) / 4);
        @include respond-to("small") {
          max-width: calc((100% - 10px) / 2);
        }
        @include respond-to("mobile") {
          max-width: none;
        }
      }
      .variantSection {
        width: calc(100% + 24px);
        margin-left: -12px;
        padding: 0px 12px 10px 12px;
        align-items: center;
        flex-wrap: nowrap;

        & > div {
          width: 40%;
          min-width: 150px;
          max-width: none;
        }
      }
      &.variantPrice {
        padding-top: 15px;
        border: none;
        & > div {
          width: 100%;
          min-width: 120px;
          max-width: calc((100% - 40px) / 5);
        }
      }
    }

    .supplierSection {
      background: #c5c5c533;
      padding: 10px 12px;
      width: calc(100% + 24px);
      margin-left: -12px;
      display: flex;
      gap: 10px;
      align-items: center;
      flex-wrap: wrap;
    }
    .supplierCover {
      padding-bottom: 15px;
      border-bottom: $light-border;
    }
    .delBtn {
      outline: none;
      border: 1px solid $danger-color;
      background: $white-color;
      border-radius: 4px;
      width: 36px;
      height: 32px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 30px;
      //   @include respond-to("tablet") {
      //     order: 1;
      //     margin-left: auto;
      //   }
    }
    .optionContainer {
      margin-top: 15px;
      padding-top: 15px;
      border-top: $light-border;
      text-align: center;
      a {
        cursor: pointer;
      }
    }
  }
  .formBtn {
    width: 250px;
    min-height: 48px;
  }
}
