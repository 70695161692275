@import "../../../../../assets//styles/base/variables";
@import "../../../../../assets//styles/base/mixins";

.createFoodMenu {
  display: flex;
  flex-direction: column;
  gap: 32px;
  @include respond-to("big-tablet") {
    gap: 12px;
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .editHeadingContainer {
    .editHeading {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-top: 32px;
    }
  }

  &__content {
    background-color: $white-color;
    padding-top: 24px;
    padding-bottom: 40px;
    border-radius: 16px;

    @include respond-to("big-tablet") {
      border-radius: 8px;
    }

    .topSection {
      flex-direction: column;
      gap: 20px;

      @include respond-to("big-tablet") {
        padding: 12px 8px;
      }

      .menuCategory {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-width: 900px;
        align-self: center;

        .lineContainer {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          margin-bottom: 12px;

          @include respond-to("big-tablet") {
            display: none;
          }

          .stageNumber {
            width: 38px;
            height: 38px;
            border-radius: 999px;
            background: #7647ee;
            display: flex;
            align-items: center;
            justify-content: center;

            p {
              color: #ffffff;
              font-size: 20px;
              line-height: 30px;
              font-weight: 3;
            }
          }

          .line {
            width: 320px;
            height: 2px;
            background: #7647ee;
          }
        }

        .menuCategoryText {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          width: 100%;
          align-self: center;
          margin-bottom: 56px;

          @include respond-to("big-tablet") {
            display: none;
          }

          div {
            max-width: 205px;
            text-align: center;

            .menuCategoryHeading {
              color: #7647ee;
              font-weight: 500;
              font-size: 20px;
              line-height: 28px;
            }

            .description {
              font-size: 14px;
            }
          }
        }
      }

      .menuContainerMobile {
        display: none;

        @include respond-to("big-tablet") {
          display: flex;
          flex-direction: column;
          width: 100%;
          padding-bottom: 24px;
          margin-bottom: 24px;
          border-bottom: 0.75px solid #eaeaea;
        }

        .menuContainerMobileHeading {
          display: flex;
          align-items: center;
          justify-content: space-between;

          h4 {
            font-size: 20px;
          }

          p {
            font-size: 18px;
            font-weight: 500;

            span {
              color: #7647ee;
            }
          }
        }

        .menuContainerMobileLine {
          margin-top: 14px;
          width: 100%;
          height: 4px;
          background: #f2edfe;

          .menuContainerMobileLineFill {
            background: #7647ee;
            height: 100%;
            width: 50%;
          }
        }
      }

      hr {
        width: 100%;
      }

      .contentHeader {
        display: flex;
        align-items: flex-start;
        gap: 24px;
        width: 100%;

        @include respond-to("big-tablet") {
          flex-direction: column;
          gap: 10px;
        }

        & > div {
          width: 35%;
          margin-right: 20px;
          @include respond-to("big-tablet") {
            margin-right: 0px;
            width: 100%;
          }
        }
      }

      .checkoutHeader {
        display: flex;
        align-items: flex-start;
        gap: 100px;
        margin-bottom: 20px;
        width: 100%;

        @include respond-to("big-tablet") {
          flex-direction: column;
          gap: 20px;
        }

        .storeDeliveyCheckout {
          display: flex;
        }

        .allowCheckout {
          display: flex;
          align-items: center;

          @include respond-to("big-tablet") {
            margin-left: 0px;
          }

          div {
            margin-left: 20px;
            margin-top: -10px;
          }
        }
      }

      .clickSelectContainer {
        width: 100%;

        h5 {
          @include respond-to("big-tablet") {
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
          }
        }

        p {
          span {
            color: #7647ee;
            cursor: pointer;
            text-decoration: underline;
          }
          @include respond-to("big-tablet") {
            font-size: 12px;
            line-height: 18px;
          }
        }
      }

      .searchContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        width: 100%;

        @include respond-to("big-tablet") {
          flex-direction: column;
          align-items: flex-start;
          gap: 30px;
        }

        h4 {
          @include respond-to("big-tablet") {
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
          }
        }
      }
    }
  }
}
