.modal_container {
  padding: 16px;

  .form_container {
    display: flex;
    flex-direction: column;
    margin-top: 24px;

    .permissions_text {
      color: #15112d;
      font-weight: 500;
      font-size: 24px;
    }

    .permissions_subtext {
      color: #696677;
      font-weight: 400;
      font-size: 14px;
    }
  }

  .permission_container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;

    .permission_item {
      border-radius: 8px;
      border: 0.75px solid rgba(151, 151, 151, 0.35);
      padding: 0 12px;

      .permission_item_heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        padding: 10px 0;

        .transform_icon {
          transition: 0.2s;
        }
        .active {
          transform: rotate(-180deg);
        }
      }

      .permission_item_body {
        display: flex;
        flex-direction: column;
        margin-top: 2px;
        margin-bottom: 10px;

        .permission_value_all {
          display: flex;
          border-top: 1px solid rgba(151, 151, 151, 0.15);
          align-items: center;
          padding: 10px 0;
        }
        .permission_value {
          display: flex;
          align-items: center;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px 0;
          border-top: 1px solid rgba(151, 151, 151, 0.15);
        }
      }
    }

    .permission {
      border: 0.75px solid #97979759;
      border-radius: 8px;
      padding: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      .text {
        font-size: 14px;
        font-weight: 700;
        color: #15112d;
      }
    }
  }
}

.btnEdit {
  width: 100% !important;
  margin-top: 32px !important;
}
