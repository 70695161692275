@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.arrowButton {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  border: 1px solid $purple-color;
  border-radius: 4px;
  padding: 0px 15px;
  cursor: pointer;
  outline: none;
  align-items: center;
  background: $white-color;
  height: 48px;
  &:disabled {
    cursor: not-allowed;
  }
}
