.geeneratedWaybills {
  height: 100%;
  display: flex;
  flex-direction: column;

  .orderDetails {
    display: flex;
    gap: 1%;
    justify-content: space-between;
    margin-bottom: 10px;
    p {
      overflow: hidden;
      width: 24%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: 15px;

    &-item {
      border: 1px solid #ddd;
      padding: 10px 10px;
      margin-top: 20px;

      .waybill_header {
        font-size: 10px;
        padding: 0 10px;
        border-radius: 3px;
        color: #f7ad3c;
        background: #fef7eb;
        height: 25px;
        display: flex;
        align-items: center;
        margin-left: 15px;
        max-width: 250px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .printBtn {
        height: 35px;
        padding: 0 15px;
        font-size: 12px;
      }
    }
  }

  &__bottom {
    position: sticky;
    bottom: 0px;
    margin-top: auto;
    padding-top: 10px;
    display: flex;
    gap: 20px;
  }
}
