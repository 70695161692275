@import "../../../assets/styles/base/variables";

.wallet-details__widget {
  background-color: $white-color;
  padding: 16px 24px 16px 24px;
  max-width: 282px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-left: auto;
  margin-top: 24px;

  &-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .btn__copy {
      height: 32px;
    }
  }
}
