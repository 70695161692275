@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.menuList {
  margin-top: 72px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 44px;
}

.menuItem {
  text-align: center;
  cursor: pointer;
}

.menuItemImage {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid rgba(118, 71, 238, 1);
  border-radius: 12px;
  width: 60px;
  height: 60px;
  margin-inline: auto;
  padding: 8px;

  & > img {
    max-width: 100%;
    height: 100%;
    width: 100%;
  }
}
