@import "../../../assets/styles/base/mixins";
@import "../../../assets/styles/base/variables";

.all-subaccounts-list {
  display: flex;
  max-width: 100%;
  padding: 10px;
  border-radius: 12px;
  background: $white-color;
  width: max-content;
  overflow: auto;
  @include respond-to("tablet") {
    margin: 10px 0;
  }
  .account {
    height: 45px;
    min-width: 150px;
    max-width: 180px;
    background: #f3f1fc;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #696677;
    margin-right: 10px;
    cursor: pointer;
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &:last-child {
      margin-right: 0;
    }

    &.active {
      background: $purple-color;
      color: $white-color;
    }
  }
}
