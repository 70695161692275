@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.offer__card {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.14);
  border-radius: 8px;
  padding: 25px;
  box-shadow: 0px 0px 4px 0px #00000024;
  background-color: $white-color;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @include respond-to("mobile") {
    padding: 15px;
  }

  &__details {
    display: flex;
    gap: 24px;

    &__image {
      width: 80px;
      height: 80px;
      overflow: hidden;
      @include respond-to("mobile") {
        height: 60px;
        height: 60px;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    &__description {
      display: flex;
      flex-direction: column;
    }

    .duration {
      display: flex;
      align-items: center;
      padding: 4px 12px 4px 12px;
      background-color: $danger-color;
      color: $white-color;
      width: 94px;
      height: 24px;
      border-radius: 16px;

      span {
        font-size: 8px;
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 24px;
    flex-wrap: wrap;
    @include respond-to("big-tablet") {
      width: 100%;
      margin-top: 20px;
    }
  }
}
