@import "../../../../../../../assets//styles/base/variables";
@import "../../../../../../../assets//styles/base/mixins";

.container {
  width: 100%;
  align-items: center;
}

.tabContent {
  margin-top: 32px;
  padding: 16px 12px;

  @include respond-to("big-tablet") {
    padding: 0px;
  }
}
