.otp-input__field {
  width: 100%;
  // display: flex;
  // justify-content: center;

  div {
    justify-content: center;
  }

  input {
    width: 2.5rem !important;
    height: 2.5rem !important;
    padding-top: 5px;
    font-size: 1.5rem;
    // background: #f3f1fc;
    border: 1px solid #7647ee;
  }
}
