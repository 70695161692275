@import "../../../../assets/styles/base/variables";
.beneficiaries {
  &__item {
    padding: 15px;
    border-bottom: 1px solid #ddd;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      background: #f6f3fb;
    }
    .profile {
      height: 60px;
      width: 60px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $purple-color;
      background: #e4dafc;
    }
  }
}
