@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.analyticsCard {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
  background-color: $white-color;
  padding: 20px;
  border-radius: 8px;
  border: $light-border;

  &__left {
    padding: 2px 0 2px 10px;
  }
}
