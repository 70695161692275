@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.transfersTable {
  table {
    th,
    td {
      &:last-child {
        min-width: 200px;
        max-width: 200px;
      }
    }
  }
}
