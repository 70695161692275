@import "../../../../assets/styles/base/variables";
@import "../../../../assets/styles/base/mixins";

.warehouse-payment {
  &__text_title {
    max-width: 75%;
    font-weight: bold;
    text-decoration: underline;
    text-underline-offset: 3px;
  }
}
