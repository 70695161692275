@import "../../../assets/styles/base/variables";

.login-verification-page {
  &__container {
    background-color: $white-color;
    max-width: 500px;
    border: 1px solid #f3f1fc;
    box-shadow: -4px 8px 24px 0px #2c3f5805;
    padding: 15px 30px;
    border-radius: 20px;
    margin: auto;

    @media (max-width: 768px) {
      padding: 25px 16px;
    }

    .verification__form {
      margin: 2em auto;
      width: 100%;
      text-align: center;

      .otp-input__field {
        & > div {
          width: 60%;
          justify-content: center;
          margin: 0 auto;
        }
      }
    }
  }

  &__notification {
    max-width: 500px;
    margin: auto;
    @media (max-width: 768px) {
      padding: 0 2em;
    }
  }
}
