.generateWaybillForm {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 20px;

  &__orderItems {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
    margin-top: 20px;

    &-item {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;

      input {
        width: 72px;
        height: 56px;
        border-radius: 4px;
        outline: none;
        padding: 0 1em;
        border: 0.75px solid #9797974d;
        font-size: 16px;

        &:disabled {
          background: none !important;
        }

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          margin: 0;
        }
      }
    }
  }

  &__bottom {
    position: sticky;
    bottom: 0px;
    margin-top: auto;
    padding-top: 10px;
    display: flex;
    gap: 20px;
    padding-top: 100px;
  }
}
