@import "../../../../../../../../../../../assets//styles/base/variables";
@import "../../../../../../../../../../../assets//styles/base/mixins";

.container {
  padding: 20px 0;
}

.backContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
}

.backBtnContainer {
  gap: 10px;
  display: none;

  @include respond-to("big-tablet") {
    display: flex;
    align-items: center;
  }
}

.back {
  height: 48px;
  width: 48px;
  border-radius: 8px;
  border: 1px solid #7647ee;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @include respond-to("big-tablet") {
    height: 40px;
    width: 40px;
    border-radius: 5.33px;
  }
}

.headingContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;

  h3 {
    @include respond-to("big-tablet") {
      font-size: 20px;
    }
  }

  p {
    width: 40%;

    @include respond-to("big-tablet") {
      width: 100%;
    }
  }
}

.mainContainer {
  display: flex;
  flex-direction: column;
  gap: 48px;

  @include respond-to("big-tablet") {
    flex-direction: column-reverse;
    gap: 36px;
  }
}

.contentDescription {
  border: 1px solid #9797974d;
  display: flex;
  align-items: center;
  height: 320px;
  border-radius: 8px;

  @include respond-to("big-tablet") {
    flex-direction: column;
    height: auto;
  }

  .howTo {
    flex: 1;
    border-right: 1px solid #9797974d;
    width: 100%;
    height: 100%;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    background: #fafafa;
    padding: 12px 40px;

    @include respond-to("big-tablet") {
      border-right: none;
      border-bottom: 1px solid #9797974d;
      padding: 20px;
    }

    .setupTitle {
      font-size: 20px;
      line-height: 28px;
      font-weight: 500;
      margin-bottom: 20px;

      @include respond-to("big-tablet") {
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        margin-bottom: 16px;
      }
    }

    .keySetupSteps {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-top: 12px;
      width: 400px;

      @include respond-to("big-tablet") {
        width: 100%;
      }

      .step {
        display: flex;
        align-items: flex-start;

        p {
          color: black;
          font-size: 14px;
          line-height: 20px;
          margin-left: 8px;

          span {
            font-size: 14px;
            color: #7647ee;
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }
  }

  .video {
    flex: 1;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    padding: 12px 0px;

    @include respond-to("big-tablet") {
      padding: 20px;
    }

    .heading {
      display: flex;
      flex-direction: column;
      align-items: center;

      @include respond-to("big-tablet") {
        padding: 0px 50px;
      }

      .title {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: black;
        text-align: center;
      }

      .paystackLink {
        font-size: 14px;
        line-height: 20px;
        margin-top: 4px;

        span {
          color: #7647ee;
          text-decoration: underline;
          cursor: pointer;
        }

        img {
          margin-left: 2px;
        }
      }
    }

    .videoContainer {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .videoTopText {
      color: #696677;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      width: 80%;
    }

    .video {
      position: relative;
      background: #15112d;
      width: 320px;
      height: 216px;
      border-radius: 8px;
      margin-top: 4px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0); /* Transparent */
        cursor: pointer;
      }
    }
  }
}

.keyMainContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include respond-to("big-tablet") {
    gap: 0px;
  }
  .keyContainer {
    .secretHeading {
      // margin-bottom: 24px;
      .title {
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;

        span {
          font-size: 14px;
          font-style: italic;
          font-weight: 400;
          line-height: 24px;
          color: rgb(255, 132, 0);
        }
      }

      .description {
        color: #696677;
        margin-top: 4px;
        font-size: 14px;
        line-height: 20px;
        width: 60%;

        @include respond-to("big-tablet") {
          width: 90%;
        }

        span {
          font-size: 14px;
          color: #7647ee;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    .inputContainer {
      margin-bottom: 16px;
    }

    .webhookContainer {
      box-shadow: 0px 0px 4px 0px #00000024;
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 12px;
      margin-top: 16px;
      position: relative;

      @include respond-to("big-tablet") {
        padding: 10px 14px;
      }

      span {
        color: #15112d;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;

        @include respond-to("big-tablet") {
          font-size: 14px;
        }
      }

      .copyButton {
        border: 1px solid #7647ee;
        padding: 12px 24px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        cursor: pointer;

        @include respond-to("big-tablet") {
          padding: 8px 14px;
        }

        span {
          color: #7647ee;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          margin-left: 8px;

          @include respond-to("big-tablet") {
            font-size: 12px;
            margin-left: 4px;
          }
        }
      }
    }
  }
}

.saveBtnContainer {
  bottom: 20px;
  width: 100%;
  margin-top: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include respond-to("big-tablet") {
    display: none;
  }
}
