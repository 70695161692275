@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.formStepper {
  display: flex;
  justify-content: space-between;
  position: relative;
  max-width: 550px;
  width: 100%;

  &::before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    top: 23%;
    left: 50%;
    transform: translateX(-50%);
    background-color: $purple-color;

    @include respond-to("mobile") {
      width: 90%;
      top: 40%;
    }
  }

  &__step {
    cursor: pointer;
    z-index: 1;
    text-align: center;
    width: 200px;
    &.firstStep {
      margin-left: -100px;
      text-align: center;
      .formStepper__stepNumber {
        margin-left: 85px;
      }
    }
    &.lastStep {
      margin-right: -100px;
      text-align: center;
      .formStepper__stepNumber {
        margin-right: 85px;
      }
    }
    &Number {
      width: 38px;
      height: 38px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f2edfe;
      color: $purple-color;
      border-radius: 50%;
      padding-top: 1px;
      transition: 0.2s ease;
      margin: 8px auto 0 auto;
      @include respond-to("small") {
        margin-top: -5px;
      }
    }

    &Title {
      margin-top: 13px;
      font-size: 18px;

      @include respond-to("small") {
        display: none;
      }
    }
    &Desc {
      @include respond-to("small") {
        display: none;
      }
    }
  }
}
