@import "../../../../../assets/styles/base/variables";
@import "../../../../../assets/styles/base/mixins";

.storeCart {
  position: sticky;
  z-index: 999;
  background-color: $white-color;
  border-radius: 0 0 8px 8px;
  padding: 0px 16px;
  padding-bottom: 150px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
  @include respond-to("large") {
    padding: 0px;
    padding: 0px 16px;
    padding-bottom: 150px;
    border-radius: 20px 20px 0px 0px;
    border-width: 0.75px 0.75px 0px 0.75px;
    border-style: solid;
    border-color: #ad91f5;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.16);
  }

  .closeContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 12px 0;

    img {
      width: 20px;
      cursor: pointer;
    }

    @include respond-to("large") {
      display: none;
    }
  }

  .cartHeader {
    display: none;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    position: sticky;
    z-index: 2;
    background: $white-color;
    top: 0px;
    width: calc(100% + 32px);
    margin: 0px 0 10px -16px;
    padding: 10px 16px 10px 10px;
    @include respond-to("large") {
      display: flex;
    }
    .cartCount {
      width: 20px;
      height: 20px;
      background: #ad91f5;
      border-radius: 20px;
      font-size: 12px;
      color: $white-color;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: -5px;
      top: -5px;
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 16px;
  }

  .tableOrderText {
    padding: 10px 16px;
    background-color: #ffeee9;
    display: inline-block;
    border-radius: 8px;
    font-size: 16px;
    line-height: 24px;
    color: #fd561f;
    margin-bottom: 38px;
    span {
      font-weight: 700;
    }
  }

  .priceSummary {
    border-radius: 8px;
    border: 1px solid #ddd;
    padding: 13px 12px 0 12px;
    margin-bottom: 12px;
    margin-top: auto;
    &__row {
      display: flex;
      gap: 10px;
    }
  }

  &__bottom {
    position: fixed;
    bottom: 0px;
    background: $white-color;
    padding: 10px 16px 10px 16px;
    box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.08);
    width: 400px;
    margin-left: -16px;
    // padding-bottom: 400px;
    @include respond-to("large") {
      margin-left: -16px;
      width: 100%;
    }
    &.active {
      @include respond-to("large") {
        position: relative;
      }
    }
  }
}
