@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.transfers-filter {
  position: relative;

  .active {
    border: 1px solid $purple-color;
    box-shadow: none;
  }

  &__dropdown {
    background: $white-color;
    border-radius: 8px;
    width: 390px;
    padding: 25px;
    position: absolute;
    right: 0;
    top: 55px;
    box-shadow: 1px 1px 3px 2px #ddd;

    @include respond-to("tablet") {
      position: fixed;
      height: max-content;
      bottom: 0;
      top: auto;
      z-index: 4;
      width: 100%;
    }

    &::after {
      width: 15px;
      height: 15px;
      background: white;
      position: absolute;
      content: "";
      right: 50px;
      top: -3px;
      //box-shadow: 1px 1px 3px 2px #ddd;
      transform: rotate(45deg);
    }

    .checked-item {
      display: flex;
      align-items: center;
      min-width: max-content;
    }

    .date-section {
      display: flex;
      flex-wrap: wrap;
      gap: 7px;

      .btn {
        height: 35px;
        font-size: 14px;
        padding: 0 12px;
      }
    }
  }

  &__overlay {
    display: none;

    @include respond-to("tablet") {
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.3);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 3;
    }
  }
}
