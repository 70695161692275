@import "../../../../../assets/styles/base/variables";
@import "../../../../../assets/styles/base/mixins";

.subscriptionHistory {
  .header {
    padding: 24px;
    border-bottom: 2px solid #e7f1fa;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap;
    background: $white-color;
    @include respond-to("large") {
      padding: 15px 15px 5px 15px;
    }
    &__left {
      display: flex;
      gap: 50px;
      @include respond-to("tablet") {
        gap: 20px;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
      }
    }
    .upgradeBtn {
      padding: 0 50px;
    }
  }
}
