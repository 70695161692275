@import "../../../../assets/styles/base/variables";
@import "../../../../assets/styles/base/mixins";

.stock {
  background-color: #19ae57;
  color: $white-color;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px;
  border-radius: 16px;
  max-height: 20px;
  justify-content: center;
  font-size: 8px !important;
  min-width: 50px;
  &.lowQuantity {
    background-color: #ed0000;
  }
  @include respond-to("mobile") {
    max-height: 15px;
    min-width: auto;
  }
}

.product__actions {
  display: flex;
  align-items: center;
  gap: 8px;
}

.variantSection {
  &__header {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
    border-bottom: $light-border;
    border-bottom: 1px solid #ddd;
    width: calc(100% + 30px);
    margin-left: -15px;
    padding: 0 25px 8px 25px;
  }

  .variantBody {
    width: calc(100% + 30px);
    margin-left: -15px;

    .productSubCard {
      display: flex;
      justify-content: space-between;
      width: 100%;
      border-bottom: 1px solid #ddd;
      padding: 5px 25px 8px 25px;
      align-items: center;
      gap: 10px;
      @include respond-to("big-tablet") {
        align-items: flex-start;
      }
      @include respond-to("tablet") {
        flex-direction: column;
      }

      &:last-child {
        border: none;
        padding-bottom: 0;
      }

      .left {
        display: flex;
        width: calc(100% - 250px);
        flex-direction: column;
        gap: 5px;
        @include respond-to("big-tablet") {
          align-items: flex-start;
          width: auto;
        }
      }

      .middle {
        display: flex;
        align-items: center;
        gap: 20px;
        max-width: 400px;
        p {
          font-size: 14px;
          min-width: 70px;
          text-align: left;
          @include respond-to("mobile") {
            font-size: 10px;
            min-width: 60px;
          }
        }
      }

      .right {
        display: flex;
        justify-content: flex-end;
        gap: 20px;
        margin-left: auto;
      }
    }
  }
}

.variantImg {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  img {
    background-size: contain;
    width: 100%;
  }
}
