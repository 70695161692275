.limited-liability-kyb-form {
  display: flex;
  flex-direction: column;
  gap: 30px;

  &__group {
    .input-field input {
      background: white !important;

      &:disabled {
        background: #e6e6e6 !important;
        border: 1.5px solid rgba(151, 151, 151, 0.3);
        cursor: not-allowed;
      }
    }

    .document-upload-status {
      display: inline-block;
      padding: 5px;
      border-radius: 4px;
      font-size: 14px;
      color: white;
      margin-left: 5px;

      &.rejected {
        background-color: #e02020;
      }

      &.approved {
        background-color: #19ae57;
      }

      &.pending {
        background-color: #f7ad3c;
      }
    }
  }
}
