.campaign-search-field {
  display: flex;
  align-items: center;
  height: 48px;
  border-radius: 8px;
  padding: 0 10px;
  background: #f3f1fc;
  border: 1px solid #7647ee;

  input {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    font-size: 14px;
    padding: 0;
    background: none;
  }
}
