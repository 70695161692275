@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.notificationContainer {
  width: 664px;
  height: 92px;
  background: #5d5fef;
  position: absolute;
  left: 30%;
  top: 20px;
  z-index: 999;
  border-radius: 12px;
  border: 2px solid #c5c6f6;
  padding: 16px;
  display: flex;
  align-items: flex-start;
  position: fixed;

  .notificationIcon {
    margin-top: 8px;
  }

  .notificationContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 12px;
    height: 100%;
    width: 100%;

    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      // height: 100%;

      .textContent {
        .mainText {
          color: white;
          opacity: 0.8;
          font-size: 20px;
          line-height: 28px;
          span {
            font-weight: 700;
          }
        }
        .subText {
          opacity: 0.8;
          color: white;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }

    .button {
      padding: 12px 24px;
      background: white;
      color: #7647ee;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      cursor: pointer;
      width: auto;
    }
  }

  .closeContainer {
    cursor: pointer;
    position: absolute;
    right: -14px;
    top: -14px;
  }
}
.dashboard {
  display: flex;
  row-gap: 25px;
  flex-direction: column;

  .headingContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .noticationContainer {
      display: flex;
      flex-direction: column;
      align-items: center;

      .notificationBellContainer {
        position: relative;
        cursor: pointer;
        span {
          width: 24px;
          height: 26px;
          border-radius: 64px;
          background: #fd561f;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          right: -5px;
          top: -10px;
          color: #fff;
          font-size: 12px;
          line-height: 18px;
        }
      }

      .notificationText {
        font-size: 16px;

        @include respond-to("mobile") {
          font-size: 14px;
        }
      }
    }
  }

  .titleSection {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 12px;
    padding-left: 5px;
    img {
      width: 22px;
      height: 22px;
    }
  }

  &__quickActions {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  &__setup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    border-radius: 8px;
    padding: 25px 40px;
    flex-wrap: wrap;
    background: $white-color;
    border: $light-border;
  }

  &__analytics {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    column-gap: 30px;
    row-gap: 5px;
  }

  .card {
    max-height: 398px;
    background-color: $white-color;
    border-radius: 8px;
    display: flex;
    height: -webkit-fill-available;
    height: -moz-available;

    flex-direction: column;
    overflow-y: auto;
    gap: 10px;

    &__item {
      display: flex;
      column-gap: 25px;
      row-gap: 10px;
      padding: 0 15px;
      align-items: center;
      &:first-child {
        padding-top: 15px;
      }
      &:last-child {
        padding-bottom: 15px;
      }
      &:hover {
        text-decoration: none;
      }
    }

    &__image {
      border-radius: 8px;
      width: 50px;
      height: 50px;
    }
  }
}

.barWrap {
  width: 200px;
  margin-right: 45px;

  @include respond-to("mobile") {
    width: 150px;
    margin-right: 10px;
  }
}

.progressBar {
  height: 10px;
  background-color: #d2d0d8;
  width: 100%;
  border-radius: 16px;

  &__progressive {
    height: 10px;
    background-color: $purple-color;
    border-radius: 16px;
  }
}
