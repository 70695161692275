@import "../../assets/styles/base/mixins";
@import "../../assets/styles/base/variables";

.inventory-layout {
  display: flex;
  flex-direction: column;
  gap: 25px;

  .floating-button {
    display: none;
    position: fixed;
    width: 56px;
    height: 56px;
    background: none;
    background-color: $purple-color;
    border: none;
    outline: none;
    border-radius: 50%;
    right: 5%;
    bottom: 90px;
    z-index: 3001;
    box-shadow: 0px 12px 36px rgba(110, 60, 252, 0.3);
    align-items: center;
    justify-content: center;

    @include respond-to("tablet") {
      display: flex;
    }
  }
}
