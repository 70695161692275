@import "../../../../assets/styles/base/variables";
@import "../../../../assets/styles/base/mixins";

.expenseAnalytics {
  &__header {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    flex-wrap: wrap;
  }
  &__cards {
    display: flex;
    gap: 10px;
    @include respond-to("large") {
      flex-wrap: wrap;
    }
  }

  &__card,
  &__chart {
    min-width: 200px;
    width: 100%;
    background-color: $white-color;
    padding: 24px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.14);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  &__card {
    @include respond-to("large") {
      width: calc(50% - 5px);
    }
    @include respond-to("mobile") {
      width: 100%;
    }
  }
  &__chart {
    min-width: 100%;
    gap: 30px;
  }
}
