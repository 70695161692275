@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.update-credit {
  padding: 33px 0 70px;
  &__title {
    font-size: 32px;
    font-weight: 700;
    color: #15112d;

    @include respond-to("mobile") {
      font-size: 24px;
    }
  }
  &__subtitle {
    font-size: 16px;
    font-weight: 400;
    color: #696677;
  }
  &__data {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    dt {
      width: 50%;
      font-size: 14px;
      font-weight: 500;
      color: #696677;
      margin-top: 10px;

      &.outstanding {
        margin-top: 14px;
      }
    }
    dd {
      width: 50%;
      text-align: right;
      font-size: 14px;
      font-weight: 500;
      margin-top: 10px;

      &.outstanding {
        font-size: 20px;
        color: $purple-color;
      }
    }
  }
  &__submit-button {
    position: absolute;
    bottom: 0px;
    padding: 10px 0;
    display: flex;
    width: 420px;
    background: $white-color;
    @media screen and (max-width: 500px) {
      left: 40px;
      padding: 10px 0px;
      width: calc(100% - 80px);
    }
  }
}
