.createExpense {
  &__form {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 30px;
    min-height: 80vh;
  }

  label {
    span {
      span {
        color: #7647ee;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
