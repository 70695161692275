@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.tableContainer {
  table {
    th,
    td {
      min-width: 150px !important;
      &:nth-child(4) {
        min-width: 210px !important;
      }
      &:nth-child(5) {
        min-width: 210px !important;
      }
      &:nth-child(7) {
        min-width: 200px !important;
      }
    }
  }
}

.noteIconContainer {
  height: 24px;
  width: 24px;
  border-radius: 999px;
  border: 1px solid #7647ee;
  display: flex;
  align-items: center;
  justify-content: center;
}

.decription {
  background: #efefef;
  padding: 0 8px;
  border-radius: 8px;

  p {
    font-size: 12px;
  }
}

.noteDetails {
  border: 1px solid #7647ee;
  box-shadow: 0px 8px 24px 0px #00000029;
  padding: 12px;
  border-radius: 8px;
  position: absolute;
  top: 0;
  left: 24px;
  z-index: 999;
  background: white;
  width: 200px;

  @include respond-to("big-tablet") {
    top: 0;
    left: -200px;
    // right: 200px;
  }
}
