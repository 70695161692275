@import "../../../assets/styles/base/mixins";
@import "../../../assets/styles/base/variables";

.creditForm {
  padding: 20px;
  .formSection {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 30px;
    border: $white-color;
  }
  .add-acct-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
    flex-wrap: wrap;
    @include respond-to("tablet") {
      margin-bottom: 10px;
    }
  }

  .add-acct:hover {
    text-decoration: none;
  }

  .users {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    gap: 30px;
    @include respond-to("tablet") {
      grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    }
  }

  &.user {
    padding: 32px 24px;
    border-radius: 8px;
    background: $white-color;
    box-shadow: 0px 0px 2px 1px #e5e5e5;
  }

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }

  .user-branch {
    border-radius: 32px;
    font-size: 20px;
  }

  .user_info {
    display: flex;
    justify-content: space-between;
    padding-top: 12px;
  }

  .acct_no_wrapper {
    display: flex;
    gap: 5px;
  }
}
