@import "../../../../assets/styles/base/variables";
@import "../../../../assets/styles/base/mixins";

.customer-analytics {
  margin-top: 33px;
  margin-bottom: 33px;

  &__cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    gap: 24px;

    &__card {
      background-color: $white-color;
      padding: 15px 24px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      // min-height: 134px;

      &__details {
        &-title {
          font-size: 14px;
          color: #696677;
        }

        &-subtitle {
          font-size: 12px;
          color: #19ae57;
        }
      }
    }
  }

  &__charts {
    margin-top: 24px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(540px, 1fr));
    gap: 24px;
    @include respond-to("tablet") {
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
  }
}
