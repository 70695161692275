@import "../../../../assets/styles/base/mixins";
@import "../../../../assets/styles/base/variables";

.tableDetails {
  &__content {
    margin-top: 32px;
    padding: 48px 32px;
    border-radius: 12px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.14);
    width: 464px;
    background-color: $white-color;
  }

  &__form {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
