.custom-switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  flex-shrink: 0;

  input {
    width: 0px;
    height: 0px;
    opacity: 0;
  }

  &__control {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #91919f;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &:before {
      position: absolute;
      content: "";
      height: 18px;
      width: 18px;
      left: 1px;
      bottom: 1px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }

  input:checked + .custom-switch__control {
    background-color: #00d266;
  }

  input:checked + .custom-switch__control:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }

  &--rounded {
    .custom-switch__control {
      border-radius: 18px;

      &:before {
        border-radius: 50%;
      }
    }
  }
}
