@import "../../../assets/styles/base/variables";

.pagination-conatiner {
  max-width: 300px;
  list-style: none;
  display: flex;
  justify-content: center;
  margin: auto;
  gap: 10px;
  padding: 20px 0;

  a {
    min-width: 35px;
    height: 35px;
    border: 1px solid #ddd;
    padding: 0 10px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-decoration: none;
    font-size: 14px;

    &:hover {
      background: #f3f1fc;
      color: $purple-color;
      border-color: #f3f1fc;
    }
  }

  .previous-Bttn,
  .next-Bttn {
    border: 1px solid #ddd;
    height: 35px;
    padding: 0 10px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-decoration: none;
    font-size: 14px;
    width: 75px;
  }

  .pagination-active a {
    background: $purple-color;
    color: $white-color;
    border-color: $purple-color;
  }

  .pagination-btn-disabled {
    display: none;
  }
}
