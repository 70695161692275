@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.reconcileStockEditView {
  &__content {
    background-color: $white-color;
    border-radius: 16px;
    width: 100%;

    .tableSection {
      max-height: calc(100vh - 200px);
    }

    table {
      th,
      td {
        &:first-child {
          min-width: 250px;
          max-width: 350px;
        }
        &:nth-child(4),
        &:nth-child(7) {
          min-width: 150px;
        }
        &:nth-child(6) {
          max-width: 300px;
          min-width: 150px;
          overflow: hidden;
        }
      }
      tr {
        border-bottom: 2px solid #e7f1fa;
      }
      tbody {
        td {
          &:nth-child(n + 3):nth-child(-n + 6) {
            background: #f3f1fc;
          }
        }
      }
    }

    .inputField {
      height: 40px;
      font-size: 12px;
      &::placeholder {
        font-size: 12px;
      }
    }
  }
}
