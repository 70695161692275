.request-loader {
  background: transparent;
  position: fixed;
  top: 0;
  z-index: 10000;
  width: 100%;
  &__bar {
    width: 100%;
    height: 3px;
    background: transparent;
    transition: 0.5s;
    &__progress {
      width: 0;
      height: 100%;
      background: #f7ad3c;
    }
  }
  &__spinner {
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
    padding-right: 17px;
    font-weight: bold;
  }
}
