@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.concatenateWithTooltip {
  position: relative;
  display: flex;
  gap: 5px;

  .toolTipIcon {
    height: 17px;
    width: 17px;
    text-align: center;
    border-radius: 17px;
    border: 1px solid $purple-color;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    flex-shrink: 0;
    &:hover {
      background: $purple-color;
      color: $white-color;
      & ~ .toolTipContent {
        display: flex !important;
      }
    }
    span {
      font-size: 17px;
      margin-top: -10px;
    }
  }

  .toolTipContent {
    display: none;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid $purple-color;
    background: $white-color;
    position: absolute;
    font-size: 10px;
    z-index: 1;
    min-width: 200px;
    max-width: 250px;
    color: $purple-color;
  }
}
