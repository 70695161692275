@import "../../../../../../../../../assets//styles/base/variables";
@import "../../../../../../../../../assets//styles/base/mixins";

.container {
  padding: 16px 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  @include respond-to("big-tablet") {
    padding: 12px;
  }
}

.instoreContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px 24px;

  @include respond-to("big-tablet") {
    padding: 16px 12px;
  }

  .instoreCheckout {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h4 {
      font-size: 20px;
    }

    div {
      @include respond-to("big-tablet") {
        width: 50%;
      }
    }

    span {
      @include respond-to("big-tablet") {
        font-size: 14px;
      }
    }
  }

  .toggleContainer {
    background: #f3f1fc;
    border: 1px solid #e4dafc;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    padding: 8px 12px;
  }
}

.itemContainer {
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include respond-to("big-tablet") {
    padding: 16px 12px;
  }

  h4 {
    font-size: 20px;
  }

  div {
    @include respond-to("big-tablet") {
      width: 50%;
    }
  }

  span {
    @include respond-to("big-tablet") {
      font-size: 14px;
    }
  }
}

.divider {
  border: 0.75px solid #9797974d;
}
