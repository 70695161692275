@import "../../../../assets/styles/base/mixins";
@import "../../../../assets/styles/base/variables";

.stockHistory {
  &__header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    gap: 10px;
    flex-wrap: wrap;
  }

  &__content {
    background: $white-color;
    min-height: 500px;

    &__header {
      padding: 24px 24px 24px 24px;
      border-bottom: 2px solid #e7f1fa;
      display: flex;
      justify-content: space-between;
      gap: 10px;
      flex-wrap: wrap;
      @include respond-to("large") {
        padding: 15px 15px 5px 15px;
      }
      .searchField {
        max-width: 600px;
        min-width: 350px;
        width: 100%;
        @include respond-to("tablet") {
          min-width: 100%;
        }
      }
    }
  }
}
