@import "../../../../assets/styles/base/mixins";
@import "../../../../assets/styles/base/variables";

.tableColumn {
  padding: 25px 24px 15px 24px;
  border-right: 1px solid #e8e9eb;
  border-bottom: 1px solid #e8e9eb;
  width: 19.5%;
  display: flex;
  flex-direction: column;

  &:first-child {
    width: 22%;
    padding-left: 0;
  }
  &:last-child {
    border-right: none;
  }
  p {
    font-size: 12px;
    color: $dark-color;
    margin-bottom: 0;
    text-transform: capitalize;
  }
}
