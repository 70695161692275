@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.staffSection {
  display: flex;
  flex-direction: column;
  min-height: 85vh;
  padding-bottom: 50px;
  gap: 15px;

  &__cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    row-gap: 10px;
    min-height: 100%;
  }
}
