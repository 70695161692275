@import "../../../assets/styles/base/variables";

.link-customer-widget {
  &__content {
    background-color: $white-color;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 24px 0px;

    .close__icon {
      cursor: pointer;
      font-size: 25px;
    }

    &__title {
      margin-top: 24px;
    }

    &__form {
      margin-top: 32px;
      display: flex;
      flex-direction: column;
      gap: 13px;
    }
  }
}
