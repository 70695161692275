@import "../../../../assets/styles/base/variables";
@import "../../../../assets/styles/base/mixins";

.loyaltyCustomer {
  &__content {
    margin-top: 32px;
    gap: 34px;
    display: flex;
    flex-direction: column;

    &__rows {
      background-color: $white-color;
      width: 100%;
      border-radius: 12px;
      padding: 24px;
      display: grid;
      gap: 24px;
      grid-template-columns: repeat(4, 1fr);
      @include respond-to("small") {
        grid-template-columns: repeat(3, 1fr);
      }
      @include respond-to("tablet") {
        grid-template-columns: repeat(2, 1fr);
        padding: 15px;
      }
    }

    &__accounts {
      display: flex;
      flex-direction: column;
      gap: 10px;
      background-color: $white-color;
      border-radius: 12px;
      padding: 24px;

      .accountRow {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
      }
    }

    &__history {
      background-color: $white-color;
      border-radius: 12px;
      padding: 24px;
    }
  }
}
