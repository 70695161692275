@import "../../../../../assets/styles/base/mixins";
@import "../../../../../assets/styles/base/variables";

.create-sub-account {
  &__acct-widget {
    width: 500px;
    margin: 100px auto;
  }

  &__form-details {
    display: flex;
    flex-direction: column;
    width: 730px;
    margin-top: 40px;
    background-color: $white-color;
    padding: 28px 40px;
    border-radius: 8px;

    @include respond-to("mobile") {
      width: 100%;
      padding: 20px;
    }
  }
}
