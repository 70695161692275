@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.customersSearchField {
  position: relative;

  .resultSection {
    padding: 15px;
    border-radius: 8px;
    min-height: 100px;
    max-height: 300px;
    overflow-y: auto;
    margin-top: 5px;
    background: $white-color;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 4px;
    width: 100%;
    position: absolute;
    z-index: 2;
  }
  .nameContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .nameItem {
      &:hover {
        color: $purple-color !important;
        cursor: pointer;
      }
    }
  }
}
