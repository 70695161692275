@import "../../../assets/styles/base/mixins";
@import "../../../assets/styles/base/variables";

.created-sub-account-modal {
  width: 500px;
  margin: auto;

  &__card {
    background: #ffffff;
    box-shadow: 0px 0px 2px 1px #e5e5e5;
    border-radius: 20px;
    padding: 56px 38px;
    margin-bottom: 24px;
  }

  &__top {
    margin-bottom: 8px;
    text-align: center;
    margin-bottom: 32px;

    & > h3 {
      margin-bottom: 16px;
    }
  }

  &__bank {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: $lumi-purple;
  }

  &__bank-info {
    color: $dark-color;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: center;
    align-items: center;
    margin-top: 24px;

    .copy {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    .return {
      border: 1px solid $purple-color !important;
    }
  }

  &__powered {
    text-align: center;
  }
}
