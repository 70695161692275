@import "../../../../../../../../../assets//styles/base/variables";
@import "../../../../../../../../../assets//styles/base/mixins";

.container {
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 48px;
  box-shadow: 0px 0px 4px 0px #00000024;
  border-radius: 12px;

  @include respond-to("big-tablet") {
    gap: 16px;
  }
}

.socialHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include respond-to("big-tablet") {
    align-items: flex-start;
    gap: 20px;
  }

  .social {
    display: flex;
    align-items: center;
    gap: 20px;
  }
}

.socialContainer {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(2, minmax(0, 1fr));

  @include respond-to("big-tablet") {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .socialItem {
    border: 1px solid #e8e9eb;
    padding: 12px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    .googleLink {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      span {
        color: #7647ee;
        font-size: 12px;
      }
    }
  }
}
