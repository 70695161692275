@import "../../../../assets/styles/base/variables";
@import "../../../../assets/styles/base/mixins";

.productStockHistory {
  padding-top: 3.5em;
  display: flex;
  flex-direction: column;
  gap: 30px;

  &__content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .productDetails {
      background: $white-color;
      border-radius: 8px;
      padding: 12px;
    }
  }
}
